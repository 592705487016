// NOTE: This code was mostly duplicated from consumer-web-react

import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './LocationInputBox.sass';
import Geosuggest from 'react-geosuggest';
import { find, isEmpty, isFunction } from 'lodash/core'
import includes from 'lodash/includes';
import get from 'lodash/get';

let getState = function() {
    return {
        errorMessage: '',
    }
};

class LocationInputBox extends Component {

    constructor() {
        super();

        this.handleSelection = this.handleSelection.bind(this);
        this.state = getState();
    }

    componentDidMount() {
        if(this.props.setFocus) {
            this.refs.geoSuggest.refs.input.focus();
        }
    }

    getMapsAddressComponent(address_components, type) {
        return find(address_components, (c) => { return includes(c.types, type); });
    }

    getAddressInfo(e) {
        let gmaps = e.gmaps;
        let location = e.location;
        let address_components = gmaps && gmaps.address_components;
        let street_number_component = this.getMapsAddressComponent(address_components, 'street_number');
        let route_component = this.getMapsAddressComponent(address_components, 'route');
        let city_component = this.getMapsAddressComponent(address_components, 'locality');
        let state_component = this.getMapsAddressComponent(address_components, 'administrative_area_level_1');
        let zip_code_component = this.getMapsAddressComponent(address_components, 'postal_code');

        if (!route_component || !city_component || !state_component || !zip_code_component) {
            this.setState({ errorMessage: 'The selected address is not valid.' });
        } else {
            this.setState({ errorMessage: '' });
            let address_1;

            if (street_number_component) {
                address_1 = `${street_number_component.short_name} ${route_component.short_name}`;
            } else {
                address_1 = `${route_component.short_name}`;
            }
            let city = city_component.short_name;
            let state = state_component.short_name;
            let zip_code = zip_code_component.short_name;

            address_components = {
                address_1,
                city,
                state,
                zip_code,
            };

            return {
                location,
                address_components,
                address: gmaps.formatted_address,
            };
        }
    }

  /**
   * Allow for direct access to user input
   *
   * This is necessary becuase google suggestions started returning placeIds
   * that were not found in the geocoding service. This would leave our
   * customers stranded if the place had been sweeped out of the geocoder
   *
   * refs: https://code.google.com/p/gmaps-api-issues/issues/detail?id=11107#c1
   *
   */
  getUserInput() {
    const geo = this.refs.geoSuggest
    return geo.state.userInput
  }

  handleSelection(e) {
    if (typeof e === 'undefined') {
      return
    }

    this.addrInfo = this.getAddressInfo(e);
    this.props.onAddressChange(this.addrInfo);
  }

    update(addr) {
        this.refs.geoSuggest.update(addr);
    }

  clear() {
    if (isFunction(this.props.onAddressCleared)) {
      this.props.onAddressCleared()
    }
    this.refs.geoSuggest.clear();
  }

    render() {
        let hasErrors = !isEmpty(this.state.errorMessage.toString());
        let errorMessage = hasErrors ? <div className="gmaps-error">{this.state.errorMessage}</div> : '';
        const address = get(this.props, 'address', '');
        return (
            <div className="location-bar-container">
                <Geosuggest
                    ref='geoSuggest'
                    className='location-field header-location-field'
                    onFocus={this.clear.bind(this)}
                    placeholder={this.props.placeholder}
                    initialValue={address.toString()}
                    onSuggestSelect={this.handleSelection} />

                {errorMessage}
            </div>
        );
    }
}

LocationInputBox.propTypes = {
  addresss: PropTypes.object,
  onAddressChange: PropTypes.func.isRequired,
};

export default LocationInputBox;
