import { handleActions } from 'redux-actions'
import { isEqual } from 'lodash'

const MIN_LAT_DIFF = 0.002
const MIN_LNG_DIFF = 0.006

const initialState = {
  markers: {},
  rectangles: {},
  polygons: {},
  center: undefined,
  zoom: 13,
  fitBounds: undefined,
  heatmapData: [ ],
  circle: undefined,
  rectangle: undefined
}

const reducer = handleActions({
  ADD_MARKER: (state, action) => ({
    ...state,
    markers: {
      ...state.markers,
      [action.payload.id]: action.payload.data
    }
  }),
  CLEAR_MARKER: (state, action) => {
    let newMarkers = {
      ...state.markers
    }
    delete newMarkers[action.payload]
    return {
      ...state,
      markers: newMarkers
    }
  },
  ADD_RECTANGLE: (state, action) => ({
    ...state,
    rectangles: {
      ...state.rectangles,
      [action.payload.id]: action.payload.data
    }
  }),
  CLEAR_RECTANGLE: (state, action) => {
    let newRectangles = {
      ...state.rectangles
    }
    delete newRectangles[action.payload]
    return {
      ...state,
      rectangles: newRectangles
    }
  },
  ADD_POLYGON: (state, action) => ({
    ...state,
    polygons: {
      ...state.polygons,
      [action.payload.id]: action.payload.data
    }
  }),
  CLEAR_POLYGON: (state, action) => {
    let newPolygons = {
      ...state.polygons
    }
    delete newPolygons[action.payload]
    return {
      ...state,
      polygons: newPolygons
    }
  },
  SET_HEATMAP: (state, action) => ({
    ...state,
    heatmapData: action.payload
  }),
  SET_CENTER: (state, action) => {
    if (isEqual(state.center, action.payload)) {
      return state
    }

    return {
      ...state,
      center: action.payload
    }
  },
  BOUNDS_CHANGED: (state, action) => {
    return {
      ...state,
      bounds: action.payload,
      fitBounds: undefined
    }
  },
  FIT_BOUNDS: (state, action) => {
    if (isEqual(state.fitBounds, action.payload)) {
      return state
    }

    // NB: This won't work if we ever leave the North and West hemispheres ¯\_(ツ)_/¯
    const newBounds = {...action.payload}
    const latDiff = Math.abs(newBounds.north - newBounds.south)
    if (latDiff < MIN_LAT_DIFF) {
      newBounds.north += ((MIN_LAT_DIFF - latDiff) / 2)
      newBounds.south -= ((MIN_LAT_DIFF - latDiff) / 2)
    }
    const lngDiff = Math.abs(newBounds.east - newBounds.west)
    if (lngDiff < MIN_LNG_DIFF) {
      newBounds.east += ((MIN_LNG_DIFF - lngDiff) / 2)
      newBounds.west -= ((MIN_LNG_DIFF - lngDiff) / 2)
    }

    return {
      ...state,
      fitBounds: newBounds
    }
  },
  SET_ZOOM: (state, action) => ({
    ...state,
    zoom: action.payload
  }),
  DRAW_CIRCLE: (state, action) => ({
    ...state,
    circle: action.payload
  }),
  DRAW_RECTANGLE: (state, action) => ({
    ...state,
    rectangle: action.payload
  }),
  RESET_MAP: (state, action) => ({
    ...state,
    circle: undefined,
    markers: {},
    rectangles: {},
    polygons: {},
    rectangle: undefined
  })
}, initialState)

export default reducer
