import OrdersAPI from '../api/orders'
import KitchensAPI from '../api/kitchens'
import { handleHttpResponse } from '../lib/handle-http-response'
import { keys, slice, pick, keyBy, mapValues } from 'lodash'

export const RESET_ORDER_DETAILS = 'RESET_ORDER_DETAILS'
export const INIT_FROM_EXISTING_ORDER = 'INIT_FROM_EXISTING_ORDER'
export const UPDATE_ORDER_PROPERTIES = 'UPDATE_ORDER_PROPERTIES'
export const VERIFY_ADDRESS = 'VERIFY_ADDRESS'
export const ADD_LINE_ITEM = 'ADD_LINE_ITEM'
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM'
export const SAVE_LINE_ITEM_EDITS = 'SAVE_LINE_ITEM_EDITS'
export const CREATE_ORDER = 'CREATE_ORDER'

export const SET_ADD_ITEM_ID = 'SET_ADD_ITEM_ID'
export const SET_ADD_ITEM_MODIFIER_GROUP_SELECTIONS = 'SET_ADD_ITEM_MODIFIER_GROUP_SELECTIONS'
export const SET_ADD_ITEM_QUANTITY = 'SET_ADD_ITEM_QUANTITY'

export const SET_EDIT_ITEM_MODIFIER_GROUP_SELECTIONS = 'SET_EDIT_ITEM_MODIFIER_GROUP_SELECTIONS'

export const TOGGLE_USER_SEARCH_MODAL = 'TOGGLE_USER_SEARCH_MODAL'
export const TOGGLE_ADDRESS_SELECTOR_MODAL = 'TOGGLE_ADDRESS_SELECTOR_MODAL'
export const TOGGLE_ADD_ITEM_MODAL = 'TOGGLE_ADD_ITEM_MODAL'
export const TOGGLE_EDIT_ITEM_MODAL = 'TOGGLE_EDIT_ITEM_MODAL'

const initialState = {
  order: {},
  modal_item: null,
  loading: false,
  error: null,
  ui: {
    user_search_modal: false,
    address_selector_modal: false,
    add_item_modal: false,
    edit_item_modal: false
  }
}

const convertModifiersToInput = (modifiers) => (keys(modifiers).map(key => ({ group_id: key, selections: modifiers[key] })))
const convertModifiersToStorage = (modifiers) => (mapValues(keyBy(modifiers, m => (m.group_id)), 'selections'))

// Reset all order details
export function resetOrderDetails() {
  return {
    type: RESET_ORDER_DETAILS
  }
}

// Initialize our new order from an existing order
export function initFromExistingOrder(sourceDetails, resetLineItems) {
  return {
    type: INIT_FROM_EXISTING_ORDER,
    sourceDetails: sourceDetails,
    resetLineItems: resetLineItems
  }
}

// Update properties on the order we're building
export function updateOrderProperties(newProperties) {
  return {
    type: UPDATE_ORDER_PROPERTIES,
    new_properties: newProperties
  }
}

// Check address is in kitchens' bounds
export function verifyAddress(location) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, VERIFY_ADDRESS, KitchensAPI.locateKitchen(location))
  }
}

// Add and remove items from the order we're building
export function addLineItem(itemId, modifiers, quantity) {
  return {
    type: ADD_LINE_ITEM,
    item_id: itemId,
    modifiers: modifiers,
    quantity: quantity
  }
}
export function removeLineItem(itemIndex) {
  return {
    type: REMOVE_LINE_ITEM,
    item_index: itemIndex
  }
}
export function saveLineItemEdits(itemIndex, modifiers) {
  return {
    type: SAVE_LINE_ITEM_EDITS,
    item_index: itemIndex,
    modifiers: modifiers
  }
}

// Create the order
export function createOrder(kitchenId, data) {
  const userId = data.user && data.user.id
  const postData = {
    ...data,
    user_id: userId
  }
  return (dispatch) => {
    return handleHttpResponse(dispatch, CREATE_ORDER, OrdersAPI.createOrder(kitchenId, postData))
  }
}

// State for the add item modal
export function setAddItemId(itemId) {
  return {
    type: SET_ADD_ITEM_ID,
    item_id: itemId
  }
}
export function setAddItemModifierGroupSelections(groupId, selections) {
  return {
    type: SET_ADD_ITEM_MODIFIER_GROUP_SELECTIONS,
    group_id: groupId,
    selections: selections
  }
}

export function setAddItemQuantity(quantity) {
  return {
    type: SET_ADD_ITEM_QUANTITY,
    quantity: quantity
  }
}

// State for the edit item modal
export function setEditItemModifierGroupSelections(groupId, selections) {
  return {
    type: SET_EDIT_ITEM_MODIFIER_GROUP_SELECTIONS,
    group_id: groupId,
    selections: selections
  }
}

// Toggle the different modal UIs
export function toggleUserSearchModal(open) {
  return {
    type: TOGGLE_USER_SEARCH_MODAL,
    open: open
  }
}
export function toggleAddressSelectorModal(open) {
  return {
    type: TOGGLE_ADDRESS_SELECTOR_MODAL,
    open: open
  }
}
export function toggleAddItemModal(open) {
  return {
    type: TOGGLE_ADD_ITEM_MODAL,
    open: open
  }
}
export function toggleEditItemModal(open, itemIndex = null) {
  return {
    type: TOGGLE_EDIT_ITEM_MODAL,
    open: open,
    item_index: itemIndex
  }
}

const verifyAddressHandler = (state, action) => {
  const error = action.error && 'Address not in delivery zone'
  return {
    ...state,
    error
  }
}

const initFromExistingOrderHandler = (state, action) => {
  const source = pick(action.sourceDetails, ['delivery_address', 'delivery_instructions', 'user', 'phone_number', 'line_items', 'pickup'])
  const user = pick(action.sourceDetails.user, ['id', 'first_name', 'last_name', 'full_name', 'phone_number', 'email'])
  const line_items = action.resetLineItems ? [] : action.sourceDetails.line_items.map(li => (pick(li, ['item_id', 'modifiers', 'item_active', 'name', 'time_range']) ))

  return {
    ...state,
    order: {
      ...state.order,
      ...source,
      source_order_id: action.sourceDetails.id,
      user: { ...user },
      line_items: line_items,
      phone_number: action.sourceDetails.phone_number || user.phone_number
    }
  }
}

const addLineItemHandler = (state, action) => {
  const new_item = {
    item_id: action.item_id,
    modifiers: convertModifiersToInput(action.modifiers)
  }
  let new_items_array = []
  for (var i = 0 ; i < action.quantity; i++) {
    new_items_array.push({...new_item})
  }
  const line_items = state.order.line_items ? [...state.order.line_items].concat(new_items_array) : new_items_array

  return {
    ...state,
    order: {
      ...state.order,
      line_items: line_items
    }
  }
}

const saveLineItemEditsHandler = (state, action) => {
  const item = {
    ...state.order.line_items[action.item_index],
    modifiers: convertModifiersToInput(action.modifiers)
  }
  return {
    ...state,
    order: {
      ...state.order,
      line_items: [...state.order.line_items.slice(0, action.item_index), item, ...state.order.line_items.slice(action.item_index + 1)]
    }
  }
}

const toggleEditItemModalHandler = (state, action) => {
  if (!action.open) {
    return {
      ...state,
      modal_item: null,
      ui: {...state.ui, edit_item_modal: action.open }
    }
  }

  const item = {
    ...state.order.line_items[action.item_index],
    modifiers: convertModifiersToStorage(state.order.line_items[action.item_index].modifiers),
    index: action.item_index
  }

  return {
    ...state,
    modal_item: item,
    ui: {...state.ui, edit_item_modal: action.open }
  }
}

const ACTION_HANDLERS = {
  [RESET_ORDER_DETAILS]: (state, action) => ({
    ...state,
    order: {}
  }),
  [INIT_FROM_EXISTING_ORDER]: initFromExistingOrderHandler,
  [UPDATE_ORDER_PROPERTIES]: (state, action) => ({...state, order: { ...state.order, ...action.new_properties } }),
  [VERIFY_ADDRESS]: verifyAddressHandler,
  [ADD_LINE_ITEM]: addLineItemHandler,
  [REMOVE_LINE_ITEM]: (state, action) => {
    return {
      ...state,
      order: {
        ...state.order,
        line_items: [...slice(state.order.line_items, 0, action.item_index), ...slice(state.order.line_items, action.item_index + 1)]
      }
    }
  },
  [SAVE_LINE_ITEM_EDITS]: saveLineItemEditsHandler,
  [CREATE_ORDER]: (state, action) => ({
    ...state,
    loading: action.loading,
    error: action.error && action.payload,
    order: {
      ...state.order,
      ...(action.error ? {} : action.payload)
    }
  }),

  [SET_ADD_ITEM_ID]: (state, action) => ({...state, modal_item: { item_id: action.item_id, quantity: 1 }, }),
  [SET_ADD_ITEM_MODIFIER_GROUP_SELECTIONS]: (state, action) => ({
    ...state,
    modal_item: {
      ...state.modal_item,
      modifiers: {
        ...state.modal_item.modifiers,
        [action.group_id]: action.selections
      }
    }
  }),
  [SET_ADD_ITEM_QUANTITY]: (state, action) => ({
    ...state,
    modal_item: {
      ...state.modal_item,
      quantity: action.quantity
    }
  }),

  [TOGGLE_USER_SEARCH_MODAL]: (state, action) => ({...state, ui: {...state.ui, user_search_modal: action.open} }),
  [TOGGLE_ADDRESS_SELECTOR_MODAL]: (state, action) => ({...state, ui: {...state.ui, address_selector_modal: action.open} }),
  [TOGGLE_ADD_ITEM_MODAL]: (state, action) => ({...state, modal_item: null, ui: {...state.ui, add_item_modal: action.open } }),
  [TOGGLE_EDIT_ITEM_MODAL]: toggleEditItemModalHandler
}

export default function adHocOrderReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
