import React from 'react'
import Loading from '../../../components/Loading'

const FrontLoading = () => (
  <div style={{padding: '300px 0'}}>
    <Loading />
  </div>
)

export default FrontLoading
