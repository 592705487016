import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import './Modal.sass'

class Modal extends Component {

  state = {
    maxDialogHeight: 'auto'
  }

  adjustMaxDialogHeight = () => {
    this.setState({maxDialogHeight: document.documentElement.clientHeight - 100})
  }

  resizeHandler = debounce(() => { this.adjustMaxDialogHeight() }, 500)

  componentDidMount = () => {
      this.adjustMaxDialogHeight()
      window.addEventListener('resize', this.resizeHandler)
  }

  componentWillUnmount = () => {
      window.removeEventListener('resize', this.resizeHandler)
  }

  render() {
    const { isOpen, title, children, footer } = this.props

    if (!isOpen) {
      return (null)
    }

    const outerClassname = this.props.modalId ? `modal-${this.props.modalId}` : ''

    return (
      <div className={outerClassname}>
        <div className='modal' style={{display: 'block'}}>
          <div className='modal-dialog'>
            <div className='modal-content' style={{maxHeight: this.state.maxDialogHeight, minHeight: this.props.minHeight}}>
              <div className='modal-header'>
                {title ? <h4 className='modal-title'>{title}</h4> : ''}
                <button type='button' className='close' onClick={this.props.onClose}>
                 <span>x</span>
                </button>
              </div>
              <div className='modal-body'>
              {children}
              </div>
              {footer ? <div className='modal-footer'>{footer}</div> : null}
            </div>
          </div>
        </div>
        <div className='modal-backdrop show in' />
      </div>
    )
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  footer: PropTypes.any,
  minHeight: PropTypes.string,
  modalId: PropTypes.string,
}

export default Modal