import request from 'superagent';
import { transform } from 'lodash';

import { getUrl, withAuthHeader } from 'api/HttpClient';

export const JSON_API_CONTENT_TYPE = 'application/vnd.api+json';
export const V1_CONTENT_TYPE_HEADER = `${JSON_API_CONTENT_TYPE}`;

const createRequestBody = (id, type, attributes, relationshipsData) => {
  const relationships = relationshipsData ?
    { relationships: transform(relationshipsData, (result, value, key) => result[key] = { data: value }, {}) } :
    null;

  return {
    data: {
      id, type, attributes
    },
    ...relationships
  }
}

const responseHandler = (resolve, reject) => {
  return (err, res) => {
    if (err) {
      reject(err);
    } else {
      resolve({
        body: res.body,
        headers: res.headers
      });
    }
  }
}

const JsonApiHttpClient = {
  get: (path, headers={}, query={}, contentType = V1_CONTENT_TYPE_HEADER) => new Promise((resolve, reject) => {
    request
      .get(getUrl(path))
      .query(query)
      .set(withAuthHeader(headers))
      .accept(JSON_API_CONTENT_TYPE)
      .type(contentType)
      .end(responseHandler(resolve, reject));
  }),

  post: (path, { id, type, attributes, relationshipsData }, headers={}, contentType = V1_CONTENT_TYPE_HEADER) =>
    new Promise((resolve, reject) => {
      request
        .post(getUrl(path))
        .set(withAuthHeader(headers))
        .send(createRequestBody(id, type, attributes, relationshipsData))
        .accept(JSON_API_CONTENT_TYPE)
        .type(contentType)
        .end(responseHandler(resolve, reject));
    }
  ),

  put: (path, { id, type, attributes, relationshipsData }, headers = {}, contentType = V1_CONTENT_TYPE_HEADER) =>
    new Promise((resolve, reject) => {
      request
        .put(getUrl(path))
        .set(withAuthHeader(headers))
        .send(createRequestBody(id, type, attributes, relationshipsData))
        .accept(JSON_API_CONTENT_TYPE)
        .type(contentType)
        .end(responseHandler(resolve, reject));
    }
  ),

  del: (path, headers = {}, contentType = V1_CONTENT_TYPE_HEADER) => new Promise((resolve, reject) => {
    request
      .del(getUrl(path))
      .set(withAuthHeader(headers))
      .accept(JSON_API_CONTENT_TYPE)
      .type(contentType)
      .end(responseHandler(resolve, reject));
  }),

  patch: (path, { id, type, attributes, relationshipsData }, headers = {}, contentType = V1_CONTENT_TYPE_HEADER) =>
    new Promise((resolve, reject) => {
      request
        .patch(getUrl(path))
        .set(withAuthHeader(headers))
        .send(createRequestBody(id, type, attributes, relationshipsData))
        .accept(JSON_API_CONTENT_TYPE)
        .type(contentType)
        .end(responseHandler(resolve, reject));
    }
  ),

  patchMultiple: (path, data, headers = {}, contentType = V1_CONTENT_TYPE_HEADER) =>
    new Promise((resolve, reject) => {
      request
        .patch(getUrl(path))
        .set(withAuthHeader(headers))
        .send({ data })
        .accept(JSON_API_CONTENT_TYPE)
        .type(contentType)
        .end(responseHandler(resolve, reject));
    }
  )
};

export default JsonApiHttpClient;
