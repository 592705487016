import { createActions } from 'redux-actions';

import ActionTypes from '../constants/ActionTypes';
import HttpClient, { V2_ACCEPT_HEADER } from '../api/HttpClient';

export default createActions({
  [ActionTypes.TRUCKS_LOAD]: (kitchenSlug) => ({
    promise: HttpClient.get(`/api/kitchens/${kitchenSlug}/trucks`),
    data: { kitchenSlug }
  }),
  [ActionTypes.TRUCK_LOAD]: (truckId) => ({
    promise: HttpClient.get(`/api/trucks/${truckId}`)
  }),
  [ActionTypes.TRUCK_ITEMS_LOAD]: (truckId) => ({
    promise: HttpClient.get(`/api/trucks/${truckId}/items`)
  }),
  [ActionTypes.TRUCK_MOVE_UP]: (truckId) => ({
    promise: HttpClient.put(`/api/admin/trucks/${truckId}/move_up`, {}, {}, V2_ACCEPT_HEADER)
  }),
  [ActionTypes.TRUCK_MOVE_DOWN]: (truckId) => ({
    promise: HttpClient.put(`/api/admin/trucks/${truckId}/move_down`, {}, {}, V2_ACCEPT_HEADER)
  }),
  [ActionTypes.TRUCK_ITEM_MOVE_UP]: (truckItemId) => ({
    promise: HttpClient.put(`/api/admin/truck_items/${truckItemId}/move_up`, {}, {}, V2_ACCEPT_HEADER)
  }),
  [ActionTypes.TRUCK_ITEM_MOVE_DOWN]: (truckItemId) => ({
    promise: HttpClient.put(`/api/admin/truck_items/${truckItemId}/move_down`, {}, {}, V2_ACCEPT_HEADER)
  }),
  [ActionTypes.TRUCK_UPDATE]: (truckId, data) => ({
    promise: HttpClient.put(`/api/trucks/${truckId}`, data)
  }),
  [ActionTypes.TRUCK_UNLOAD]: () => {}
});
