import { handleActions } from 'redux-actions';

import ActionTypes from '../constants/ActionTypes';
import { handleRequest, handleError } from './util.js';

const initialState = {
  loading: false,
  asset: {},
  error: false
}

export default handleActions({
  [ActionTypes.ASSET_UPLOAD_REQUEST]: handleRequest,
  [ActionTypes.ASSET_UPLOAD_SUCCESS]: (state, action) => ({
    ...state, asset: action.payload, loading: false
  }),
  [ActionTypes.ASSET_UPLOAD_ERROR]: handleError,
}, initialState);
