import React from 'react'
import PropTypes from 'prop-types'

const SearchIcon = ({ color, width, height }) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className="nav-icon nav-icon-search" viewBox="0 0 23 23">
    <g fill={color} fillRule="evenodd">
      <path d="M8.97 17.63c-4.85 0-8.8-3.95-8.8-8.8 0-4.86 3.95-8.8 8.8-8.8 4.86 0 8.8 3.94 8.8 8.8 0 4.85-3.94 8.8-8.8 8.8zm0-14.47C5.85 3.16 3.3 5.7 3.3 8.83c0 3.12 2.55 5.67 5.67 5.67 3.13 0 5.67-2.55 5.67-5.67 0-3.13-2.54-5.67-5.67-5.67z"/>
      <path d="M20.3 22.35L13.03 15l2.23-2.2 7.27 7.34-2.23 2.2"/>
    </g>
  </svg>)
}

SearchIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

SearchIcon.defaultProps = {
  color: '#A6AFBC',
  width: 23,
  height: 23
}


export default SearchIcon
