import { handleHttpResponse } from '../lib/handle-http-response'
import DriversAPI from '../api/drivers'
import { get } from 'lodash'

export const LOAD_DRIVER = 'LOAD_DRIVER'
export const CHANGE_TAB = 'CHANGE_TAB'
export const TOGGLE_DRIVER = 'TOGGLE_DRIVER'
export const UPDATE_KITCHENS = 'UPDATE_KITCHENS'
export const UPDATE_VEHICLES = 'UPDATE_VEHICLES'
export const LOAD_DRIVER_ORDER = 'LOAD_DRIVER_ORDER'
export const GET_EARNINGS_REPORT = 'GET_EARNINGS_REPORT'
export const LOAD_DRIVER_EVENTS = 'LOAD_DRIVER_EVENTS'
export const LOAD_DRIVER_STANDBY_SLOTS = 'LOAD_DRIVER_STANDBY_SLOTS'
export const ADD_USER_TO_KITCHEN = 'ADD_USER_TO_KITCHEN'
export const LOAD_DRIVER_VEHICLES = 'LOAD_DRIVER_VEHICLES'
export const EXECUTE_BACKGROUND_CHECK = 'EXECUTE_BACKGROUND_CHECK'
export const ASSIGN_TRAINING = 'ASSIGN_TRAINING'
export const PING_DRIVER = 'PING_DRIVER'
export const UPDATE_PHONE_NUMBER = 'UPDATE_PHONE_NUMBER'

export function loadDriver(driver_id){
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER, DriversAPI.get(driver_id))
  }
}

export function loadDriverOrders(driver_id, offset, limit) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER_ORDER, DriversAPI.orders(driver_id, offset, limit))
  }
}

export function getEarningsReport(driver_id, start_date, end_date, offset=0, limit=20) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, GET_EARNINGS_REPORT, DriversAPI.getEarningsReport(driver_id, start_date, end_date, offset, limit))
  }
}

export function loadDriverEvents(driver_id, offset, limit) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER_EVENTS, DriversAPI.events(driver_id, offset, limit))
  }
}

export function loadDriverStandbySlots(driver_id, offset, limit) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER_STANDBY_SLOTS, DriversAPI.standby_slots(driver_id, offset, limit))
  }
}

export function executeBackgroundCheck(driver_id) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, EXECUTE_BACKGROUND_CHECK, DriversAPI.backgroundCheck(driver_id))
  }
}

export function assignTraining(driver_id) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, ASSIGN_TRAINING, DriversAPI.assignTraining(driver_id))
  }
}

export function loadDriverVehicles(driver_id) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER_VEHICLES, DriversAPI.getVehicles(driver_id))
  }
}

export function activateDriver(driverId) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, TOGGLE_DRIVER, DriversAPI.enableDriver(driverId))
  }
}

export function deactivateDriver(driverId) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, TOGGLE_DRIVER, DriversAPI.disableDriver(driverId))
  }
}

export function pingDriver(driverId) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, PING_DRIVER, DriversAPI.pingDriver(driverId))
  }
}

export function updatePhoneNumber(driverId, phoneNumber) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, UPDATE_PHONE_NUMBER, DriversAPI.updatePhoneNumber(driverId, phoneNumber))
  }
}

export function sendToggleKitchen(driver_id, kitchen_id, enable_kitchen) {
  return(dispatch) => {
    if (enable_kitchen) {
      return handleHttpResponse(dispatch, UPDATE_KITCHENS, DriversAPI.enableKitchen(driver_id, kitchen_id))
    } else {
      return handleHttpResponse(dispatch, UPDATE_KITCHENS, DriversAPI.disableKitchen(driver_id, kitchen_id))
    }
  }
}

export function sendAddUserToKitchen(driver_id, kitchen_id) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, ADD_USER_TO_KITCHEN, DriversAPI.addDriverToKitchen(driver_id, kitchen_id))
  }
}

export function sendToggleVehicle(vehicle_id, enable_vehicle) {
  return(dispatch) => {
    if (enable_vehicle) {
      return handleHttpResponse(dispatch, UPDATE_VEHICLES, DriversAPI.enableVehicle(vehicle_id))
    } else {
      return handleHttpResponse(dispatch, UPDATE_VEHICLES, DriversAPI.disableVehicle(vehicle_id))
    }
  }
}

export function sendRevertRejection(driver_id) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER, DriversAPI.revertRejection(driver_id))
  }
}


const loadDriverHandler = (state, action) => {
  return {
    ...state,
    loading: action.loading || false,
    error: action.error,
    driver: action.payload || { },
    backgroundCheck: get(action, 'payload.background_check_executed', false),
    training:        get(action, 'payload.training_assigned', false),
    orders: {
      loading: false,
      error: false,
      data: {
        list: [ ],
        meta: { }
      }
    }
  }
}

const loadOrderHandler = (state, action) => {
  return {
    ...state,
    orders: {
      loading: action.loading,
      error: action.error,
      data: action.payload || state.orders.data
    }
  }
}

const getEarningsReportHandler = (state, action) => {
  const payload = action.payload || {};
  return {
    ...state,
    loading: action.loading || false,
    error: action.error,
    earnings_report: payload
  }
}

const loadEventsHandler = (state, action) => {
  return {
    ...state,
    events: {
      loading: action.loading,
      error: action.error,
      data: action.payload || state.events.data
    }
  }
}

const loadStandbySlotsHandler = (state, action) => {
  return {
    ...state,
    standbySlots: {
      loading: action.loading,
      error: action.error,
      data: action.payload || state.standbySlots.data
    }
  }
}

const loadVehiclesHandler = (state, action) => {
  return {
    ...state,
    vehicles: {
      [state.driver.id]: action.payload || [ ]
    }
  }
}

const setTab = (state, action) => {
  return {
    ...state,
    activeTab: action.tab
  }
}

const toggleDriverState = (state, action) => {
  let data = action.payload || state.driver
  return {
    ...state,
    loading: action.loading,
    error: action.error,
    driver: {
      ...state.driver,
      driver_state: data.driver_state
    }
  }
}

const updateKitchens = (state, action) => {
  let data = action.payload || state.driver
  return {
    ...state,
    loading: action.loading,
    error: action.error,
    driver: {
      ...state.driver,
      kitchen_approvals: data.kitchens
    }
  }
}

const updateUserKitchens = (state, action) => {

  let kitchen_approvals = state.driver.kitchen_approvals
  if (action.payload !== undefined)
    kitchen_approvals.push(action.payload)

  return {
    ...state,
    loading: action.loading,
    error: action.error,
    driver: {
      ...state.driver,
      kitchen_approvals: kitchen_approvals
    }
  }
}

const updateVehicles = (state, action) => {
  let updated_vehicle = action.payload || {}
  let vehicles = state.vehicles[state.driver.id].filter(vehicle => vehicle.id !== updated_vehicle.id)
  if (action.payload)
    vehicles.push(updated_vehicle)
  return {
    ...state,
    loading: action.loading || false,
    error: action.error,
    vehicles: {
      ...state.vehicles,
      [state.driver.id]: vehicles
    }
  }
}

const updatePhoneNumberHandler = (state, action) => {
  let data = action.payload || state.driver
  return {
    ...state,
    loading: action.loading,
    error: action.error,
    driver: {
      ...state.driver,
      phone_number: data.phone_number
    }
  }
}

const executeBackgroundCheckHandler = (state, action) => {
  return {
    ...state,
    backgroundCheck: true
  }
}

const assignTrainingHandler = (state, action) => {
  return {
    ...state,
    training: true
  }
}

const initialState = {
  loading: false,
  error: false,
  driver: { },
  vehicles: { },
  orders: {
    loading: false,
    error: false,
    data: {
      list: [ ],
      meta: { }
    }
  },
  events: {
    loading: false,
    error: false,
    data: {
      list: [ ],
      meta: { }
    }
  },
  standbySlots: {
    loading: false,
    error: false,
    data: {
      list: [ ],
      meta: { }
    }
  },
  activeTab: 'driver',
  backgroundCheck: false,
  training: false,
}

const ACTION_HANDLERS = {
  [LOAD_DRIVER]: loadDriverHandler,
  [LOAD_DRIVER_VEHICLES]: loadVehiclesHandler,
  [CHANGE_TAB]: setTab,
  [TOGGLE_DRIVER]: toggleDriverState,
  [UPDATE_KITCHENS]: updateKitchens,
  [UPDATE_VEHICLES]: updateVehicles,
  [LOAD_DRIVER_ORDER]: loadOrderHandler,
  [GET_EARNINGS_REPORT]: getEarningsReportHandler,
  [LOAD_DRIVER_EVENTS]: loadEventsHandler,
  [LOAD_DRIVER_STANDBY_SLOTS]: loadStandbySlotsHandler,
  [ADD_USER_TO_KITCHEN]: updateUserKitchens,
  [EXECUTE_BACKGROUND_CHECK]: executeBackgroundCheckHandler,
  [ASSIGN_TRAINING]: assignTrainingHandler,
  [UPDATE_PHONE_NUMBER]: updatePhoneNumberHandler
}

export default function driverReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
