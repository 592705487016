import keyMirror from 'fbjs/lib/keyMirror';

export default keyMirror({
  PLACES_FILTERED_LOAD: null,
  PLACES_FILTERED_LOAD_REQUEST: null,
  PLACES_FILTERED_LOAD_SUCCESS: null,
  PLACES_FILTERED_LOAD_ERROR: null,

  EDITING_PLACE_LOAD: null,
  EDITING_PLACE_REQUEST: null,
  EDITING_PLACE_SUCCESS: null,
  EDITING_PLACE_ERROR: null,

  UPDATE_EDITING_PLACE: null,
  RESET_EDITING_PLACE: null,

  UPDATE_FILTER: null,
  RESET_FILTER: null,

  UPDATE_FOCUSED_PLACE: null,
  RESET_FOCUSED_PLACE: null,

  UPDATE_HOVERED_DROPOFF_LOCATION: null,
  RESET_HOVERED_DROPOFF_LOCATION: null,

  UPDATE_MOVING_DROPOFF_LOCATION: null,
  RESET_MOVING_DROPOFF_LOCATION: null,
});
