import { handleActions } from 'redux-actions';
import ActionTypes from '../constants/ActionTypes';
import { get } from 'lodash'

const initialState = {
  error: false,
  loading: false,
  editKitchen: undefined,
  hoursByKitchen: { },
  overriddenHoursByKitchen: { }
}

export default handleActions({
  LOAD_KITCHENS: (state, action) => {
    const { payload, loading } = action
    const hoursByKitchen = state.hoursByKitchen
    const overriddenHoursByKitchen = state.overriddenHoursByKitchen
    if (!loading) {
      for (let i=0; i < payload.length; i++) {
        hoursByKitchen[payload[i].id] = payload[i].original_hours
        overriddenHoursByKitchen[payload[i].id] = payload[i].overridden_hours
      }
    }

    return {
      ...state,
      hoursByKitchen,
      overriddenHoursByKitchen
    }
  },
  [ActionTypes.EDIT_KITCHEN_HOURS]: (state, action) => ({
    ...state,
    editKitchen: action.payload
  }),
  [ActionTypes.CLOSE_EDIT_KITCHEN_HOURS]: (state, action) => ({
    ...state,
    editKitchen: undefined
  }),
  UPDATE_KITCHEN_HOURS_REQUEST: (state, action) => ({
    ...state,
    error: false,
    loading: true
  }),
  UPDATE_KITCHEN_HOURS_SUCCESS: (state, action) => {
    const kitchen = get(action.payload, 'body', { })
    return {
      ...state,
      loading: false,
      error: false,
      hoursByKitchen: {
        ...state.hoursByKitchen,
        [kitchen.id]: kitchen.original_hours
      },
      overriddenHoursByKitchen: {
        ...state.overriddenHoursByKitchen,
        [kitchen.id]: kitchen.overridden_hours
      }
    }
  },
  UPDATE_KITCHEN_HOURS_ERROR: (state, action) => {
    return {
      ...state,
      loading: false,
      error: get(action.payload, 'response.body')
    }
  }
}, initialState);
