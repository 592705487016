import { handleHttpResponse } from '../lib/handle-http-response'
import DriversAPI from '../api/drivers'
import { get } from 'lodash'

export const LOAD_DRIVER_BONUS = 'LOAD_DRIVER_BONUS'
export const CREATE_DRIVER_BONUS = 'CREATE_DRIVER_BONUS'
export const BONUS_MODAL = 'BONUS_MODAL'

export function loadDriverBonuses(id) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_DRIVER_BONUS, DriversAPI.fetchBonuses(id))
  }
}

export function createDriverBonus(id, data) {
  return (dispatch) => handleHttpResponse(dispatch, CREATE_DRIVER_BONUS, DriversAPI.createBonus(id, data))
}

export const openBonusModal = () => ({
  type: BONUS_MODAL,
  payload: true
})

export const closeBonusModal = () => ({
  type: BONUS_MODAL,
  payload: false
})

const initialState = {
  ui: {
    modal: false,
  },
  newBonus: {
    loading: false,
    error: false,
    errors: { },
    data: { }
  },
  bonusList: {
    loading: false,
    error: false,
    data: { }
  }
}

const appendNewBonus = (state, action) => {
  if (action.error || action.loading) {
    return state
  }

  const list     = get(state, 'data.list', [ ])
  const newBonus = action.payload
  const data = {
    ...state.data,
    list: [
      newBonus,
      ...list
    ]
  }

  return {
    ...state,
    data
  }
}

const createHandler = (state, action) => ({
  ...state,
  bonusList: appendNewBonus(state.bonusList, action),
  newBonus: {
    loading: action.loading || false,
    error:   action.error   || false,
    errors:  get(action, 'payload.response.body.errors', { }),
    data:    action.payload || { }
  }
})

const bonusModalHandler = (state, action) => ({
  ...state,
  ui: { modal: action.payload },
  newBonus: {
    loading: false,
    error: false,
    errors: { },
    data: { }
  }
})

const loadDriverBonusHandler = (state, action) => ({
  ...state,
  bonusList: {
    loading: action.loading || false,
    error: action.error || false,
    data: action.payload || state.bonusList.data
  }
})

const ACTION_HANDLERS = {
  [LOAD_DRIVER_BONUS]: loadDriverBonusHandler,
  [CREATE_DRIVER_BONUS]: createHandler,
  [BONUS_MODAL]: bonusModalHandler
}

export default function driverReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
