import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, reject } from 'lodash'
import { connect } from 'react-redux'
import Kitchen from './Kitchen'

class HomeView extends Component {

  render() {
    const { loading, list }  = this.props
    const loadingMessage = isEmpty(list) && loading ? "loading..." : null
    const kitchens = reject(list, k => {
      return k.kitchen_state === 'disabled'
    })

    return (
      <div className="row mt-4">
        {loadingMessage}
        {kitchens.map(k => (<Kitchen kitchen={k} key={k.id} />))}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { kitchens, kitchenHours } = state
  const list = (kitchens.list || [ ]).map(id => (kitchens.byId[id]))

  return {
    editKitchen: kitchenHours.editKitchen,
    list,
    loading: kitchens.loading,
    error: kitchens.error
  }
}

const mapDispatchToProps = { }

HomeView.propTypes = {
  list: PropTypes.array.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
