import reduce from 'lodash/reduce'
import filter from 'lodash/filter'

export function nestSubOrders(initialResponse) {
  // Pre-seed set of found groups to include the groups in the response
  const foundGroups = reduce(filter(initialResponse, o => (o.group_order)), (accumulator, order) => {
    accumulator[order.id] = true
    return accumulator
  }, {})

  const newResponse = initialResponse.map((order) => {
    // Process if this order is a suborder and a) its group hasn't already been processed, or b) its group isn't also in the response set
    if (order.sub_order && !foundGroups[order.group_id]) {
      foundGroups[order.group_id] = true

      const subOrders = initialResponse.filter(o => (o.group_id === order.group_id))

      return {
        group_number: order.group_number,
        group_order: true,
        id: order.group_id,
        display_ordered_at: order.display_ordered_at,
        owner: order.group_owner,
        group_state: order.group_state,
        ordered_at: order.ordered_at,
        sub_orders: subOrders,
        source: order.group_source
      }
    } else if (order.group_order) {
      const subOrders = initialResponse.filter(o => (o.group_id === order.id))

      return {
        group_number: order.number,
        group_order: true,
        id: order.id,
        ordered_at: order.ordered_at,
        display_ordered_at: order.display_ordered_at,
        issue: order.issue,
        rating: order.rating,
        owner: true,
        group_state: order.state,
        promo_code: order.promo_code,
        sub_orders: subOrders,
        source: order.source
      }
    } else if (!order.group_order && !order.sub_order) {
      return order
    }

    return null
  })

  return newResponse.filter((res) => {
    return res !== null
  })
}
