import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo';
import { client } from 'util/ApolloClient';

class AppContainer extends Component {
  shouldComponentUpdate () {
    return false
  }

  render () {
    const { routes, store, history } = this.props

    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router history={history} children={routes} />
        </Provider>
      </ApolloProvider>
    )
  }
}

AppContainer.propTypes = {
  routes : PropTypes.object.isRequired,
  store  : PropTypes.object.isRequired,
  history : PropTypes.object.isRequired
}

export default AppContainer
