export default (store) => ({
  layout: { fluid: true },
  path: '/places(/:id)',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    import(
      /* webpackChunkName: 'Places' */
      './components/Places'
    ).then(module => {
      cb(null, module.default)
    })
  }
})