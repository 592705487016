import client from './client'
import client2 from './client2'

export default {
  index: () => {
    return client
      .get('/api/admin/kitchens')
  },

  get: (id) => {
    return client
      .get(`/api/admin/kitchens/${id}`)
  },

  getMenu: (id) => {
    return client
      .get(`/api/admin/kitchens/${id}/menu`)
  },

  update: (id, data) => {
    return client
      .patch(`/api/admin/kitchens/${id}`)
      .send(data)
  },

  locateKitchen: (location) => {
    return client2
      .get(`/api/kitchens?q=${JSON.stringify(location)}`)
  }

}
