import CoreLayout from '../layouts/CoreLayout'
import Home from './Home'
import Orders from './Orders'
import Login from './Login'
import OrderDetails from './OrderDetails'
import IssueDetails from './IssueDetails'
import UserDetails from './UserDetails'
import OrderSearch from './OrderSearch'
import OrderReports from './OrderReports'
import UserSearch from './UserSearch'
import DriverSearch from './DriverSearch'
import DriverDetails from './DriverDetails'
import PromoCodes from './PromoCodes'
import AdHocOrder from './AdHocOrder'
import IssueSearch from './IssueSearch'
import MenuDetails from './MenuDetails'
import Teams from './Teams'
import ScheduleRanges from './ScheduleRanges'
import Places from './Places'
import DeliveryAreas from './DeliveryAreas'
import FrontApp from './FrontApp'

export const createRoutes = (store) => {
  return {
    path        : '/',
    component   : CoreLayout,
    indexRoute  : Home,
    childRoutes : [
      Orders(store),
      Login(store),
      OrderDetails(store),
      UserDetails(store),
      IssueDetails(store),
      OrderSearch(store),
      OrderReports(store),
      UserSearch(store),
      DriverSearch(store),
      DriverDetails(store),
      PromoCodes(store),
      AdHocOrder(store),
      IssueSearch(store),
      MenuDetails(store),
      ScheduleRanges(store),
      Places(store),
      DeliveryAreas(store),
      FrontApp,
      Teams
    ]
  }
}

export default createRoutes
