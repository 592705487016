import keyMirror from 'fbjs/lib/keyMirror';

export default keyMirror({
  KITCHENS_LOAD_MINIMAL: null,
  KITCHENS_LOAD_MINIMAL_REQUEST: null,
  KITCHENS_LOAD_MINIMAL_SUCCESS: null,
  KITCHENS_LOAD_MINIMAL_ERROR: null,

  ITEMS_SEARCH: null,
  ITEMS_SEARCH_REQUEST: null,
  ITEMS_SEARCH_SUCCESS: null,
  ITEMS_SEARCH_ERROR: null,

  SUGGESTED_ITEMS_SEARCH: null,
  SUGGESTED_ITEMS_SEARCH_REQUEST: null,
  SUGGESTED_ITEMS_SEARCH_SUCCESS: null,
  SUGGESTED_ITEMS_SEARCH_ERROR: null,

  LINK_ITEMS_SEARCH: null,
  LINK_ITEMS_SEARCH_REQUEST: null,
  LINK_ITEMS_SEARCH_SUCCESS: null,
  LINK_ITEMS_SEARCH_ERROR: null,

  ITEM_UPDATE: null,
  ITEM_UPDATE_REQUEST: null,
  ITEM_UPDATE_SUCCESS: null,
  ITEM_UPDATE_ERROR: null,

  ITEM_LOAD: null,
  ITEM_LOAD_REQUEST: null,
  ITEM_LOAD_SUCCESS: null,
  ITEM_LOAD_ERROR: null,

  ITEM_CREATE: null,
  ITEM_CREATE_REQUEST: null,
  ITEM_CREATE_SUCCESS: null,
  ITEM_CREATE_ERROR: null,

  ITEM_DELETE: null,
  ITEM_DELETE_REQUEST: null,
  ITEM_DELETE_SUCCESS: null,
  ITEM_DELETE_ERROR: null,

  ITEM_RESTORE: null,
  ITEM_RESTORE_REQUEST: null,
  ITEM_RESTORE_SUCCESS: null,
  ITEM_RESTORE_ERROR: null,

  ITEM_LINKED_TO: null,
  ITEM_LINKED_TO_REQUEST: null,
  ITEM_LINKED_TO_SUCCESS: null,
  ITEM_LINKED_TO_ERROR: null,

  ITEM_LOAD_SUGGESTED_ITEMS: null,
  ITEM_LOAD_SUGGESTED_ITEMS_REQUEST: null,
  ITEM_LOAD_SUGGESTED_ITEMS_SUCCESS: null,
  ITEM_LOAD_SUGGESTED_ITEMS_ERROR: null,

  ITEM_CLEAR_CURRENT: null,
  ITEM_CURRENT_CHANGE: null,

  ITEM_CLEAR_SEARCH_RESULTS: null,

  ITEM_CLEAR_SUGGESTED_ITEMS_SEARCH_RESULTS: null,

  ITEM_ENTER_REMOVE_STATE: null,
  ITEM_EXIT_REMOVE_STATE: null,

  MODIFIER_UPDATE: null,
  MODIFIER_UPDATE_REQUEST: null,
  MODIFIER_UPDATE_SUCCESS: null,
  MODIFIER_UPDATE_ERROR: null,

  MODIFIER_MOVE_UP: null,
  MODIFIER_MOVE_UP_REQUEST: null,
  MODIFIER_MOVE_UP_SUCCESS: null,
  MODIFIER_MOVE_UP_ERROR: null,

  MODIFIER_MOVE_DOWN: null,
  MODIFIER_MOVE_DOWN_REQUEST: null,
  MODIFIER_MOVE_DOWN_SUCCESS: null,
  MODIFIER_MOVE_DOWN_ERROR: null,

  MODIFIER_CREATE: null,
  MODIFIER_CREATE_REQUEST: null,
  MODIFIER_CREATE_SUCCESS: null,
  MODIFIER_CREATE_ERROR: null,

  MODIFIER_LINK_ITEM: null,
  MODIFIER_LINK_ITEM_REQUEST: null,
  MODIFIER_LINK_ITEM_SUCCESS: null,
  MODIFIER_LINK_ITEM_ERROR: null,

  MODIFIER_UNLINK_ITEM: null,
  MODIFIER_UNLINK_ITEM_REQUEST: null,
  MODIFIER_UNLINK_ITEM_SUCCESS: null,
  MODIFIER_UNLINK_ITEM_ERROR: null,

  MODIFIER_NEW: null,

  MODIFIER_GROUP_UPDATE: null,
  MODIFIER_GROUP_UPDATE_REQUEST: null,
  MODIFIER_GROUP_UPDATE_SUCCESS: null,
  MODIFIER_GROUP_UPDATE_FAILURE: null,

  TAX_RATE_NEW: null,

  TAX_RATE_CREATE: null,
  TAX_RATE_CREATE_REQUEST: null,
  TAX_RATE_CREATE_SUCCESS: null,
  TAX_RATE_CREATE_ERROR: null,

  TAX_RATES_LOAD: null,
  TAX_RATES_LOAD_REQUEST: null,
  TAX_RATES_LOAD_SUCCESS: null,
  TAX_RATES_LOAD_ERROR: null,

  TRUCKS_LOAD: null,
  TRUCKS_LOAD_REQUEST: null,
  TRUCKS_LOAD_SUCCESS: null,
  TRUCKS_LOAD_ERROR: null,

  TRUCK_LOAD: null,
  TRUCK_LOAD_REQUEST: null,
  TRUCK_LOAD_SUCCESS: null,
  TRUCK_LOAD_ERROR: null,

  TRUCK_MOVE_UP: null,
  TRUCK_MOVE_UP_REQUEST: null,
  TRUCK_MOVE_UP_SUCCESS: null,
  TRUCK_MOVE_UP_ERROR: null,

  TRUCK_MOVE_DOWN: null,
  TRUCK_MOVE_DOWN_REQUEST: null,
  TRUCK_MOVE_DOWN_SUCCESS: null,
  TRUCK_MOVE_DOWN_ERROR: null,

  TRUCK_UPDATE: null,
  TRUCK_UPDATE_REQUEST: null,
  TRUCK_UPDATE_SUCCESS: null,
  TRUCK_UPDATE_ERROR: null,

  TRUCK_ITEMS_LOAD: null,
  TRUCK_ITEMS_LOAD_REQUEST: null,
  TRUCK_ITEMS_LOAD_SUCCESS: null,
  TRUCK_ITEMS_LOAD_ERROR: null,

  TRUCK_ITEM_MOVE_UP: null,
  TRUCK_ITEM_MOVE_UP_REQUEST: null,
  TRUCK_ITEM_MOVE_UP_SUCCESS: null,
  TRUCK_ITEM_MOVE_UP_ERROR: null,

  TRUCK_ITEM_MOVE_DOWN: null,
  TRUCK_ITEM_MOVE_DOWN_REQUEST: null,
  TRUCK_ITEM_MOVE_DOWN_SUCCESS: null,
  TRUCK_ITEM_MOVE_DOWN_ERROR: null,

  TRUCK_UNLOAD: null,

  ASSET_UPLOAD: null,
  ASSET_UPLOAD_REQUEST: null,
  ASSET_UPLOAD_SUCCESS: null,
  ASSET_UPLOAD_ERROR: null,

  NOTIFICATION_TOAST_CLEAR_TEXT: null,
  NOTIFICATION_TOAST_SUCCESS: null,
  NOTIFICATION_TOAST_ERROR: null,

  NOTIFICATION_TOAST_TRUCK_UPDATE_SUCCESS: null,
  NOTIFICATION_TOAST_TRUCK_ACTIVE_STATE_TOGGLE_MESSAGE: null,

  NOTIFICATION_TOAST_ITEM_TRUCK_ADD_SUCCESS: null,
  NOTIFICATION_TOAST_ITEM_TRUCK_REMOVE_SUCCESS: null,

  NOTIFICATION_TOAST_ITEM_SUGGESTED_ITEM_ADD_SUCCESS: null,
  NOTIFICATION_TOAST_ITEM_SUGGESTED_ITEM_REMOVE_SUCCESS: null,

  NOTIFICATION_TOAST_FIX_ERRORS_ABOVE: null,
  NOTIFICATION_TOAST_GENERIC_ERROR: null,

  NOTIFICATION_TOAST_MODIFIER_SAVE_CHANGES_SUCCESS: null,

  NOTIFICATION_TOAST_ITEM_UPDATE_SUCCESS: null,
  NOTIFICATION_TOAST_ITEM_DELETE_SUCCESS: null,

  NOTIFICATION_TOAST_CUSTOMER_ROLE_GRANT_SUCCESS: null,
  NOTIFICATION_TOAST_CUSTOMER_ROLE_REVOKE_SUCCESS: null,

  NOTIFICATION_TOAST_COMPONENT_CREATE_SUCCESS: null,

  NOTIFICATION_TOAST_PLACE_SAVE_SUCCESS: null,

  NOTIFICATION_TOAST_DROPOFF_LOCATION_SAVE_SUCCESS: null,
  NOTIFICATION_TOAST_DROPOFF_LOCATION_ERROR: null,
  NOTIFICATION_TOAST_DROPOFF_LOCATION_CREATE_SUCCESS: null,
  NOTIFICATION_TOAST_DROPOFF_LOCATION_ARCHIVE_SUCCESS: null,

  EDIT_KITCHEN_HOURS: null,
  CLOSE_EDIT_KITCHEN_HOURS: null,
  UPDATE_KITCHEN_HOURS: null,
  UPDATE_KITCHEN_HOURS_REQUEST: null,
  UPDATE_KITCHEN_HOURS_SUCCESS: null,

  // Teams search
  UPDATE_TEAMS_QUERY: null,
  UPDATE_TEAMS_PAGE: null,
  SEARCH_TEAMS: null,
  SEARCH_TEAMS_REQUEST: null,
  SEARCH_TEAMS_SUCCESS: null,
  SEARCH_TEAMS_ERROR: null,

  // Teams UI
  OPEN_EDIT_TEAM: null,
  OPEN_DELETE_TEAM: null,
  OPEN_MEMBERS_TEAM: null,

  // Teams load
  LOAD_TEAM: null,
  LOAD_TEAM_REQUEST: null,
  LOAD_TEAM_SUCCESS: null,
  LOAD_TEAM_ERROR: null,

  // Teams save
  UPDATE_TEAM_ADDRESS: null,
  SAVE_TEAM: null,
  SAVE_TEAM_REQUEST: null,
  SAVE_TEAM_SUCCESS: null,
  SAVE_TEAM_ERROR: null,

  // Teams delete
  DELETE_TEAM: null,
  DELETE_TEAM_REQUEST: null,
  DELETE_TEAM_SUCCESS: null,
  DELETE_TEAM_ERROR: null,

  // Teams members load
  UPDATE_MEMBERS_PAGE: null,
  LOAD_TEAM_MEMBERS: null,
  LOAD_TEAM_MEMBERS_REQUEST: null,
  LOAD_TEAM_MEMBERS_SUCCESS: null,
  LOAD_TEAM_MEMBERS_ERROR: null,

  // Teams members reset
  RESET_TEAM_MEMBER: null,
  RESET_TEAM_MEMBER_REQUEST: null,
  RESET_TEAM_MEMBER_SUCCESS: null,
  RESET_TEAM_MEMBER_ERROR: null,

  MENU_COMPONENTS_LOAD: null,
  MENU_COMPONENTS_LOAD_REQUEST: null,
  MENU_COMPONENTS_LOAD_SUCCESS: null,
  MENU_COMPONENTS_LOAD_ERROR: null,

  MENU_COMPONENT_CREATE: null,
  MENU_COMPONENT_CREATE_REQUEST: null,
  MENU_COMPONENT_CREATE_SUCCESS: null,
  MENU_COMPONENT_CREATE_ERROR: null,

  MENU_COMPONENT_UPDATE: null,
  MENU_COMPONENT_UPDATE_REQUEST: null,
  MENU_COMPONENT_UPDATE_SUCCESS: null,
  MENU_COMPONENT_UPDATE_ERROR: null,

  MENU_COMPONENT_LOAD: null,
  MENU_COMPONENT_LOAD_REQUEST: null,
  MENU_COMPONENT_LOAD_SUCCESS: null,
  MENU_COMPONENT_LOAD_ERROR: null,

  TRUCK_MENU_COMPONENT_LOAD: null,
  TRUCK_MENU_COMPONENT_LOAD_REQUEST: null,
  TRUCK_MENU_COMPONENT_LOAD_SUCCESS: null,
  TRUCK_MENU_COMPONENT_LOAD_ERROR: null,

  MENU_COMPONENT_MOVE_UP: null,
  MENU_COMPONENT_MOVE_UP_REQUEST: null,
  MENU_COMPONENT_MOVE_UP_SUCCESS: null,
  MENU_COMPONENT_MOVE_UP_ERROR: null,

  MENU_COMPONENT_MOVE_DOWN: null,
  MENU_COMPONENT_MOVE_DOWN_REQUEST: null,
  MENU_COMPONENT_MOVE_DOWN_SUCCESS: null,
  MENU_COMPONENT_MOVE_DOWN_ERROR: null,

  MENU_COMPONENT_ARCHIVE: null,
  MENU_COMPONENT_ARCHIVE_REQUEST: null,
  MENU_COMPONENT_ARCHIVE_SUCCESS: null,
  MENU_COMPONENT_ARCHIVE_ERROR: null,

  MENU_COMPONENT_UNLOAD: null,

  MENU_COMPONENT_ITEM_MOVE: null,
  MENU_COMPONENT_ITEM_MOVE_REQUEST: null,
  MENU_COMPONENT_ITEM_MOVE_SUCCESS: null,
  MENU_COMPONENT_ITEM_MOVE_ERROR: null,

  SCHEDULE_RANGES_LOAD: null,
  SCHEDULE_RANGES_LOAD_REQUEST: null,
  SCHEDULE_RANGES_LOAD_SUCCESS: null,
  SCHEDULE_RANGES_LOAD_ERROR: null,

  SCHEDULE_RANGE_TOGGLE_SELECT: null,
  SCHEDULE_RANGES_CLEAR_SELECTED: null,

  SCHEDULE_RANGES_UPDATE: null,
  SCHEDULE_RANGES_UPDATE_REQUEST: null,
  SCHEDULE_RANGES_UPDATE_SUCCESS: null,
  SCHEDULE_RANGES_UPDATE_ERROR: null,
});
