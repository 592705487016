import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import PlaceActionTypes from 'constants/PlaceActionTypes';

const initialFilterState = () => ({ kitchenId: undefined, placeCategories: [], text: '' });

const initialEditingPlaceState = () => ({
  kitchen: { id: '' }, name: '', placeCategory: { id: '' }, description: '', customerVisible: false
});

const initialPlacesFilteredState = () => ({ places: [], boundary: undefined });

const initialState = {
  loading: false,
  filter: initialFilterState(),
  placesFiltered: initialPlacesFilteredState(),
  focusedPlace: undefined,
  editingPlace: initialEditingPlaceState(),
  hoveredDropoffLocationId: undefined,
  movingDropoffLocation: undefined,
};

const updateHandler = (fieldName) => {
  return (state, action) => ({ ...state, [fieldName]: action.payload });
};

const resetHandler = (fieldName) => {
  return (state, action) => ({ ...state, [fieldName]: undefined });
}

export default handleActions({
  [PlaceActionTypes.UPDATE_EDITING_PLACE]: (state, action) => ({
    ...state, editingPlace: { ...state.editingPlace, ...action.payload }
  }),
  [PlaceActionTypes.RESET_EDITING_PLACE]: (state, action) => ({
    ...state, editingPlace: initialEditingPlaceState()
  }),

  [PlaceActionTypes.UPDATE_FILTER]: updateHandler('filter'),
  [PlaceActionTypes.RESET_FILTER]: (state, action) => ({
    ...state, filter: initialFilterState(), placesFiltered: initialPlacesFilteredState()
  }),

  [PlaceActionTypes.PLACES_FILTERED_LOAD_REQUEST]: (state, action) => ({
    ...state, loading: true
  }),
  [PlaceActionTypes.PLACES_FILTERED_LOAD_SUCCESS]: (state, action) => {
    return { ...state, ...action.payload.data, loading: false };
  },
  [PlaceActionTypes.PLACES_FILTERED_LOAD_ERROR]: (state, action) => ({
    ...state, error: action.error, loading: false
  }),

  [PlaceActionTypes.EDITING_PLACE_LOAD_REQUEST]: (state, action) => ({
    ...state, loading: true
  }),
  [PlaceActionTypes.EDITING_PLACE_LOAD_SUCCESS]: (state, action) => {
    const place = get(action, 'payload.data.place');

    return { ...state, editingPlace: place, loading: false };
  },
  [PlaceActionTypes.EDITING_PLACE_LOAD_ERROR]: (state, action) => ({
    ...state, error: action.error, loading: false
  }),

  [PlaceActionTypes.UPDATE_FOCUSED_PLACE]: updateHandler('focusedPlace'),
  [PlaceActionTypes.RESET_FOCUSED_PLACE]: resetHandler('focusedPlace'),

  [PlaceActionTypes.UPDATE_HOVERED_DROPOFF_LOCATION]: updateHandler('hoveredDropoffLocationId'),
  [PlaceActionTypes.RESET_HOVERED_DROPOFF_LOCATION]: resetHandler('hoveredDropoffLocationId'),

  [PlaceActionTypes.UPDATE_MOVING_DROPOFF_LOCATION]: updateHandler('movingDropoffLocation'),
  [PlaceActionTypes.RESET_MOVING_DROPOFF_LOCATION]: resetHandler('movingDropoffLocation'),
}, initialState);
