export function getTokenFromStorage() {
  const localData = localStorage.getItem('cx-web-app')
  if (localData) {
    const parsedData = JSON.parse(localData)
    return parsedData.user && parsedData.user.token
  }
}

export function getAuthHeader() {
  const token = getTokenFromStorage()
  return `Bearer ${token}`
}

export function apiVersion(versionNo){
  return `application/vnd.api.clustertruck.com; version=${versionNo}, application/json`
}
