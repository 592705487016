import kitchensAPI from '../api/kitchens'
import ActionTypes from '../constants/ActionTypes';
import { LOGOUT_USER } from './user'
import { handleHttpResponse } from '../lib/handle-http-response'

export const LOAD_KITCHENS = 'LOAD_KITCHENS'
export const LOAD_KITCHEN = 'LOAD_KITCHEN'
export const UPDATE_KITCHEN = 'UPDATE_KITCHEN'
export const LOAD_MENU = 'LOAD_MENU'

export function loadKitchens() {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_KITCHENS, kitchensAPI.index())
  }
}

export function loadKitchen(id) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_KITCHEN, kitchensAPI.get(id))
  }
}

export function updateKitchen(id, data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, UPDATE_KITCHEN, kitchensAPI.update(id, data))
  }
}

export function loadMenu(id) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_MENU, kitchensAPI.getMenu(id))
  }
}

const initialState = {
  loading: false,
  error: false,
  list: [ ],
  byId: { },
  bySlug: { },
  menu: {
    loading: false,
    error: null,
    details: {}
  }
}

const clearKitchens = (state, action) => {
  return {
    initialState
  }
}

const loadKitchensHandler = (state, action) => {
  const kitchens = action.payload || state.list
  if (action.error) {
    return state
  }

  const list = [ ]
  const byId = {...state.byId} || { }
  const bySlug = {...state.bySlug}
  for (let i=0; i<kitchens.length; i++) {
    let k = kitchens[i]
    list[i] = k.id
    byId[kitchens[i].id] = k
    bySlug[kitchens[i].slug] = k
  }

  return {
    ...state,
    list,
    byId,
    bySlug,
    loading: action.loading,
    error: action.error
  }
}

const updateKitchenHandler = (state, action) => {
  if (!action.payload) {
    return {
      ...state,
      loading: action.loading
    }
  }

  return {
    ...state,
    byId: {
      ...state.byId,
      [action.payload.id] : action.payload,
    },
    bySlug: {
      ...state.bySlug,
      [action.payload.slug]: action.payload,
    },
    loading: action.loading,
    error: action.error
  }
}

const loadKitchenHandler = (state, action) => {
  if (action.payload && action.payload.id) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id] : action.payload,
      },
      bySlug: {
        ...state.bySlug,
        [action.payload.slug]: action.payload,
      },
      loading: action.loading,
      error: action.error
    }
  }
  return state
}

const ACTION_HANDLERS = {
  [LOGOUT_USER]: clearKitchens,
  [UPDATE_KITCHEN]: updateKitchenHandler,
  [ActionTypes.UPDATE_KITCHEN_HOURS_SUCCESS]: (state, action) => (
    updateKitchenHandler(state, {
      payload: action.payload.body
    })
  ),
  [LOAD_KITCHENS]: loadKitchensHandler,
  [LOAD_KITCHEN]:  loadKitchenHandler,
  [LOAD_MENU]: (state, action) => ({
    ...state,
    menu: {
      loading: action.loading || false,
      error: action.error,
      details: action.payload
    }
  })
}

export default function kitchensReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
