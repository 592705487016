import UserAPI from '../api/user'
import { handleHttpResponse } from '../lib/handle-http-response'
import _ from 'lodash'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER = 'LOGIN_USER'
export const CLEAR_USER = 'CLEAR_USER'
export const LOAD_USER = 'LOAD_USER'
export const LOAD_USER_ORDER_HISTORY = 'LOAD_USER_ORDER_HISTORY'

export const GIVE_POINTS_ROLE = 'give_points';
export const ADMIN_ROLE = 'admin';
export const CUSTOMER_EXPERIENCE = 'customer_experience';
export const MENU_COMPONENTS_ROLE = 'menu_components';
export const SCHEDULE_RANGE_EDIT_ROLE = 'schedule_range_edit';
export const MANAGE_PLACES_ROLE = 'manage_places';
export const MANAGE_DELIVERY_AREAS_ROLE = 'manage_delivery_areas';

const initialState = {
  data: { roles: [ ] },
  driver_admin: false,
  token: null,
  forbidden: false,
  loading: false
}

export function login(token) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, LOGIN_USER, UserAPI.login(token, window.ENV.cookieDomain))
  }
}

export function logout() {
  document.cookie = 'clustertruck_auth_token=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain='+ window.ENV.cookieDomain +'; secure';
  return ({
    type: CLEAR_USER
  })
}

const userLoginHandler = (state, action) => {
  const payload = action.payload || { }
  const user  = payload.user || { roles: [ ] }
  return  {
    ...state,
    token: user.token,
    loading: action.loading,
    error: action.error,
    forbidden: false,
    data: user,
    driver_admin: user && user.roles && user.roles.indexOf('driver_admin') > -1
  }
}

const userLogoutHandler = (state, action) => {
  return initialState
}

const ACTION_HANDLERS = {
  [LOGIN_USER]: userLoginHandler,
  [CLEAR_USER]: userLogoutHandler,
}

export function isAdminUser(user) {
  return user && user.roles && user.roles.indexOf('admin') > -1
}

export function isDriverAdmin(user) {
  return user && user.roles && user.roles.indexOf('driver_admin') > -1
}

export function userHasRole(user, role) {
  return user && user.roles && user.roles.indexOf(role) > -1
}

export function userHasRoles(user) {
  const roles = Array.prototype.slice.call(arguments, 1);

  if (!user || !user.roles) {
    return false;
  }

  return(_.some(roles, (role) => {
    return userHasRole(user, role)
  }))
}

export function hasAdHocOrderingRole(user) {
  return user && user.roles && user.roles.indexOf('ad_hoc_ordering') > -1
}

export function hasMenuBuilderRole(user) {
  return user && user.roles && user.roles.indexOf('menu_builder') > -1
}

export function hasMenuComponentsRole(user) {
  return user && user.roles && user.roles.indexOf(MENU_COMPONENTS_ROLE) > -1
}

export function hasScheduleRangeEditRole(user) {
  return user && user.roles && user.roles.indexOf(SCHEDULE_RANGE_EDIT_ROLE) > -1
}

export function hasManagePlacesRole(user) {
  return user && user.roles && user.roles.indexOf(MANAGE_PLACES_ROLE) > -1
}

export function hasManageDeliveryAreasRole(user) {
  return user && user.roles && user.roles.indexOf(MANAGE_DELIVERY_AREAS_ROLE) > -1
}

export default function userReducer (state = initialState, action) {
  if (action.error && action.payload && action.payload.status === 403) {
    return {
      ...state,
      forbidden: true
    }
  }
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
