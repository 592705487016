import React from 'react'

const Front = () => {
  return (
    <div className="row mt-4">
      <div className="col">
        <h2>Ops Front plugin</h2>
        <p>Select converation to load customer details</p>
      </div>
    </div>
  )
}

export default Front
