import OrdersAPI from '../api/orders'
import { handleHttpResponse } from '../lib/handle-http-response'
import { nestSubOrders } from '../lib/sub-orders-nester'
import get from 'lodash/get'

export const LOAD_ORDERS = 'LOAD_ORDERS'
export const SEARCH_ORDERS = 'SEARCH_ORDERS'
export const CLEAR_ORDER_SEARCH = 'CLEAR_ORDER_SEARCH'
export const LOAD_ORDER = 'LOAD_ORDER'
export const LOAD_ORDER_DETAILS = 'LOAD_ORDER_DETAILS'
export const LOAD_PAST_ORDERS = 'LOAD_PAST_ORDERS'
export const LOAD_RECENT_ORDER_LOCATIONS = 'LOAD_RECENT_ORDER_LOCATIONS'
export const ISSUE_REFUND = 'ISSUE_REFUND'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const LOAD_REFUNDS = 'LOAD_REFUNDS'
export const LOAD_INTERACTIONS = 'LOAD_INTERACTIONS'
export const REFIRE_ORDER = 'REFIRE_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const QUEUE_ORDER = 'QUEUE_ORDER'
export const DELIVER_ORDER = 'DELIVER_ORDER'
export const EXCEPTION_ORDER = 'EXCEPTION_ORDER'


const initialState = {
  data: [ ],
  recentLocations: {
    loading: false,
    error: null,
    details: []
  },
  loading: false,
  details: {refund: null},
  error: false,
  search: {
    filter: {},
    results: [],
    meta: {},
    aggs: {},
    loading: false,
    error: false
  }
}

export function loadOrders(kitchen) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_ORDERS, OrdersAPI.index(kitchen))
  }
}

export function searchOrders(searchQuery, offset = 0, limit = 20) {
  return(dispatch) => {
    const dispatchWithContext = (action) => (dispatch({...action, filter: searchQuery}))
    return handleHttpResponse(dispatchWithContext, SEARCH_ORDERS, OrdersAPI.search(searchQuery, offset, limit))
  }
}

export function clearOrderSearch() {
  return({
    type: CLEAR_ORDER_SEARCH
  })
}

export function loadOrderDetails(orderNumber) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_ORDER_DETAILS, OrdersAPI.load_order(orderNumber))
  }
}

export function loadUserOrderHistory(id, offset = 0, limit = 30) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_PAST_ORDERS, OrdersAPI.getPastOrders(id, offset, limit))
  }
}

export function loadUserOrderRecentLocations(id) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_RECENT_ORDER_LOCATIONS, OrdersAPI.getRecentOrderLocations(id))
  }
}

export function cancelOrder(id) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, CANCEL_ORDER, OrdersAPI.cancelOrder(id))
  }
}

export function issueRefund(orderId, data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, ISSUE_REFUND, OrdersAPI.issueRefund(orderId, data))
  }
}

export function loadOrderRefunds(orderId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_REFUNDS, OrdersAPI.loadOrderRefunds(orderId))
  }
}

export function loadOrderInteractions(orderId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_INTERACTIONS, OrdersAPI.loadOrderInteractions(orderId))
  }
}

export function queueOrder(orderId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, QUEUE_ORDER, OrdersAPI.queueOrder(orderId))
  }
}

export function deliverOrder(orderId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, DELIVER_ORDER, OrdersAPI.deliverOrder(orderId))
  }
}

export function exceptionOrder(orderId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, EXCEPTION_ORDER, OrdersAPI.exceptionOrder(orderId))
  }
}

export function updateOrder(orderId, data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, UPDATE_ORDER, OrdersAPI.patchOrder(orderId, data))
  }
}


const loadOrderDetailsHandler = (state, action) => {
  const refunds = state.details.refunds
  const payload = { group_owner: null, ...action.payload }
  const details = { ...state.details, source_order: null, ...payload, refunds, interactions: null}
  return {
    ...state,
    details,
    loading: action.loading,
    error: action.error
  }
}

const loadOrderInteractionsHandler = (state, action) => {
  const interactions = action.error ? {
      message: action.payload.response.body ? action.payload.response.body.description : 'Unknown error',
      error: true,
      loading: action.loading
    } : {
      data: action.payload,
      error: false,
      loading: action.loading
    }

  return {
    ...state,
    details: {...state.details, interactions: interactions }
  }
}

const loadOrdersHandler = (state, action) => {
  const orders = action.payload
  return {
    ...state,
    data: orders,
    loading: action.loading,
    error: action.error
  }
}

const searchOrdersHandler = (state, action) => {
  return {
    ...state,
    search: {
      ...state.search,
      loading: action.loading,
      error: action.error,
      filter: action.filter || {},
      results: (action.payload && action.payload.orders) || [],
      meta: (action.payload && action.payload.meta) || {},
      aggs: (action.payload && action.payload.aggs) || {}
    }
  }
}

const cancelOrderHandler = (state, action) => {
  const order = action.payload
  return {
    ...state,
    data: order,
    loading: action.loading,
    error: action.error
  }
}

const refundIssuedHandler = (state, action) => {
  let refunds = {}

  if (action.error) {
    refunds = {
      message: get(action, 'payload.response.body.description', 'Unknown Error'),
      error: true
    }
  }
  else {
    refunds = {
      data: action.payload,
      error: false
    }
  }

  return {
    ...state,
    details: {...state.details, refunds: refunds}
  }
}

const loadPastOrdersHandler = (state, action) => {
  const pastOrdersResponse = action.payload || []
  const newResponse = nestSubOrders(pastOrdersResponse.orders || [])
  const metaData = pastOrdersResponse.meta || []

  return {
    ...state,
    data: newResponse,
    meta: metaData,
    userId: pastOrdersResponse.user_id,
    loading: action.loading,
    error: action.error
  }
}

const loadRecentOrderLocationsHandler = (state, action) => {
  return {
    ...state,
    recentLocations: {
      ...state.recentLocations,
      details: action.payload,
      loading: action.loading,
      error: action.error
    }
  }
}

const handleOrderEvent = (state, action) => {
  let order = {}
  let error = false

  if (action.error) {
    error = action.payload.response.body.details
  } else {
    order = action.payload
  }

  return {
    ...state,
    details: { ...state.details, ...order},
    error: error
  }
}

const ACTION_HANDLERS = {
  [REFIRE_ORDER]: handleOrderEvent,
  [UPDATE_ORDER]: handleOrderEvent,
  [QUEUE_ORDER]: handleOrderEvent,
  [DELIVER_ORDER]: handleOrderEvent,
  [EXCEPTION_ORDER]: handleOrderEvent,
  [CANCEL_ORDER]: cancelOrderHandler,
  [LOAD_ORDERS]: loadOrdersHandler,
  [SEARCH_ORDERS]: searchOrdersHandler,
  [CLEAR_ORDER_SEARCH]: (state, action) => ({
    ...state,
    search: {
      filter: {},
      results: [],
      meta: {},
      aggs: {},
      loading: false,
      error: false
    }
  }),
  [LOAD_ORDER_DETAILS]: loadOrderDetailsHandler,
  [LOAD_PAST_ORDERS]: loadPastOrdersHandler,
  [LOAD_RECENT_ORDER_LOCATIONS]: loadRecentOrderLocationsHandler,
  [ISSUE_REFUND]: refundIssuedHandler,
  [LOAD_REFUNDS]: refundIssuedHandler,
  [LOAD_INTERACTIONS]: loadOrderInteractionsHandler
}

export default function ordersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
