import { combineReducers } from 'redux'
import kitchensReducer from '../modules/kitchens'
import clusterpointsReducer from '../modules/clusterpoints'
import userReducer from '../modules/user'
import ordersReducer from '../modules/orders'
import issuesReducer from '../modules/issues'
import customerReducer from '../modules/customer'
import CLEAR_USER from '../modules/customer'
import driversReducer from '../modules/drivers'
import driverReducer from '../modules/driver'
import promoCodeReducer from '../modules/promo_code'
import driverBonusReducer from '../modules/driver_bonus'
import adHocOrderReducer from '../modules/ad_hoc_order'
import alertsReducer from '../modules/alerts'
import mapReducer from '../reducers/mapReducer'
import issueSearchReducer from '../reducers/issueSearchReducer'
import itemsReducer from '../reducers/itemsReducer'
import taxRatesReducer from '../reducers/taxRatesReducer'
import trucksReducer from '../reducers/trucksReducer'
import assetsReducer from '../reducers/assetsReducer'
import notificationToastsReducer from '../reducers/notificationToastsReducer'
import kitchenHoursReducer from '../reducers/kitchenHoursReducer'
import teamsReducer from '../reducers/teamsReducer'
import menuComponentsReducer from '../reducers/menuComponentsReducer'
import scheduleRangesReducer from '../reducers/scheduleRangesReducer'
import placesReducer from 'reducers/placesReducer';

export const makeRootReducer = () => {
  const appReducer = combineReducers({
    clusterpoints: clusterpointsReducer,
    kitchens: kitchensReducer,
    kitchenHours: kitchenHoursReducer,
    user: userReducer,
    orders: ordersReducer,
    issues: issuesReducer,
    customer: customerReducer,
    drivers: driversReducer,
    driver: driverReducer,
    promo_codes: promoCodeReducer,
    driver_bonus: driverBonusReducer,
    ad_hoc_order: adHocOrderReducer,
    alerts: alertsReducer,
    mapReducer: mapReducer,
    issueSearchReducer: issueSearchReducer,
    items: itemsReducer,
    taxRates: taxRatesReducer,
    trucks: trucksReducer,
    assets: assetsReducer,
    notificationToasts: notificationToastsReducer,
    teamsReducer: teamsReducer,
    menuComponents: menuComponentsReducer,
    scheduleRanges: scheduleRangesReducer,
    places: placesReducer,
  })

  const rootReducer = (state, action) => {
    if (action.type === CLEAR_USER) {
      state = {}
    }
    return appReducer(state, action)
  }

  return rootReducer
}

export default makeRootReducer
