import { isObject, isArray, isUndefined, isNull, omitBy, isEmpty, isString } from 'lodash'

export const toQueryString = (obj) => {
  const omitFunc = v => (isNull(v) || isUndefined(v))
  return Object.keys(omitBy(obj, omitFunc)).map(k => {
    let val = obj[k]
    if (isArray(val)) {
      return val.map(v => (`${k}[]=${encodeURIComponent(v)}`)).join('&')
    }
    else if (isObject(val)) {
      return Object.keys(val).map(val_key => {
        return `${k}[${val_key}]=${encodeURIComponent(val[val_key])}`
      }).join('&')
    }
    return `${k}=${encodeURIComponent(val)}`
  }).join('&')
}

export const shortId = (str) => {
  if (isEmpty(str) || !isString(str)) {
    return str;
  }

  return str.split('-')[0];
}
