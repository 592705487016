import { createActions } from 'redux-actions';
import kitchensAPI from '../api/kitchens'
import ActionTypes from '../constants/ActionTypes';

export default createActions({
  [ActionTypes.EDIT_KITCHEN_HOURS]: id => id,
  [ActionTypes.CLOSE_EDIT_KITCHEN_HOURS]: id => id,
  [ActionTypes.UPDATE_KITCHEN_HOURS]: (id, hours, overriddenHours) => ({
    promise: kitchensAPI.update(id, { hours, overridden_hours: overriddenHours })
  })
})
