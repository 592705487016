import React, { useState, useEffect } from 'react'
import Modal from '../../../components/Modal/Modal'
import LocationInputBox from '../../../components/LocationInputBox/LocationInputBox'
import Loading from '../../../components/Loading'
import HttpClient from '../../../api/HttpClient'
import { errorToString } from '../../../reducers/util'
import { get } from 'lodash'

import '../style/style.sass'

const loadTeam = (teamId) => {
  return HttpClient.get(`/api/admin/teams/${teamId}`)
}

const updateTeam = (team) => {
  return HttpClient.patch(`/api/admin/teams/${team.id}`, team)
}

const createTeam = (params) => {
  return HttpClient.post('/api/admin/teams', params)
}

const TeamInformationModal = ({ teamId, onClose, teamUpdated }) => {


  const [address, setAddress] = useState("")
  const [name, setName] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [tipPercentage, setTipPercentage] = useState(0)
  const [taxExempt, setTaxExempt] = useState(false)

  useEffect(() => {
    if (teamId) {
      load(teamId)
    }
  }, [teamId])

  const load = (teamId) => {
    setLoading(true)
    loadTeam(teamId)
      .then((team) => {
        setLoading(false)
        setError("")
        setName(team.name)
        setTipPercentage(team.tip_percentage * 100)
        setTaxExempt(team.tax_exempt)
        setAddress(get(team.delivery_address, 'address', ''))
      })
      .catch((error) => {
        setLoading(false)
        setError(errorToString(error))
      })
  }

  const errorDisplay = (
    <div className="alert alert-danger" role="alert">
      {error}
    </div>
  )

  const save = () => {
    if (name === "") {
      setError("Team Name Required")
      return
    }
    if (tipPercentage > 100 || tipPercentage < 0) {
      setError("Tip Percentage must be between 0 and 100")
      return
    }
    setError("")

    const params = {
      name: name,
      tip_percentage: (tipPercentage / 100),
      tax_exempt: taxExempt,
      delivery_address:  {
        address: address
      }
    }

    if (address === '') {
      params.delivery_address = null
    }
    if (teamId) params.id = teamId

    if (teamId) {
      setLoading(true)
      updateTeam(params)
        .then((team) =>{
          setLoading(false)
          teamUpdated(team)
          onClose()
        })
        .catch((error) => {
          setLoading(false)
          setError(errorToString(error))
        })
    } else {
      setLoading(true)
      createTeam(params)
        .then((team) => {
          setLoading(false)
          onClose()
        })
        .catch((error) => {
          setLoading(false)
          setError(errorToString(error))
        })
    }
  }

  const footer = () => {
    if (loading) {
      return (
        <Loading />
      )
    }
    const actionText = teamId ? "Update" : "Create"
    return (
      <div className="modal-footer">
        <button className="btn btn-default" onClick={onClose}>Cancel</button>
        <button className="btn btn-success" onClick={save}>{actionText}</button>
      </div>
    )
  }

  return (<Modal
    isOpen={true}
    title={teamId ? `Edit ${name}` : "Create New Team"}
    onClose={onClose}
    footer={footer()}
    modalId='team-information'>

    {error !== "" ? errorDisplay : null}

    <div className='form-group'>
      <label>Team Name</label>
      <input type="text" disabled={loading} className="form-control" id="teamName" placeholder="Team Name" value={name} onChange={e => setName(e.target.value)} />
    </div>

    <div className='form-group'>
      <label>Tip Percentage (i.e. 15 = 15%)</label>
      <input type="number" disabled={loading} className="form-control" id="tipPercentage" placeholder="Tip Percentage" value={tipPercentage} onChange={e => setTipPercentage(e.target.value)} />
    </div>

    <div className='form-group'>
      <label>Tax Exempt?</label>
      <select disabled={loading} className="form-control" value={taxExempt} onChange={e => setTaxExempt(e.target.value)}>
        <option value="false">False</option>
        <option value="true">True</option>
      </select>
    </div>

    <div className='form-group'>
      <label>Delivery Address</label>
      <LocationInputBox
        disabled={loading}
        onAddressChange={address => setAddress(address.address)}
        address={address}
        onAddressCleared={() => setAddress("")} />
    </div>
  </Modal>)
}

export default (TeamInformationModal)