import { get, partialRight } from 'lodash';

import { JSON_API_CONTENT_TYPE } from 'api/JsonApiHttpClient';

function getRelationships(relationshipName, compoundDocuments) {
  const relationshipResourceIdentifiers = get(this, `relationships.${relationshipName}.data`);
  if (Array.isArray(relationshipResourceIdentifiers)) {
    return relationshipResourceIdentifiers.map(value => {
      if (compoundDocuments) {
        const compoundDoc = compoundDocuments.find(doc => doc.id === value.id)

        return { ...compoundDoc };
      } else {
        return { ...value };
      }
    });
  } else if (typeof data === 'object') {
    if (compoundDocuments) {
      const compoundDoc = compoundDocuments.find(doc => doc.id === relationshipResourceIdentifiers.id)

      return { ...compoundDoc };
    } else {
      return { ...relationshipResourceIdentifiers };
    }
  }
}

function getAttribute(attributeKey) {
  return get(this, `attributes.${attributeKey}`);
}

const addUtilityFunctions = (data, compoundDocuments) => {
  if (Array.isArray(data)) {
    data.forEach(datum => {
      datum.getRelationships = partialRight(getRelationships, compoundDocuments);
      datum.getAttribute = getAttribute;
    });
  } else if (typeof data === 'object') {
    data.getRelationships = partialRight(getRelationships, compoundDocuments);
    data.getAttribute = getAttribute;
  }
}

export default store => next => action => {
  if (get(action, 'payload.headers.content-type') === JSON_API_CONTENT_TYPE) {
    const documentData = get(action, 'payload.body.data');
    const compoundDocuments = get(action, 'payload.body.included');
    addUtilityFunctions(documentData, compoundDocuments);
    addUtilityFunctions(compoundDocuments, compoundDocuments);
  }

  return next(action);
}
