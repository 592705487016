import client from './client'
import { omitBy, isEmpty } from 'lodash'

export default {
  search: (searchQuery, activeFilter, offset = 0, limit = 20) => {
    // Clear off any filters that are empty
    const filter_param = omitBy(searchQuery, isEmpty)

    // Force code to a wildcard search
    if (!isEmpty(filter_param.code)) {
      filter_param.code = `*${filter_param.code}*`
    }
    const activeFilterValue = activeFilter || ''

    const url = `/api/promo_codes?offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter_param)}&active=${activeFilterValue}`
    return client
      .get(url)
  },

  createPromoCode: (data) => {
    return client
      .post('/api/promo_codes')
      .send(data)
  },

  patchCode: (id, data) => {
    return client
      .put(`/api/promo_codes/${id}`)
      .send(data)
  }
}
