import client from './client'

export default {
  search: (term, kitchen, state, expired_document, offset = 0) => {
    const url = `/api/drivers/search?term=${term}&kitchen=${kitchen}&state=${state}&expired_document=${expired_document}&offset=${offset}`
    return client
      .get(url)
  },
  get: (driver_id) => {
    return client
      .get(`/api/admin/drivers/${driver_id}`)
  },
  enableDriver: (driver_id) => {
    return client
      .patch(`/api/drivers/${driver_id}/enable`)
  },
  disableDriver: (driver_id) => {
    return client
      .patch(`/api/drivers/${driver_id}/disable`)
  },
  enableKitchen: (driver_id, kitchen_id) => {
    return client
      .patch(`/api/drivers/${driver_id}/kitchens/${kitchen_id}/enable`)
  },
  disableKitchen: (driver_id, kitchen_id) => {
    return client
      .patch(`/api/drivers/${driver_id}/kitchens/${kitchen_id}/disable`)
  },
  enableVehicle: (vehicle_id) => {
    return client
      .patch(`/api/admin/vehicles/${vehicle_id}/enable`)
  },
  disableVehicle: (vehicle_id) => {
    return client
      .patch(`/api/admin/vehicles/${vehicle_id}/disable`)
  },
  orders: (driver_id, offset = 0, limit = 20) => {
    return client
      .get(`/api/drivers/${driver_id}/delivery_jobs?limit=${limit}&offset=${offset}`)
  },
  getEarningsReport: (driver_id, start_date, end_date, offset, limit) => {
    let url = `/api/drivers/${driver_id}/earnings_report?offset=${offset}&limit=${limit}`;
    if (start_date) {
      url += `&start_date=${start_date}`;
    }
    if (end_date) {
      url += `&end_date=${end_date}`;
    }

    return client.get(url)
  },
  events: (driver_id, offset = 0, limit = 20) => {
    return client
      .get(`/api/admin/drivers/${driver_id}/events?limit=${limit}&offset=${offset}`)
  },
  standby_slots: (driver_id, offset = 0, limit = 20) => {
    return client
      .get(`/api/admin/drivers/${driver_id}/standby_slots?limit=${limit}&offset=${offset}`)
  },
  backgroundCheck: (driver_id) => {
    return client
      .post(`/api/admin/drivers/${driver_id}/background_check`)
  },
  assignTraining: (driver_id) => {
    return client
      .post(`/api/admin/drivers/${driver_id}/training`)
  },
  addDriverToKitchen: (driver_id, kitchen_id) => {
    const url = `/api/drivers/${driver_id}/kitchen_approvals`
    return client
      .post(url)
      .send({kitchen_id: kitchen_id})
  },
  getVehicles: (driver_id) => {
    return client
      .get(`/api/drivers/${driver_id}/vehicles`)
  },
  revertRejection: (driver_id) => {
    return client.delete(`/api/drivers/${driver_id}/rejections`)
  },
  fetchBonuses: (id) => {
    return client.get(`/api/admin/drivers/${id}/bonuses`)
  },
  createBonus: (id, data) => {
    return client.post(`/api/admin/drivers/${id}/bonuses`).send(data)
  },
  pingDriver: (id) => {
    return client
      .post(`/api/admin/drivers/${id}/ping`)
      .send()
  },
  updatePhoneNumber: (id, phoneNumber) => {
    return client
      .put(`/api/admin/drivers/${id}`)
      .send({
        driver: {
          phone_number: phoneNumber
        }
      });
  }
}
