import { handleActions } from 'redux-actions';
import { get, map, partialRight } from 'lodash';

import ActionTypes from 'constants/ActionTypes';
import TruckActions from 'actions/TruckActions';
import { updateSingleDataResourceObject } from 'util/updateDocumentContent';

const initialState = {
  loading: {
    menuComponents: {
      list: false,
      loadSingle: false,
      move: false
    },
    archive: false,
    currentComponent: false
  },
  topLevelDocument: [],
  currentDocument: {},
  error: null,
  errors: {}
};

const handleRequest = (state, action, loadingState = {}) => ({
  ...state,
  loading: {
    ...state.loading,
    menuComponents: {
      ...state.loading.menuComponents,
      ...loadingState
    }
  },
  ...action.payload
});

const handleSuccess = (state, action, loadingState) => {
  const documentMembers = get(action, 'payload.body', []);

  return {
    ...state,
    topLevelDocument: documentMembers,
    loading: {
      ...state.loading,
      menuComponents: { ...state.loading.menuComponents, ...loadingState }
    }
  };
}

const handleError = (state, action, loadingState = {}) => {
  const errors = get(action, 'payload.response.body.details');

  return {
    ...state,
    loading: { ...state.loading, menuComponents: { ...state.loading.menuComponents, ...loadingState } },
    error: action.error,
    errors
  };
};

const archiveComponentHandler = (state, action) => {
  const meta = state.topLevelDocument.meta
  const data = state.topLevelDocument.data
  const idx = map(data, 'id').indexOf(action.meta.id)

  return {
    ...state,
    loading: {
      ...state.loading,
      archive: false
    },
    topLevelDocument: {
      ...state.topLevelDocument,
      data: [
        ...data.slice(0, idx),
        ...data.slice(idx+1)
      ],
      meta: {
        ...meta,
        total: meta.total - 1
      }
    }
  }
}

// Handle a component load or save success
const handleComponentSuccess = (state, action) => {
  const member = get(action, 'payload.body', []);

  return {
    ...state,
    topLevelDocument: updateSingleDataResourceObject(state, action),
    currentDocument: member,
    loading: {
      ...state.loading,
      menuComponents: {
        ...state.loading.menuComponents,
        loadSingle: false,
        move: false
      }
    }
  };
}

export default handleActions({
  [ActionTypes.MENU_COMPONENTS_LOAD_REQUEST]: partialRight(handleRequest, { list: true }),
  [ActionTypes.MENU_COMPONENTS_LOAD_SUCCESS]: partialRight(handleSuccess, { list: false }),
  [ActionTypes.MENU_COMPONENTS_LOAD_ERROR]: partialRight(handleError, { list: false }),

  [ActionTypes.MENU_COMPONENT_CREATE_REQUEST]: partialRight(handleRequest, { loadSingle: true }),
  [ActionTypes.MENU_COMPONENT_CREATE_SUCCESS]: (state, action) => {
    const newMenuComponent = get(action, 'payload.body.data', []);
    const componentType = get(action, 'meta.type');

    if (componentType === 'TruckMenuComponent' && newMenuComponent) {
      action.sideEffect((store) => {
        store.dispatch(TruckActions.truckLoad(newMenuComponent.getAttribute('component_data.id')));
      });
    }

    return {
      ...state,
      topLevelDocument: updateSingleDataResourceObject(state, action),
      currentDocument:
        (componentType === 'PlaceholderMenuComponent' ? initialState.currentComponent : newMenuComponent),
      loading: {
        ...state.loading,
        menuComponents: {
          ...state.loading.menuComponents,
          loadSingle: false
        }
      }
    };
  },
  [ActionTypes.MENU_COMPONENT_CREATE_ERROR]: partialRight(handleError, { loadSingle: false }),

  [ActionTypes.MENU_COMPONENT_LOAD_REQUEST]: partialRight(handleRequest, { loadSingle: true }),
  [ActionTypes.MENU_COMPONENT_LOAD_SUCCESS]: handleComponentSuccess,
  [ActionTypes.MENU_COMPONENT_LOAD_ERROR]: partialRight(handleError, { loadSingle: false }),

  [ActionTypes.MENU_COMPONENT_UPDATE_SUCCESS]: handleComponentSuccess,
  [ActionTypes.MENU_COMPONENT_UPDATE_ERROR]: partialRight(handleError, { loadSingle: false }),

  [ActionTypes.TRUCK_MENU_COMPONENT_LOAD_REQUEST]: (state, action) => ({
    ...state,
    loading: { ...state.loading, currentComponent: true }
  }),
  [ActionTypes.TRUCK_MENU_COMPONENT_LOAD_SUCCESS]: (state, action) => {
    const member = get(action, 'payload.body', []);

    action.sideEffect((store) => {
      const truckId = member.data.getAttribute('component_data.id');
      store.dispatch(TruckActions.truckLoad(truckId));
    });

    return {
      ...state,
      currentDocument: member,
      loading: {
        ...state.loading,
        currentComponent: false
      }
    };
  },
  [ActionTypes.TRUCK_MENU_COMPONENT_LOAD_ERROR]: (state, action) => {
    const errors = get(action, 'payload.response.body.details');

    return {
      ...state,
      loading: { ...state.loading, currentComponent: false },
      error: action.error,
      errors
    }
  },

  [ActionTypes.MENU_COMPONENT_MOVE_UP_REQUEST]: partialRight(handleRequest, { move: true }),
  [ActionTypes.MENU_COMPONENT_MOVE_UP_SUCCESS]: partialRight(handleSuccess, { move: false }),
  [ActionTypes.MENU_COMPONENT_MOVE_UP_ERROR]: partialRight(handleError, { move: false }),

  [ActionTypes.MENU_COMPONENT_MOVE_DOWN_REQUEST]: partialRight(handleRequest, { move: true }),
  [ActionTypes.MENU_COMPONENT_MOVE_DOWN_SUCCESS]: partialRight(handleSuccess, { move: false }),
  [ActionTypes.MENU_COMPONENT_MOVE_DOWN_ERROR]: partialRight(handleError, { move: false }),

  [ActionTypes.MENU_COMPONENT_ITEM_MOVE_REQUEST]: partialRight(handleRequest, { move: true, loadSingle: true }),
  [ActionTypes.MENU_COMPONENT_ITEM_MOVE_SUCCESS]: handleComponentSuccess,
  [ActionTypes.MENU_COMPONENT_ITEM_MOVE_ERROR]: partialRight(handleError, { move: false, loadSingle: false }),

  [ActionTypes.MENU_COMPONENT_ARCHIVE_REQUEST]: (state, action) => ({ ...state, error: false, errors: { }, loading: { ...state.loading, archive: true } }),
  [ActionTypes.MENU_COMPONENT_ARCHIVE_SUCCESS]: archiveComponentHandler,
  [ActionTypes.MENU_COMPONENT_ARCHIVE_ERROR]:   (state, action) => ({
    ...state,
    error: true,
    errors: get(action.payload.response.body, 'details')
  }),

  [ActionTypes.MENU_COMPONENT_UNLOAD]: (state, action) => ({
    ...state,
    currentDocument: {}
  })
}, initialState);
