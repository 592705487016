import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { get } from 'lodash';

import { getTokenFromStorage } from 'util/api';

const cache = new InMemoryCache({
  dataIdFromObject: object => get(object, 'id', null)
});

const httpLink = createUploadLink({
  uri: '/api/graphql',
  headers: {
    authorization: `Bearer ${getTokenFromStorage()}`
  }
});

export const client = new ApolloClient({
  cache,
  link: httpLink,
  defaultOptions: {
    query: { fetchPolicy: 'network-only', errorPolicy: 'network-only' }
  }
});
