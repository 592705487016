import React, { useEffect, useState } from 'react'
import { Link } from 'react-router'
import { isEmpty, isUndefined, get } from 'lodash'
import Modal from '../../../components/Modal/Modal'
import Loading from '../../../components/Loading'
import ReactPaginate from 'react-paginate'
import HttpClient from '../../../api/HttpClient'
import { errorToString } from '../../../reducers/util'

import '../style/style.sass'

const loadTeamMembers = (teamId, page = 0) => {
  const DEFAULT_PAGE_SIZE = 10
  return HttpClient.get(`/api/admin/teams/${teamId}/team_members`, {}, {
    limit: DEFAULT_PAGE_SIZE,
    offset: page * DEFAULT_PAGE_SIZE
  })
}

const resetTeamMember = (teamId, memberId) => {
  return HttpClient.del(`/api/admin/teams/${teamId}/team_members/${memberId}`)
}

const TeamMembersModal = ({ teamId, name, onClose, hasTeamManagerRole }) => {

  const [meta, setMeta] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [members, setMembers] = useState([])

  useEffect(() => {
    setLoading(true)
    loadTeamMembers(teamId)
        .then((results) => {
          setLoading(false)
          setMeta(results.meta)
          setMembers(results.list)
        })
        .catch((error) => {
          setLoading(false)
          setError(errorToString(error))
        })
  }, [teamId])

  const loadPage = (selectedPage) => {
    setLoading(true)
    loadTeamMembers(teamId, selectedPage)
      .then((results) => {
        setLoading(false)
        setMeta(results.meta)
        setMembers(results.list)
      })
      .catch((error) => {
        setLoading(false)
        setError(errorToString(error))
      })
  }

  const confirmResetMember = (memberId, name) => {
    if (window.confirm(`Are you sure you want to reset ${name}?`)) {
      setLoading(true)
      resetTeamMember(teamId, memberId)
        .then(result => {
          setLoading(false)
          removeTeamMember(memberId)
        })
        .catch((error) => {
          setLoading(false)
          setError(errorToString(error))
        })
    }
  }

  const removeTeamMember = (id) => {
    const newMembers = members.filter(member => member.id !== id)
    setMembers(newMembers)
  }

  const renderFooter = () => {
    return (
      <div className="modal-footer">
        <button className="btn btn-default" onClick={() => onClose()}>Close</button>
      </div>
    )
  }

  const renderFullWidth = (element) => {
    return (<tr><td colSpan='2'>{element}</td></tr>)
  }

  const renderResults = () => {

    if (error) {
      return renderFullWidth(error)
    }

    if (loading) {
      return renderFullWidth(<Loading />)
    }

    if (isEmpty(members)) {
      return renderFullWidth('No matches were found')
    }

    return members.map(team_member => {
      const { id } = team_member
      const name = get(team_member, 'user.full_name', 'Unknown')
      const user_id = get(team_member, 'user.id')
      const resetButton = hasTeamManagerRole ? <button type='button' className='btn btn-danger btn-sm' onClick={() => confirmResetMember(id, name)}>Reset</button> : null

      return (
        <tr key={id}>
          <td><Link to={`/users/${user_id}`}>{name}</Link></td>
          <td>{resetButton}</td>
        </tr>
      )
    })
  }

  const errorDisplay = error ? (<p className='error'>{error}</p>) : null

  const pagination = isUndefined(meta) ? null : <ReactPaginate
    containerClassName="react-pagination"
    pageCount={Math.ceil(meta.total / meta.limit)}
    pageRangeDisplayed={5}
    marginPagesDisplayed={2}
    onPageChange={data => loadPage(data.selected)}
    forcePage={meta.offset / meta.limit}
  />

  return (<Modal
    isOpen={true}
    title={`Team Members: ${name}`}
    onClose={() => onClose()}
    footer={renderFooter()}
    modalId='team-members'>

    {errorDisplay}

    <div className="mt-2">
      {!isUndefined(meta) ? <p><strong>{meta.total}</strong> members found</p> : ''}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderResults()}
        </tbody>
      </table>
      {pagination}
    </div>
  </Modal>)
}

export default (TeamMembersModal)