import CustomerAPI from '../api/customer'
import CreditCardAPI from '../api/credit_card'
import { handleHttpResponse } from '../lib/handle-http-response'
import { isEmpty } from 'lodash/core';
import get from 'lodash/get';
import assign from 'lodash/assign';

export const LOAD_CUSTOMER = 'LOAD_CUSTOMER'
export const SEARCH_CUSTOMERS = 'SEARCH_CUSTOMERS'
export const LOAD_POINTS_TRANSACTIONS = 'LOAD_POINTS_TRANSACTIONS'
export const GIVE_POINTS = 'GIVE_POINTS';
export const GIVE_FUNDS = 'GIVE_FUNDS';
export const LOAD_FUNDS_TRANSACTIONS = 'LOAD_FUNDS_TRANSACTIONS'
export const LOAD_WALLET_TRANSACTIONS = 'LOAD_WALLET_TRANSACTIONS'
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const LOGOUT_CUSTOMER = 'LOGOUT_CUSTOMER';
export const ARCHIVE_CUSTOMER = 'ARCHIVE_CUSTOMER';
export const TOGGLE_PREF = 'TOGGLE_PREF'
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER'
export const DESTROY_CREDIT_CARD = 'DESTROY_CREDIT_CARD';

export function clearCustomer() {
  return {
    type: CLEAR_CUSTOMER
  }
}


export function loadCustomer(id) {
 return (dispatch) => {
  return handleHttpResponse(dispatch, LOAD_CUSTOMER, CustomerAPI.getCustomer(id))
 }
}

export function searchCustomers(search, offset = 0, limit = 20) {
  if (isEmpty(search)) {
    return {
      loading: false,
      type: SEARCH_CUSTOMERS,
      payload: []
    }
  }
  return (dispatch) => {
    return handleHttpResponse(dispatch, SEARCH_CUSTOMERS, CustomerAPI.searchCustomers(search, offset, limit))
  }
}

export function loadUserPointsTransactions(id, offset = 0, limit = 30) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_POINTS_TRANSACTIONS, CustomerAPI.pointsTransactions(id, offset, limit));
  }
}

export function givePointsToCustomer(id, data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, GIVE_POINTS, CustomerAPI.givePoints(id, data));
  }
}

export function loadUserWalletTransactions(id, offset = 0, limit = 25) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_WALLET_TRANSACTIONS, CustomerAPI.walletTransactions(id, offset, limit));
  }
}

export function giveFundsToCustomer(id, data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, GIVE_FUNDS, CustomerAPI.giveFunds(id, data));
  }
}

export function updateCustomer(userId, data) {
  return (dispatch) => {
    if (data.preferences) {
      dispatch({
        type: TOGGLE_PREF,
        payload: data.preferences
      })
    }
    return handleHttpResponse(dispatch, UPDATE_CUSTOMER, CustomerAPI.update(userId, data));
  }
}

export function destroyCreditCard(id, userId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, DESTROY_CREDIT_CARD, CreditCardAPI.destroy(userId, id))
  }
}


export function logoutCustomer(userId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOGOUT_CUSTOMER, CustomerAPI.logout(userId))
  }
}

export function archiveCustomer(userId) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, ARCHIVE_CUSTOMER, CustomerAPI.archive(userId))
  }
}

export function clearSearchData() {
  return ({ type: CLEAR_SEARCH_DATA });
}

const initialState = {
  loading: false,
  error: false,
  data: [ ],
  details: {}
}

const loadCustomerHandler = (state, action) => {
  const customer = action.payload || {}

  return {
    ...state,
    details: customer,
    loading: action.loading,
    error: action.error
  }
}

const updateCustomerHandler = (state, action) => {
  const customer = get(action, 'payload', state.details);

  return {
    ...state,
    details: customer,
    loading: action.loading,
    error: action.error
  };
}


const searchCustomersHandler = (state, action) => {
  const searchResults = action.payload || {}

  return {
    ...state,
    loading: action.loading,
    error: action.error,
    data: searchResults
  }
}

const loadPointsTransactionsHandler = (state, action) => {
  const pointsTransactions = action.payload || {};
  const customerDetails = {...state.details};

  if (!action.error && !isEmpty(customerDetails) && !isEmpty(pointsTransactions)) {
    customerDetails.point_balance = pointsTransactions.user.point_balance;
    customerDetails.display_point_balance = pointsTransactions.user.display_point_balance;
  }

  const user = pointsTransactions.user || [];

  return {
    ...state,
    details: customerDetails,
    pointsTransactions: pointsTransactions.points_transactions,
    metaPointsTransactions: pointsTransactions.meta_points_transactions,
    userId: user.user_id,
    loading: action.loading,
    error: action.error
  };
}

const loadWalletTransactionsHandler = (state, action) => {
  const walletTransactions = action.payload || {};
  const customerDetails = {...state.details};

  if (!action.error && !isEmpty(customerDetails) && !isEmpty(walletTransactions)) {
    customerDetails.wallet.balance_cents = walletTransactions.user.wallet.balance_cents;
    customerDetails.wallet.display_balance = walletTransactions.user.wallet.display_balance;
  }

  const user = walletTransactions.user || [];

  return {
    ...state,
    details: customerDetails,
    walletTransactions: walletTransactions.wallet_transactions,
    metaWalletTransactions: walletTransactions.meta_wallet_transactions,
    userId: user.user_id,
    loading: action.loading,
    error: action.error
  };
};

const ACTION_HANDLERS = {
  [LOAD_CUSTOMER]: loadCustomerHandler,
  [SEARCH_CUSTOMERS]: searchCustomersHandler,
  [LOAD_POINTS_TRANSACTIONS]: loadPointsTransactionsHandler,
  [GIVE_POINTS]: loadPointsTransactionsHandler,
  [LOAD_WALLET_TRANSACTIONS]: loadWalletTransactionsHandler,
  [GIVE_FUNDS]: loadWalletTransactionsHandler,
  [UPDATE_CUSTOMER]: updateCustomerHandler,
  [ARCHIVE_CUSTOMER]: updateCustomerHandler,
  [TOGGLE_PREF]: (state, action) => {
    const preferences = assign(state.details.preferences, action.payload)
    return {
      ...state,
      details: {
        ...state.details,
        preferences: preferences
      }
    }
  },
  [CLEAR_CUSTOMER]: (state, action) => {
    return {
      ...state,
      details: { }
    }
  },
  [CLEAR_SEARCH_DATA]: (state, action) => ({ ...state, data: [] }),
  [DESTROY_CREDIT_CARD]: (state, action) => {
    // Handle the destory card API response
    //
    // grab the new list of cards from the API response or default to what is
    // currently in state
    const credit_cards = get(action, 'payload.credit_cards', state.details.credit_cards)

    return {
      ...state,
      details: {
        ...state.details,
        credit_cards
      }
    }
  }
}

export default function customerReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
