import client from './client'
import { isEmpty } from 'lodash'
import { toQueryString } from '../util/stringUtils'

export default {
  index: (kitchen) => {
    const kitchenUrl = `/api/admin/kitchens/${kitchen}/orders`
    return client
      .get(kitchenUrl)
  },

  search: (searchQuery, offset = 0, limit = 20) => {
    // Clear off any filters that are empty
    const filterString = toQueryString(searchQuery)
    const filterParam = isEmpty(filterString) ? '' : `&${filterString}`

    const url = `/api/admin/orders/search?offset=${offset}&limit=${limit}${filterParam}`
    return client
      .get(url)
  },

  load_order: (orderId) => {
    const orderUrl = `/api/admin/orders/${orderId}`
    return client
      .get(orderUrl)
  },

  getPastOrders: (userId, offset = 0, limit = 30) => {
    return client
      .get(`/api/admin/users/${userId}/orders?limit=${limit}&offset=${offset}`)
  },

  getRecentOrderLocations: (userId) => {
    return client
      .get(`/api/admin/users/${userId}/orders/recent_locations`)
  },

  cancelOrder: (orderId) => {
    return client
      .post(`/api/admin/orders/${orderId}/cancel`)
  },

  issueRefund: (orderId, data) => {
    return client
      .post(`/api/admin/orders/${orderId}/refunds`)
      .send(data)
  },

  loadOrderRefunds: (orderId) => {
    return client
      .get(`/api/admin/orders/${orderId}/refunds`)
  },

  loadOrderInteractions: (orderId) => {
    return client.get(`/api/admin/orders/${orderId}/interactions`)
  },

  queueOrder: (orderId) => {
    return client
      .post(`/api/admin/orders/${orderId}/queue`)
  },

  deliverOrder: (orderId) => {
    return client
      .post(`/api/admin/orders/${orderId}/delivered`)
  },

  exceptionOrder: (orderId) => {
    return client
      .post(`/api/admin/orders/${orderId}/exception`)
  },

  createOrder: (kitchenId, data) => {
    return client
      .post(`/api/admin/kitchens/${kitchenId}/orders`)
      .send(data)
  },

  patchOrder: (orderId, data) => {
    return client
      .put(`/api/admin/orders/${orderId}`)
      .send(data)
  }
}
