export default (store) => ({
  path : '/kitchens/:id(/orders/:order_id)/ad_hoc_order',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    import(
      /* webpackChunkName: 'ad_hoc_order' */
      './components/AdHocOrderView'
    ).then(module => {
      cb(null, module.default)
    })
  }
})
