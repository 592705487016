import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

import NotificationToastActions from '../../actions/NotificationToastActions';

import './NotificationToast.sass';

class NotificationToast extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showAlertToast: undefined
    };
  }

  componentDidUpdate(prevProps) {
    const { text } = this.props;

    if (prevProps.text !== text) {
      if (isEmpty(text)) {
        this.setState({ showAlertToast: false });
      } else {
        this.setState({ showAlertToast: true });

        setTimeout(() => {
          this.props.hideAlertToast();
        }, 3000);
      }
    }
  }

  render() {
    const { text, toastType } = this.props;
    const { showAlertToast } = this.state;

    const classes = [
      'ct-alert-box',
      'alert',
      `alert-${toastType}`,
      'mr-3'
    ];
    if (showAlertToast) {
      classes.push('show-alert-box');
    } else if (showAlertToast === false) {
      classes.push('hide-alert-box');
    }

    return (
      <div className={classes.join(' ')} style={{ zIndex: '9999' }}>
        {text}
      </div>
    );
  }
}

NotificationToast.propTypes = {
  text: PropTypes.string,
  toastType: PropTypes.string
};

const mapPropsToDispatch = {
  hideAlertToast: NotificationToastActions.notificationToastClearText
}

export default connect(() => ({}), mapPropsToDispatch)(NotificationToast);
