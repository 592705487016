import PromoCodeAPI from '../api/promo_code'
import { handleHttpResponse } from '../lib/handle-http-response'
import { isNull, isString, isObject, get, isArray } from 'lodash'

export const SEARCH_PROMO_CODES = 'SEARCH_PROMO_CODES'
export const CREATE_PROMO_CODE = 'CREATE_PROMO_CODE'
export const PROMO_CODE_SEARCH_CODE_CHANGED = 'PROMO_CODE_SEARCH_CODE_CHANGED'
export const PROMO_CODE_DIALOG = 'PROMO_CODE_DIALOG'
export const PROMO_CODE_MODIFIED = 'PROMO_CODE_MODIFIED'
export const PATCH_PROMO_CODE = 'PATCH_PROMO_CODE'
export const PROMO_CODE_VALIDATION_ERROR = 'PROMO_CODE_VALIDATION_ERROR'

const initialState = {
  data: {},
  loading: false,
  saving: false,
  searchResults: [ ],
  codeSearchText: '',
  promoCode: null,
  editing: false,
  errorMsg: null,
  successMsg: null
}

export function searchPromoCodes(searchQuery, activeFilter, offset = 0, limit = 20) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, SEARCH_PROMO_CODES, PromoCodeAPI.search(searchQuery, activeFilter, offset, limit))
  }
}

export function changePromoCodeSearchCode(text) {
  return ({
    type: PROMO_CODE_SEARCH_CODE_CHANGED,
    codeSearchText: text
  })
}

export function createPromoCode(data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, CREATE_PROMO_CODE, PromoCodeAPI.createPromoCode(data))
  }
}

export function patchPromoCode(id, data) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, PATCH_PROMO_CODE, PromoCodeAPI.patchCode(id, data))
  }
}

export function promoCodeValidationError(errorMsg) {
  return ({
    type: PROMO_CODE_VALIDATION_ERROR,
    errorMsg: errorMsg
  })
}

export function createOrderPromoCode(order_number) {
  const data = {
    name: order_number,
    code: order_number,
    type: 'free_top_item',
    global_single_use: true,
    accounting_category: 'customer-service',
  }
  return createPromoCode(data)
}

export function openPromoCodeDialog(promoCode) {
  return ({
    type: PROMO_CODE_DIALOG,
    promoCode: {
      type: '',
      code: '',
      new_customer: false,
      single_use: false,
      global_single_use: false,
      roles: [],
      accounting_category: 'marketing',
      metadata: {},
      valid_days: [ true, true, true, true, true, true, true ],
      location: null,
      ...promoCode
    }

  })
}

export function closePromoCodeDialog() {
  return ({
    type: PROMO_CODE_DIALOG,
    promoCode: null
  })
}

export function promoCodeModified(updatedPromoCode) {
  return ({
    type: PROMO_CODE_MODIFIED,
    updatedPromoCode: updatedPromoCode
  })
}

const getActionErrorMessage = (action) => {
  const body = get(action, 'payload.response.body')
  let errorDetails = ''
  if (isString(body.details)) {
    errorDetails = body.details
  }
  else if (isObject(body.details)) {
    errorDetails = Object.entries(body.details).map((e) => {
      const res = isArray(e[1]) ? e[1] : [e[1]]
      return `${e[0]}: ${res.join(', ')}`
    }).join(', ')
  }
  return `${body.description}: ${errorDetails}`
}

const handleCreatePromoCode = (state, action) => {
  const newState = {
    ...state,
    saving: action.loading,
    errorMsg: null,
    successMsg: null
  }

  if (!action.loading && !action.error) {
    return {
      ...newState,
      promoCode: action.payload,
      successMsg: `Code ${action.payload.code} created`
    }
  }

  if (!action.loading && action.error) {
    return {
      ...newState,
      errorMsg: getActionErrorMessage(action)
    }
  }

  return newState
}

const handlePatchPromoCode = (state, action) => {
  const newState = {
    ...state,
    saving: action.loading,
    errorMsg: null,
    successMsg: null
  }

  if (!action.loading && !action.error) {
    // Replace result in search in-place with the updates
    let newResults = state.searchResults.list
    const matchIndex = state.searchResults.list.findIndex((elem) => elem.id === state.promoCode.id )
    if (matchIndex !== -1) {
      newResults = state.searchResults.list.slice(0, matchIndex).concat([action.payload]).concat(state.searchResults.list.slice(matchIndex+1))
    }
    return {
      ...newState,
      searchResults: {
        ...state.searchResults,
        list: newResults
      },
      promoCode: null,
      editing: false,
    }
  }

  if (!action.loading && action.error) {
    return {
      ...newState,
      errorMsg: getActionErrorMessage(action)
    }
  }

  return newState
}

const ACTION_HANDLERS = {
  [SEARCH_PROMO_CODES]:
    (state, action) => ({...state, loading: action.loading || false, error: action.error, searchResults: action.payload || [ ] }),
  [PROMO_CODE_SEARCH_CODE_CHANGED]:
    (state, action) => ({...state, codeSearchText: action.codeSearchText}),
  [PROMO_CODE_DIALOG]:
    (state, action) => ({...state, promoCode: action.promoCode, editing: !isNull(action.promoCode), errorMsg: null, successMsg: null}),
  [PROMO_CODE_MODIFIED]:
    (state, action) => ({...state, promoCode: {...state.promoCode, ...action.updatedPromoCode}}),
  [CREATE_PROMO_CODE]: handleCreatePromoCode,
  [PATCH_PROMO_CODE]: handlePatchPromoCode,
  [PROMO_CODE_VALIDATION_ERROR]:
    (state, action) => ({...state, errorMsg: action.errorMsg})
}

export default function promoCodeReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
