import React, { Component, useState, useEffect, useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import moment from 'moment'
import KitchenHoursActions from '../../../actions/KitchenHoursActions'
import { updateKitchen } from '../../../modules/kitchens'
import { isAdminUser, isDriverAdmin, hasMenuBuilderRole } from '../../../modules/user'
import { updateFilter, updateSort } from '../../../actions/issueSearchActions'
import { Link } from 'react-router'
import { get, isEmpty, isArray, filter, fromPairs, toPairs, map } from 'lodash';
import Modal from '../../../components/Modal/Modal'

import '../style/Kitchen.sass'

const HOURS = [
  [ '-- Select Time --', '-1' ],
  [ '6 AM',    '06:00' ],
  [ '6:30 AM', '06:30' ],
  [ '7 AM',    '07:00' ],
  [ '7:30 AM', '07:30' ],
  [ '8 AM',    '08:00' ],
  [ '8:30 AM', '08:30' ],
  [ '9 AM',    '09:00' ],
  [ '9:30 AM', '09:30' ],
  [ '10 AM',   '10:00' ],
  [ '10:30 AM','10:30' ],
  [ '11 AM',   '11:00' ],
  [ '11:30 AM','11:30' ],
  [ 'Noon',    '12:00' ],
  [ '1 PM',    '13:00' ],
  [ '2 PM',    '14:00' ],
  [ '3 PM',    '15:00' ],
  [ '4 PM',    '16:00' ],
  [ '5 PM',    '17:00' ],
  [ '6 PM',    '18:00' ],
  [ '7 PM',    '19:00' ],
  [ '8 PM',    '20:00' ],
  [ '9 PM',    '21:00' ],
  [ '10 PM',   '22:00' ],
  [ '11 PM',   '23:00' ],
  [ 'Midnight','23:59' ],
]

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const HourSelector = ({value, onChange}) => {
  return (
    <select className='mr-2' value={value} onChange={onChange}>
      {HOURS.map(hour => (
        <option key={hour} value={hour[1]}>{hour[0]}</option>
      ))}
    </select>
  )
}

const HourEditRow = ({forwardRef, day, normalOpen, normalClose, overrideOpen, overrideClose, normalClosed, overrideClosed}) => {
  const [isModified, setModified] = useState(false)
  const [isPermanent, setPermanent] = useState(false)
  const [isClosed, setClosed] = useState(false)
  const [open, setOpen] = useState()
  const [close, setClose] = useState()

  useEffect(() => {
    setOpen(overrideOpen || normalOpen)
    setClose(overrideClose || normalClose)
    setClosed(overrideClosed || (normalClosed && !overrideOpen && !overrideClose))
  }, [setOpen, setClose, setClosed, normalOpen, overrideOpen, normalClose, overrideClose, normalClosed, overrideClosed])

  // Allow parent component to get state from this component
  useImperativeHandle(forwardRef, () => ({
    isModified,
    isPermanent,
    isClosed,
    open,
    close
  }), [isModified, isPermanent, isClosed, open, close])

  const normalOpenFormatted = moment(normalOpen, ['HH:mm']).format('h:mm A')
  const normalCloseFormatted = moment(normalClose, ['HH:mm']).format('h:mm A')

  return (<div className='form-group'>
            <label>{day}</label>

            <div>
            {isClosed ?
                null :
                <span>
                  <HourSelector value={open || ''}  onChange={e => {
                    setOpen(e.target.value)
                    setModified(true)
                  }} />
                  <HourSelector value={close || ''} onChange={e => {
                    setClose(e.target.value)
                    setModified(true)
                  }} />
                </span>}


                <span>
                  <label className='ml-3'>
                    <input type='checkbox' checked={isClosed} onChange={() => {
                      setClosed(!isClosed)
                      setModified(true)
                    }} /> Closed
                  </label>
                  {isModified ?
                    <label className='ml-3'>
                      <input type='checkbox' checked={isPermanent} onChange={() => {
                        setPermanent(!isPermanent)
                        setModified(true)
                      }} /> Permanent
                    </label> : null}
                </span>
            </div>
            {(overrideOpen && overrideClose) || overrideClosed ?
              (<div><b>Normal Hours</b>:&nbsp;{normalClosed ? 'Closed' : `${normalOpenFormatted} - ${normalCloseFormatted}`}</div>) :
              null}
          </div>)
}

const HoursModal = ({open, name, loading, errors, hours, overriddenHours, onClose, onSave}) => {
  const dayRefs = {
    'monday': useRef({}),
    'tuesday': useRef({}),
    'wednesday': useRef({}),
    'thursday': useRef({}),
    'friday': useRef({}),
    'saturday': useRef({}),
    'sunday': useRef({})
  }

  const saveHandler = () => {
    // Get all days that have modified permanent hours into a format to send to the callback
    const modifiedHours = fromPairs(
      map(
        filter(
          toPairs(dayRefs),
          (refPair) => (get(refPair, '[1].current.isModified') && get(refPair, '[1].current.isPermanent'))
        ),
        (pair) => {
          const dayHours = get(pair, '[1].current.isClosed') ? [] : [[get(pair, '[1].current.open'), get(pair, '[1].current.close')]]
          return [pair[0], dayHours]
        }
      )
    )

    // Get all days that have modified override hours into a format to send to the callback
    const modifiedOverriddenHours = fromPairs(
      map(
        filter(
          toPairs(dayRefs),
          (refPair) => (get(refPair, '[1].current.isModified') && !get(refPair, '[1].current.isPermanent'))
        ),
        (pair) => {
          const dayHours = get(pair, '[1].current.isClosed') ? [] : [[get(pair, '[1].current.open'), get(pair, '[1].current.close')]]
          return [pair[0], dayHours]
        }
      )
    )
    onSave({modifiedHours, modifiedOverriddenHours});
  }

  const footer = (
    <div>
      <button disabled={loading} className='btn' onClick={onClose}>Cancel</button>
      <button disabled={loading} className='btn btn-primary' onClick={saveHandler}>Save</button>
    </div>
  )


  return (
    <Modal
      title={name + ' Hours'}
      isOpen={open}
      onClose={onClose}
      footer={footer}
      modalId='kitchen-hours'>
      {errors ? <p className='alert alert-danger'>
        There was an error saving the hours. {errors[0]}
      </p> : null}
    <div>
      {DAYS.map(day => {
        return (<HourEditRow
            forwardRef={dayRefs[day]}
            key={day}
            day={day}
            normalOpen={get(hours, `[${day}][0][0]`, null)}
            normalClose={get(hours, `[${day}][0][1]`, null)}
            overrideOpen={get(overriddenHours, `[${day}][0][0]`, null)}
            overrideClose={get(overriddenHours, `[${day}][0][1]`, null)}
            normalClosed={hours[day] === null || hours[day].length === 0 || (get(hours[day], '[0][0]') === '-1' && get(hours[day], '[0][1]'))}
            overrideClosed={isArray(overriddenHours[day]) && isEmpty(overriddenHours[day])} />)
      })}
    </div>
    </Modal>
  )
}

class Kitchen extends Component {

  state = {
    editHours: false,
    kitchenManageDropdownIsOpen: false
  }

  constructor(props) {
    super(props)

    this.toggleKitchenManageDropdown = this.toggleKitchenManageDropdown.bind(this)
  }

  confirmAndSubmit(e) {
    const {id} = this.props.kitchen
    if (e.keyCode === 13) {
      if (window.confirm('Are you sure you want to set a banner?')) {
        this.props.updateKitchen(id, { announcement: e.target.value })
      }
    }
  }

  clearBanner() {
    const {id} = this.props.kitchen
    if (window.confirm('Are you sure you want to remove banner?')) {
      this.props.updateKitchen(id, { announcement: null })
    }
  }

  renderAnnouncementText() {
    const { announcement, name } = this.props.kitchen

    if (announcement) {
      return (
        <strong className="block">{announcement}</strong>
      )
    }

    return (
      <input type="text"
        className="form-control"
        placeholder={'Set ' + name + ' kitchen banner...'}
        onKeyUp={this.confirmAndSubmit.bind(this)} />
    )
  }

  viewOpenIssues() {
    const { id } = this.props.kitchen
    this.props.updateFilter({
      kitchen_id: id,
      state: 'open'
    })
    this.props.updateSort('created_at', 'desc')
  }

  renderAnnouncement() {
    const { announcement } = this.props.kitchen
    const removeBannerLink = announcement ?
      (<button onClick={this.clearBanner.bind(this)}
        className="btn btn-link"
        style={{ padding: 0 }}>
          Remove banner
        </button>) : null

    return (
        <div className={classnames("d-flex align-items-start flex-column list-group-item", { 'list-group-item-danger': announcement})}>
          {this.renderAnnouncementText()}
          {removeBannerLink}
        </div>
    )
  }

  saveKitchenHours({modifiedHours, modifiedOverriddenHours}) {
    const { hours, overriddenHours, updateKitchenHours } = this.props
    const { id } = this.props.kitchen

    updateKitchenHours(id, {...hours, ...modifiedHours}, {...overriddenHours, ...modifiedOverriddenHours}).then(this.closeHourEditor.bind(this))
  }

  openHourEditor() {
    this.setState({editHours: true})
  }

  closeHourEditor() {
    this.setState({editHours: false})
  }

  toggleKitchenManageDropdown() {
    const { kitchenManageDropdownIsOpen } = this.state

    this.setState({ kitchenManageDropdownIsOpen: !kitchenManageDropdownIsOpen })
  }

  renderHoursModal() {
    const { name } = this.props.kitchen
    const { hoursReducer, hours, overriddenHours } = this.props

    return (<HoursModal
        open={this.state.editHours}
        loading={hoursReducer.loading}
        name={name}
        hours={hours}
        overriddenHours={overriddenHours}
        errors={get(hoursReducer, 'error.details.hours')}
        onClose={this.closeHourEditor.bind(this)}
        onSave={this.saveKitchenHours.bind(this)}
      />)
  }

  renderKitchenGroupHeader() {
    const { id, name, slug } = this.props.kitchen
    const { hasMenuBuilderRole } = this.props

    const ordersPath = `kitchens/${slug}/orders`

    return (
      <li className="list-group-header with-button">
        <h5 className="heading-center-text"><Link to={ordersPath}>{name}</Link></h5>
        <div className={classnames('dropdown', { show: this.state.kitchenManageDropdownIsOpen })}>
          <button
            className="btn btn-link dropdown-toggle"
            style={{ padding: 0 }}
            type="button"
            onClick={this.toggleKitchenManageDropdown}>Manage
          </button>
          <div className="dropdown-menu">
            <Link className="dropdown-item"
              onClick={this.viewOpenIssues.bind(this)} to='/issues'>Open Issues
            </Link>
            {hasMenuBuilderRole ? (
              <Link className="dropdown-item" to={`/menu/${slug}/items`}>Manage Menu</Link>
            ) : null}
            <Link className="dropdown-item" to={`/kitchens/${slug}/schedule-ranges`}>Schedule Slots</Link>
            <Link className="dropdown-item" to={`/kitchens/${slug}/delivery-areas`}>Delivery Areas</Link>
            <a className="dropdown-item" href={`/kitchens/${id}/settings`}>Settings</a>
          </div>
        </div>
      </li>
    )
  }

  onRecruitingStateChange(e) {
    const { id, name } = this.props.kitchen
    const { updateKitchen } = this.props
    const recruiting_state = e.target.checked ? 'active' : 'hidden'
    const verb = recruiting_state === 'active' ? 'enable' : 'disable'

    if (window.confirm(`Are you sure you want to ${verb} driver recruitment for ${name}?`)) {
      updateKitchen(id, { recruiting_state })
    }
  }

  renderDriverRecruitiment() {
    const { recruiting_state } = this.props.kitchen
    const { isDriverAdmin } = this.props

    if (!isDriverAdmin) {
      return null
    }

    return (
      <li className="list-group-item list-group-item-action justify-content-between">
        <span>Driver Recuriting</span>
        <div>
          <label>enabled</label> <input type='checkbox' onChange={this.onRecruitingStateChange.bind(this)} checked={recruiting_state === 'active'} />
        </div>
      </li>
    )
  }

  render() {
    const { isAdminUser } = this.props
    const { id, open, today_hours } = this.props.kitchen

    const openSign = open ?
      <span className="badge badge-success">Open</span> :
      <span className="badge badge-danger">Closed</span>

    const displayHours = isEmpty(today_hours) ? '' : <span>{today_hours[0][0]} - {today_hours[0][1]}</span>

    return (
      <div className="col-lg-4 mb-5" key={id}>
        <ul className="list-group mb-3">
          {this.renderKitchenGroupHeader()}

          {this.renderAnnouncement()}

          {this.renderDriverRecruitiment()}

          <li className="list-group-item list-group-item-action justify-content-between">
            <span>Hours</span>
            <div>
              {openSign} {displayHours}
            </div>
            {isAdminUser ?
              <button onClick={this.openHourEditor.bind(this)} className="btn btn-link">Edit</button> :
              null}
          </li>
        </ul>

        {this.renderHoursModal()}
      </div>
    )}
}

const mapStateToProps = (state, props) => {
  const hours = get(state.kitchenHours.hoursByKitchen, props.kitchen.id)
  const overriddenHours = get(state.kitchenHours.overriddenHoursByKitchen, props.kitchen.id)
  const hoursReducer = state.kitchenHours
  const user = get(state, 'user.data')

  return {
    hours,
    overriddenHours,
    hoursReducer,
    isAdminUser: isAdminUser(user),
    isDriverAdmin: isDriverAdmin(user),
    hasMenuBuilderRole: hasMenuBuilderRole(user)
  }
}

const mapDispatchToProps = {
  updateKitchen,
  updateKitchenHours: KitchenHoursActions.updateKitchenHours,
  updateFilter,
  updateSort,
}

Kitchen.propTypes = {
  kitchen: PropTypes.object.isRequired,
  updateKitchen: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Kitchen)
