export default (store) => ({
  path : 'login',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    import(
      /* webpackChunkName: 'LoginView' */
      './components/LoginView'
    ).then(module => {
      cb(null, module.default)
    })
  }
})
