import { handleHttpResponse } from '../lib/handle-http-response'
import DriversAPI from '../api/drivers'

export const SEARCH_DRIVERS = 'SEARCH_DRIVERS'
export const CHANGE_MARKET = 'CHANGE_MARKET'
export const CHANGE_STATE = 'CHANGE_STATE'
export const CHANGE_EXPIRED_DOCUMENT = 'CHANGE_EXPIRED_DOCUMENT'
export const CHANGE_TERM = 'CHANGE_TERM'

export function searchDrivers(term, market=null, state=null, expired_document=null, offset=0) {
  return(dispatch) => {
    return handleHttpResponse(dispatch, SEARCH_DRIVERS, DriversAPI.search(term, market, state, expired_document, offset))
  }
}

export function changeMarket(market) {
  return ({
    type: CHANGE_MARKET,
    market: market
  })
}

export function changeState(state) {
  return ({
    type: CHANGE_STATE,
    state: state
  })
}

export function changeExpiredDocument(expired_document) {
  return ({
    type: CHANGE_EXPIRED_DOCUMENT,
    expired_document: expired_document
  })
}

export function changeTerm(term){
  return({
    type: CHANGE_TERM,
    term: term
  })
}

const searchDriversHandler = (state, action) => {
  return {
    ...state,
    loading: action.loading || false,
    error: action.error,
    searchResults: action.payload || [ ]
  }
}

const changeMarketHandler = (state, action) => {
  return {
    ...state,
    searchQuery: {
      ...state.searchQuery,
      market: action.market
    }
  }
}

const changeStateHandler = (state, action) => {
  return {
    ...state,
    searchQuery: {
      ...state.searchQuery,
      state: action.state
    }
  }
}

const changeExpiredDocumentHandler = (state, action) => {
  return {
    ...state,
    searchQuery: {
      ...state.searchQuery,
      expired_document: action.expired_document
    }
  }
}

const changeTermHandler = (state, action) => {
  return {
    ...state,
    searchQuery: {
      ...state.searchQuery,
      term: action.term
    }
  }
}

const initialState = {
  loading: false,
  error: false,
  searchResults: [ ],
  searchQuery: {
    term: '',
    market: 'all',
    state: 'all_drivers',
    expired_document: 'none'
  },
}

const ACTION_HANDLERS = {
  [SEARCH_DRIVERS]: searchDriversHandler,
  [CHANGE_MARKET]: changeMarketHandler,
  [CHANGE_STATE]: changeStateHandler,
  [CHANGE_EXPIRED_DOCUMENT]: changeExpiredDocumentHandler,
  [CHANGE_TERM]: changeTermHandler,
}

export default function driverReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
