import { get, isEmpty, reduce, differenceBy } from 'lodash';

const findRemovedRelationshipResourceObjectIdentifiers = (
  indexOfUpdatedResourceObject,
  newTopLevelDocument,
  documentDataCopy
) => {
  const removedDocument =
    documentDataCopy.splice(indexOfUpdatedResourceObject, 1, newTopLevelDocument.data)[0];
  if (removedDocument.relationships) {
    const removedDocumentRelationships =
      reduce(removedDocument.relationships, (result, value, key) => [ ...result, ...value.data ], []);
    const newResourceObjectRelationships =
      reduce(newTopLevelDocument.relationships, (result, value, key) => [ ...result, ...value.data ], []);

    return differenceBy(removedDocumentRelationships, newResourceObjectRelationships, 'id');
  }
};

export const updateSingleDataResourceObject = (state, action) => {
  const newTopLevelDocument = get(action, 'payload.body', []);
  if (Array.isArray(newTopLevelDocument.data)) {
    console.error('Payload top level document data is an Array, but has to be an Object in order to',
      'update a single data resource object');
    return { ...state.topLevelDocument };
  }

  const stateTopLevel = isEmpty(state.topLevelDocument) ? { data: [] } : state.topLevelDocument

  let removedRelationships;
  const documentDataCopy = stateTopLevel.data.slice();
  const indexOfUpdatedResourceObject = stateTopLevel.data.findIndex(
    resourceObject => resourceObject.id === newTopLevelDocument.data.id
  );
  if (indexOfUpdatedResourceObject > -1) {
    removedRelationships = findRemovedRelationshipResourceObjectIdentifiers(
      indexOfUpdatedResourceObject,
      newTopLevelDocument,
      documentDataCopy
    );
  } else {
    if (newTopLevelDocument.data.getAttribute && newTopLevelDocument.data.getAttribute('component_type') === 'BannerMenuComponent') {
      // stick the banner at the top
      documentDataCopy.unshift(newTopLevelDocument.data);
    } else {
      documentDataCopy.push(newTopLevelDocument.data);
    }
  }

  if (isEmpty(newTopLevelDocument.included)) {
    return { data: documentDataCopy, included: stateTopLevel.included, meta: stateTopLevel.meta };
  }

  const compoundDocumentsCopy = stateTopLevel.included.slice();
  newTopLevelDocument.included.forEach(newCompoundDocument => {
    const indexOfUpdatedCompoundDocument = compoundDocumentsCopy.findIndex(
      existingCompoundDocument => existingCompoundDocument.id === newCompoundDocument.id
    );

    if (indexOfUpdatedCompoundDocument > -1) {
      compoundDocumentsCopy.splice(indexOfUpdatedCompoundDocument, 1, newCompoundDocument);
    } else {
      compoundDocumentsCopy.push(newCompoundDocument);
    }
  });

  // Remove the old compound document that is no longer by any data resource objects, if any.
  if (removedRelationships) {
    removedRelationships.forEach(relationship => {
      compoundDocumentsCopy.splice(
        compoundDocumentsCopy.findIndex(compoundDocument => compoundDocument.id === relationship.id), 1
      );
    });
  }

  return { data: documentDataCopy, included: compoundDocumentsCopy, meta: stateTopLevel.meta };
};
