import { applyMiddleware, compose, createStore } from 'redux'
import { createReduxLocationActions } from 'redux-location-state'
import { reduce } from 'lodash'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'

import makeRootReducer from './reducers'
import sideEffects from 'middleware/sideEffects'
import jsonApiReducer from 'middleware/jsonApiReducer'

const routes = require('../routes').default()
const stateToParams = reduce(
  routes.childRoutes,
  (acc, route) => {
    if (route.stateToParams) {
      return { ...acc, [route.path]: route.stateToParams }
    }
    return acc
  },
  {}
)
const paramsToState = reduce(
  routes.childRoutes,
  (acc, route) => {
    if (route.paramsToState) {
      return { ...acc, [route.path]: route.paramsToState }
    }
    return acc
  },
  {}
)

const mapLocationToState = (state, location) => {
  if (paramsToState[location.pathname]) {
    return paramsToState[location.pathname](state, location)
  }
  return state
}

export default (initialState = {}, history) => {

  const {locationMiddleware, reducersWithLocation} = createReduxLocationActions(stateToParams, mapLocationToState, history, makeRootReducer())

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk,
    locationMiddleware,
    promiseMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR']
    }),
    sideEffects,
    jsonApiReducer
  ]

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = []
  /*
  if (__DEV__) {
    const devToolsExtension = window.devToolsExtension
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension())
    }
  }
  */

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducersWithLocation,
    initialState,
    composeEnhancers(
      applyMiddleware(
        ...middleware,
        promiseMiddleware({
          promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR']
        })
      ),
      ...enhancers
    )
  )
  store.asyncReducers = {}

  // To unsubscribe, invoke `store.unsubscribeHistory()` anytime
  //store.unsubscribeHistory = browserHistory.listen(updateLocation(store))

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default
      store.replaceReducer(reducers(store.asyncReducers))
    })
  }

  return store
}

