import { createActions } from 'redux-actions';

import ActionTypes from '../constants/ActionTypes';

export default createActions({
  [ActionTypes.NOTIFICATION_TOAST_CLEAR_TEXT]: () => ({}),
  [ActionTypes.NOTIFICATION_TOAST_SUCCESS]: (text) => ({
    text: text,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ERROR]: (text) => ({
    text: text,
    toastType: 'danger'
  }),
  [ActionTypes.NOTIFICATION_TOAST_TRUCK_UPDATE_SUCCESS]: (truckName) => ({
    text: `Truck ${truckName} was successfully updated.`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_FIX_ERRORS_ABOVE]: () => ({
    text: 'Please fix the errors above.',
    toastType: 'danger'
  }),
  [ActionTypes.NOTIFICATION_TOAST_GENERIC_ERROR]: () => ({
    text: 'An error has occurred, please check the JavaScript console for details.',
    toastType: 'danger'
  }),
  [ActionTypes.NOTIFICATION_TOAST_TRUCK_ACTIVE_STATE_TOGGLE_MESSAGE]: (activeState) => ({
    text: `Successfully ${activeState ? 'enabled' : 'disabled'} truck.`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ITEM_TRUCK_ADD_SUCCESS]: (truckName) => ({
    text: `Successfully assigned item to ${truckName} truck.`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ITEM_TRUCK_REMOVE_SUCCESS]: (truckName) => ({
    text: `Successfully removed item from ${truckName} truck.`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ITEM_SUGGESTED_ITEM_ADD_SUCCESS]: (suggestedItemName) => ({
    text: `Successfully added ${suggestedItemName} as suggested item.`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ITEM_SUGGESTED_ITEM_REMOVE_SUCCESS]: (suggestedItemName) => ({
    text: `Successfully removed ${suggestedItemName} as suggested item.`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_MODIFIER_SAVE_CHANGES_SUCCESS]: (modifierName) => ({
    text: `Successfully saved changes to ${modifierName}`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ITEM_UPDATE_SUCCESS]: (itemName) => ({
    text: `Successfully saved changes to ${itemName}`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_ITEM_DELETE_SUCCESS]: (itemName) => ({
    text: `Successfully deleted ${itemName}`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_CUSTOMER_ROLE_GRANT_SUCCESS]: (roleGranted) => ({
    text: `Successfully granted role "${roleGranted}".`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_CUSTOMER_ROLE_REVOKE_SUCCESS]: (roleRevoked) => ({
    text: `Successfully revoked role "${roleRevoked}".`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_COMPONENT_CREATE_SUCCESS]: (componentType) => ({
    text: `Successfully created component of type ${componentType}`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_PLACE_SAVE_SUCCESS]: (placeName) => ({
    text: `Successfully updated place ${placeName}`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_SAVE_SUCCESS]: (dropoffLocationName) => ({
    text: `Successfully updated drop-off location ${dropoffLocationName}`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_ERROR]: (errorMessage) => ({
    text: `Could not save the drop-off location: ${errorMessage}`,
    toastType: 'danger'
  }),
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_CREATE_SUCCESS]: () => ({
    text: `Successfully created new drop-off location`,
    toastType: 'success'
  }),
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_ARCHIVE_SUCCESS]: (dropoffLocationName) => ({
    text: `Successfully archived drop-off location ${dropoffLocationName}`,
    toastType: 'success'
  }),
});
