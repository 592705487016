import ClusterpointsAPI from '../api/clusterpoints'
import { handleHttpResponse } from '../lib/handle-http-response'

const initialState = {
    loading: true,
    data: null
}

export function loadClusterpointsConfig() {
    return (dispatch) => {
        handleHttpResponse(dispatch, 'LOAD_CLUSTERPOINTS_CONFIG', ClusterpointsAPI.getConfig())
    }
}

export default function clusterpoints(state = initialState, action) {
    switch(action.type) {
        case 'LOAD_CLUSTERPOINTS_CONFIG':
            return {
                ...initialState,
                loading: action.loading,
                data: action.payload
            }
    }

    return state
}
