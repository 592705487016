import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SearchIcon from './icons/Search.js'

export default class SearchOnEnterBox extends Component {

  state = {
    value: ''
  }

  constructor(props, state) {
    super(props, state)

    this.state = {
      value: this.props.default
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.default !== this.props.default) {
      this.setState({
        value: this.props.default
      })
    }
  }

  focus() {
    this.refs.searchInput.focus()
  }

  onChange(e) {
    this.setState({
      value: e.target.value
    })
    if (this.props.onChange) {
      this.props.onChange(e)
    }
  }

  onKeyPress(e) {
    if(e.key === 'Enter') {
      this.props.onSearch(this.refs.searchInput.value)
    }
  }

  onButtonClick = (e) => {
    this.props.onSearch(this.refs.searchInput.value)
  }

  getValue() {
    return this.refs.searchInput.value
  }

  render() {
    const button = this.props.includeButton ? <span className='input-group-btn'><button className='btn btn-primary' type='button' style={{padding: '8px'}} onClick={this.onButtonClick}><SearchIcon width={14} height={14} color='white'/></button></span> : null

    return (
      <div className='input-group'>
        <input
          ref='searchInput'
          type="text"
          onChange={this.onChange.bind(this)}
          onKeyPress={this.onKeyPress.bind(this)}
          value={this.state.value || ''}
          className="form-control input-block" placeholder={this.props.placeholder} />
        {button}
      </div>
    )
  }
}

SearchOnEnterBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  default: PropTypes.string,
  placeholder: PropTypes.string,
  includeButton: PropTypes.bool
}
