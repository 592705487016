import { createActions } from 'redux-actions'
import IssuesAPI from '../api/issues'

export const {
  updateFilter,
  updatePage,
  updateSort,
  clearFilter,
  searchIssues,
  getIssueTypes
} = createActions({
  UPDATE_FILTER: filter => filter,
  UPDATE_PAGE: page => page,
  UPDATE_SORT: (field, order) => ({field, order}),
  CLEAR_FILTER:  () => true,
  SEARCH_ISSUES: (filter, sort, page = 0) => ({
    data: {},
    promise: IssuesAPI.search(filter, sort, page * 20, 20)
  }),
  TOGGLE_DATE_PICKER_MODAL: flag => flag,
  GET_ISSUE_TYPES: () => ({
    promise: IssuesAPI.getIssueTypes()
  }),
})
