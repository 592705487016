import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GOOGLE_CLIENT_ID } from '../constants'
import { connect } from 'react-redux'
import { logout } from '../modules/user'
import { clearAlert } from '../modules/alerts'
import { loadKitchens } from '../modules/kitchens'
import { loadClusterpointsConfig } from '../modules/clusterpoints'
import { clearCustomer } from '../modules/customer'
import { Link } from 'react-router'
import { get, last, isEmpty } from 'lodash'
import classnames from 'classnames'
import customerAPI from '../api/customer'
import NotificationToast from '../components/NotificationToast';
import Loading from '../routes/FrontApp/components/Loading'

const Front = window.Front

class CoreLayout extends Component {

  static contextTypes = {
    router: PropTypes.object.isRequired
  }

  state = {
    navOpen: false,
    frontContext: false,
    frontLoading: false
  }

  componentDidMount() {
    const { kitchens, location: { pathname }, loadKitchens, loadClusterpointsConfig } = this.props;
    const { router } = this.context
    const { clearCustomer } = this.props


    if (!this.requireLogin(this.props) && isEmpty(kitchens)) {
      if (!/\/kitchens\/[a-z-]+\/delivery-areas/.test(pathname)) {
        loadKitchens();
      }
    }

    loadClusterpointsConfig()

    if (Front) {
      Front.on('panel_visible', (visible) => {
        console.log('setting front context')
        this.setState({frontContext: true})
      })

      Front.on('no_conversation', () => {
        clearCustomer()
      })

      Front.on('conversation', (data) => {
        clearCustomer()
        this.setState({frontLoading: true})

        customerAPI.getFrontContact(data.contact.id)
          .then(({body}) => {
            const { ct_id } = body
            this.setState({frontLoading: false})
            router.push(`/users/${ct_id}`)
          })
          .catch(err => {
            this.setState({frontLoading: false})
            router.push(`/front`)
          })
      })
    }
  }

  componentDidUpdate() {
    const { kitchens, kitchensLoading, location: { pathname }, loadKitchens } = this.props;
    if (!kitchensLoading && isEmpty(kitchens)) {
      if (!/\/kitchens\/[a-z-]+\/delivery-areas/.test(pathname)) {
        loadKitchens();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.requireLogin(nextProps)
  }

  requireLogin(props) {
    const { location } = this.props
    const { pathname } = location
    if (!props.isAuthed && pathname !== '/login') {
      this.context.router.push('/login')
      return true
    }
    return false
  }

  toggleNav() {
    this.setState({
      navOpen: !this.state.navOpen
    })
  }

  logout() {
    const { logout } = this.props
    window.gapi.load('auth2', function() {
      window.gapi.auth2.init({
        clientid: GOOGLE_CLIENT_ID,
        hosted_domain: 'clustertruck.com'
      }).then(() => {
        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut().then(() => {
          logout()
        })
      })
    })
  }

  renderAlert() {
    if (!this.props.alerts.alert) {
      return null
    }

    const dismissButton = this.props.alerts.dismissable ? (<button type='button' className='close' onClick={this.props.clearAlert} style={{top: '-1rem'}} aria-label='Close'>
      <span aria-hidden='true'>&times;</span>
    </button>) : null

    return (
      <div style={{position: 'absolute', top: 0, left: '50%', zIndex: 9999, minWidth: '35%'}}>
        <div className={classnames('alert', `alert-${this.props.alerts.alertType}`, {'alert-dismissible': this.props.alerts.dismissable})} style={{position: 'relative', left: '-50%', textAlign: 'center'}} role='alert'>
          {dismissButton}
          {this.props.alerts.alert}
        </div>
      </div>
    )
  }

  renderNav() {
    const { frontContext } = this.state
    const dashboardUrl = window.ENV.dashboardUrl

    if (frontContext) {
      return null
    }

    return (
      <nav className="header site-header navbar navbar-toggleable-sm fixed-top navbar-inverse bg-inverse app-navbar">
        <button onClick={this.toggleNav.bind(this)} className="navbar-toggler navbar-toggler-right hidden-md-up" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <Link className="navbar-brand" to="/">
          ClusterTruck Ops
        </Link>

        <div hidden={!this.props.isAuthed}>
        <div className={classnames("navbar-collapse mr-auto", {collapse: !this.state.navOpen})} id="navbarResponsive">
          <ul className="nav navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">Kitchens</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/orders">Orders</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/order_reports">ClusterMap</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/places">Places</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/issues">Issues</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/users">Users</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/drivers">Drivers</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/promo_codes">Promo Codes</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/teams">Teams</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={dashboardUrl}>Dashboards</a>
            </li>
            <li className="nav-item">
              <Link to="/logout" className="nav-link" onClick={this.logout.bind(this)}>Sign Out</Link>
            </li>
          </ul>
        </div>
        </div>
      </nav>
    )
  }

  render() {
    const route = last(this.context.router.routes)
    const fluid = get(route, 'layout.fluid', false)
    const additionalClassNames = get(route, 'layout.classNames', [])
    const { toastText, toastType } = this.props;
    const { frontLoading } = this.state

    return (
      <div>
        {this.renderAlert()}
        {this.renderNav()}

        <div className={classnames({ 'container' : !fluid, 'container-fluid container-fluid-spacious': fluid }, additionalClassNames)}>
          {frontLoading ? <Loading /> : this.props.children}
        </div>

        <NotificationToast toastType={toastType} text={toastText}></NotificationToast>
      </div>
      );
  }
}

const mapPropsToDispatch = {
  logout,
  clearAlert,
  loadKitchens,
  clearCustomer,
  loadClusterpointsConfig
}

const mapStateToProps = (state) => {
  const { user, alerts, kitchens } = state
  const { text, toastType } = state.notificationToasts;

  return {
    user,
    isAuthed: state.user.token,
    alerts,
    kitchens: kitchens.list || [],
    kitchensLoading: kitchens.loading,
    toastText: text,
    toastType
  }
}

CoreLayout.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapPropsToDispatch)(CoreLayout)
