import { handleActions } from 'redux-actions';
import { isEmpty } from 'lodash';

import ActionTypes from '../constants/ActionTypes';
import { handleRequest, handleError } from './util.js';

const initialState = {
  loading: false,
  error: false,
  byKitchenSlug: {}
}

export default handleActions({
  [ActionTypes.TAX_RATE_NEW]: (state, action) => {
    const { kitchenSlug, kitchenId } = action.payload;

    const taxRatesByKitchen = { ...state.byKitchenSlug };
    if (isEmpty(taxRatesByKitchen[kitchenSlug])) {
      taxRatesByKitchen[kitchenSlug] = [];
    }
    taxRatesByKitchen[kitchenSlug].push({
      id: 'new',
      kitchen_id: kitchenId
    });

    return {
      ...state,
      loading: false,
      byKitchenSlug: { ...taxRatesByKitchen }
    };
  },
  [ActionTypes.TAX_RATE_CREATE_REQUEST]: handleRequest,
  [ActionTypes.TAX_RATE_CREATE_SUCCESS]: (state, action) => {
    const newTaxRate = action.payload.data;
    const kitchenSlug = action.payload.kitchenSlug;

    const taxRatesByKitchen = { ...state.byKitchenSlug };
    const index =
      taxRatesByKitchen[kitchenSlug].findIndex((taxRate) => taxRate.id === 'new');
    taxRatesByKitchen[kitchenSlug][index] = newTaxRate;

    return {
      ...state,
      loading: false,
      byKitchenSlug: { ...taxRatesByKitchen }
    };
  },
  [ActionTypes.TAX_RATE_CREATE_ERROR]: handleError,
  [ActionTypes.TAX_RATES_LOAD_REQUEST]: handleRequest,
  [ActionTypes.TAX_RATES_LOAD_SUCCESS]: (state, action) => {
    const taxRates = action.payload.data;
    const kitchenSlug = action.payload.kitchenSlug;
    const taxRatesByKitchen = { ...state.byKitchenSlug };
    taxRatesByKitchen[kitchenSlug] = taxRates;

    return {
      ...state,
      loading: false,
      byKitchenSlug: taxRatesByKitchen
    };
  },
  [ActionTypes.TAX_RATES_LOAD_ERROR]: handleError
}, initialState);
