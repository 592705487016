import { handleActions } from 'redux-actions'
import { omitBy, isUndefined, get, keyBy } from 'lodash'

const initialState = {
  loading: false,
  error: false,
  data: [],
  meta: {},
  filter: {},
  sort: { field: 'created_at', order: 'asc' },
  page: 0,
  issueTypes: [],
  issueTypesMap: {},
}

const reducer = handleActions({
  UPDATE_FILTER: (state, action) => {
    const filter = omitBy({
      ...state.filter,
      ...action.payload
    }, isUndefined)

    return {
      ...state,
      filter,
      page: initialState.page,
      data: initialState.data,
      meta: initialState.meta
    }
  },

  UPDATE_PAGE: (state, action) => ({
    ...state,
    page: action.payload,
    data: initialState.data,
    meta: initialState.meta
  }),

  UPDATE_SORT: (state, action) => ({
    ...state,
    sort: action.payload,
    page: initialState.page,
    data: initialState.data,
    meta: initialState.meta
  }),

  CLEAR_FILTER: (state, action) => ({
    ...state,
    filter: {...initialState.filter},
    page: initialState.page,
    data: initialState.data,
    meta: initialState.meta
  }),

  SEARCH_ISSUES_REQUEST: (state, action) => ({
    ...state,
    loading: true,
    error: false
  }),

  SEARCH_ISSUES_SUCCESS: (state, action) => ({
    ...state,
    loading: false,
    error: false,
    data: get(action.payload, 'body.issues', initialState.data),
    meta: get(action.payload, 'body.meta', initialState.meta)
  }),

  SEARCH_ISSUES_ERROR: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.body,
    data: initialState.data,
    meta: initialState.meta
  }),

  GET_ISSUE_TYPES_SUCCESS: (state, action) => {
    const issueTypes = get(action.payload, 'body.issue_types', [])
    return {
      ...state,
      issueTypes: issueTypes,
      issueTypesMap: keyBy(issueTypes, 'id')
    }
  },
}, initialState)

export default reducer
