import request from 'superagent';

import { getTokenFromStorage } from '../util/api'

export const V1_ACCEPT_HEADER = 'application/vnd.api.clustertruck.com; version=1'
export const V2_ACCEPT_HEADER = 'application/vnd.api.clustertruck.com; version=2'
export const V3_ACCEPT_HEADER = 'application/vnd.api.clustertruck.com; version=3'

export function getUrl(path) {
  const base = window.ENV.BASE_URL || ''
  return base + path;
}

export function withAuthHeader(headers = {}) {
  const token = getTokenFromStorage();
  headers.Authorization = `Bearer ${token}`;

  return headers;
};

function responseHandler(resolve, reject) {
  return (err, res) => {
    if (err) {
      reject(err);
    } else {
      resolve(res.body);
    }
  }
}

const HttpClient = {
  get: (path, headers={}, query={}, accept = V1_ACCEPT_HEADER) => new Promise((resolve, reject) => {
    request
      .get(getUrl(path))
      .query(query)
      .set(withAuthHeader(headers))
      .accept(accept)
      .end(responseHandler(resolve, reject));
  }),

  post: (path, data, headers={}) => new Promise((resolve, reject) => {
    request
      .post(getUrl(path))
      .set(withAuthHeader(headers))
      .send(data)
      .accept('application/json')
      .end(responseHandler(resolve, reject));
  }),

  put: (path, data = {}, headers = {}, accept = V1_ACCEPT_HEADER) => new Promise((resolve, reject) => {
    request
      .put(getUrl(path))
      .set(withAuthHeader(headers))
      .send(data)
      .accept('application/json')
      .accept(accept)
      .end(responseHandler(resolve, reject));
  }),

  del: (path, headers = {}) => new Promise((resolve, reject) => {
    request
      .del(getUrl(path))
      .set(withAuthHeader(headers))
      .accept('application/json')
      .end(responseHandler(resolve, reject));
  }),

  patch: (path, data, headers = {}) => new Promise((resolve, reject) => {
    request
      .patch(getUrl(path))
      .set(withAuthHeader(headers))
      .send(data)
      .accept('application/json')
      .end(responseHandler(resolve, reject));
  }),

  patchMultiple: (path, data, headers = {}) => new Promise((resolve, reject) => {
    request
      .patch(getUrl(path))
      .set(withAuthHeader(headers))
      .send({ data })
      .accept('application/json')
      .end(responseHandler(resolve, reject));
  })
};

export default HttpClient;
