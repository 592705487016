import React from 'react';
import { render } from 'react-snapshot';
import { browserHistory } from 'react-router'
import { listenForHistoryChange } from 'redux-location-state'

import App from './App';
import createStore from './store/createStore'

import './index.sass';

const CX_WEB_APP_LOCAL_STORAGE = 'cx-web-app'
let initialState = {}

try {
  const parsedState = JSON.parse(localStorage.getItem(CX_WEB_APP_LOCAL_STORAGE))
  initialState = {
    user: parsedState.user || { }
  }
} catch(e) { }

const history = browserHistory
const store = createStore(initialState, history)
const routes = require('./routes').default(store)
listenForHistoryChange(store, history)

// Capture initial load
store.dispatch({
  type: 'REDUX-LOCATION-POP-ACTION',
  payload: history.getCurrentLocation()
})

store.subscribe(() => {
  const currentState = store.getState()
  localStorage.setItem(CX_WEB_APP_LOCAL_STORAGE, JSON.stringify(currentState))
})

render(
  <App routes={routes} store={store} history={history}/>,
  document.getElementById('root')
);
