import IssuesAPI from '../api/issues'
import { handleHttpResponse } from '../lib/handle-http-response'
import { get } from 'lodash'
import partialRight from 'lodash/partialRight'

import NotificationToastActions from 'actions/NotificationToastActions'
import { storeKeyNameFromField } from 'apollo-utilities'

export const OPEN_ISSUE_MODAL = 'OPEN_ISSUE_MODAL'
export const CLOSE_ISSUE_MODAL = 'CLOSE_ISSUE_MODAL'
export const OPEN_RESOLVE_MODAL = 'OPEN_RESOLVE_MODAL'
export const CLOSE_RESOLVE_MODAL = 'CLOSE_RESOLVE_MODAL'
export const OPEN_REOPEN_MODAL = 'OPEN_REOPEN_MODAL'
export const CLOSE_REOPEN_MODAL = 'CLOSE_REOPEN_MODAL'
export const TOGGLE_MORE_ACTIONS = 'TOGGLE_MORE_ACTIONS'
export const OPEN_REFUND_MODAL = 'OPEN_REFUND_MODAL'
export const CLOSE_REFUND_MODAL = 'CLOSE_REFUND_MODAL'
export const OPEN_POINTS_MODAL = 'OPEN_POINTS_MODAL'
export const CLOSE_POINTS_MODAL = 'CLOSE_POINTS_MODAL'
export const OPEN_FUNDS_MODAL = 'OPEN_FUNDS_MODAL'
export const CLOSE_FUNDS_MODAL = 'CLOSE_FUNDS_MODAL'
export const OPEN_PROMO_CODE_MODAL = 'OPEN_PROMO_CODE_MODAL'
export const CLOSE_PROMO_CODE_MODAL = 'CLOSE_PROMO_CODE_MODAL'
export const OPEN_AD_HOC_REFIRE_MODAL = 'OPEN_AD_HOC_REFIRE_MODAL'
export const CLOSE_AD_HOC_REFIRE_MODAL = 'CLOSE_AD_HOC_REFIRE_MODAL'
export const LOAD_ISSUES = 'LOAD_ISSUES'
export const LOAD_ISSUE = 'LOAD_ISSUE'
export const CREATE_ISSUE = 'CREATE_ISSUE'
export const UPDATE_ISSUE = 'UPDATE_ISSUE'
export const RESOLVE_ISSUE = 'RESOLVE_ISSUE'
export const REOPEN_ISSUE = 'REOPEN_ISSUE'
export const CLOSE_ISSUE = 'CLOSE_ISSUE'
export const DELETE_ISSUE = 'DELETE_ISSUE'

const initialState = {
  //ui
  ui : {
    modal: false,
    resolve: false,
    reopen:false,
    moreActions: false,
    refund: false,
    points: false,
    funds: false,
    promoCode: false,
    adHocRefire: false
  },
  // index
  list: {
    loading: false,
    error: false,
    data: [ ]
  },
  // show
  issue: {
    loading: false,
    error: false,
    data: { }
  },
  // edit
  editIssue: {
    saved: false,
    loading: false,
    error: false,
    data: { }
  },
  // create
  newIssue: {
    saved: false,
    loading: false,
    error: false,
    data: { }
  }
}

export function loadIssues(order) {
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_ISSUES, IssuesAPI.index(order))
  }
}

export function loadIssue(id){
  return (dispatch) => {
    return handleHttpResponse(dispatch, LOAD_ISSUE, IssuesAPI.load_issue(id))
  }
}

export function createIssue(orderId, data){
  return (dispatch) => {
    return handleHttpResponse(dispatch, CREATE_ISSUE, IssuesAPI.createIssue(orderId, data))
  }
}

export function updateIssue(id, data){
  return (dispatch) => {
    return handleHttpResponse(dispatch, UPDATE_ISSUE, IssuesAPI.updateIssue(id, data))
  }
}

export function resolveIssue(issueId, data){
  return (dispatch) => {
    return handleHttpResponse(dispatch, RESOLVE_ISSUE, IssuesAPI.resolveIssue(issueId, data))
  }
}

export function reopenIssue(issueId){
  return (dispatch) => {
    return handleHttpResponse(dispatch, REOPEN_ISSUE, IssuesAPI.reopenIssue(issueId))
  }
}

export function closeIssue(issueId){
  return (dispatch) => {
    return handleHttpResponse(dispatch, CLOSE_ISSUE, IssuesAPI.closeIssue(issueId))
  }
}

export function deleteIssue(issueId){
  return (dispatch) => {
    return handleHttpResponse(dispatch, DELETE_ISSUE, IssuesAPI.destroyIssue(issueId))
  }
}

export function openIssueModal() {
  return {
    type: OPEN_ISSUE_MODAL
  }
}

export function closeIssueModal() {
  return {
    type: CLOSE_ISSUE_MODAL
  }
}

export function openResolveModal() {
  return {
    type: OPEN_RESOLVE_MODAL
  }
}

export function closeResolveModal() {
  return {
    type: CLOSE_RESOLVE_MODAL
  }
}


export function openReopenModal() {
  return {
    type: OPEN_REOPEN_MODAL
  }
}

export function closeReopenModal() {
  return {
    type: CLOSE_REOPEN_MODAL
  }
}

export function toggleMoreActions() { return { type: TOGGLE_MORE_ACTIONS } }
export function openRefundModal() { return { type: OPEN_REFUND_MODAL } }
export function closeRefundModal() { return { type: CLOSE_REFUND_MODAL } }
export function openPointsModal() { return { type: OPEN_POINTS_MODAL } }
export function closePointsModal() { return { type: CLOSE_POINTS_MODAL } }
export function openFundsModal() { return { type: OPEN_FUNDS_MODAL } }
export function closeFundsModal() { return { type: CLOSE_FUNDS_MODAL } }
export function openPromoCodeModal() { return { type: OPEN_PROMO_CODE_MODAL } }
export function closePromoCodeModal() { return { type: CLOSE_PROMO_CODE_MODAL } }
export function openAdHocRefireModal() { return { type: OPEN_AD_HOC_REFIRE_MODAL } }
export function closeAdHocRefireModal() { return { type: CLOSE_AD_HOC_REFIRE_MODAL } }

const openIssueModalHandler = (state, action) => {
  return {
    ...state,
    ui: {
      modal: true
    },
    newIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    },
    editIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    }
  }
}

const closeIssueModalHandler = (state, action) => {
  return {
    ...state,
    ui: {
      modal: false,
    },
    newIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    },
    editIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    }
  }
}

const openResolveModalHandler = (state, action) => {
  return {
    ...state,
    ui: {
      resolve: true
    },
    editIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    }
  }
}

const closeResolveModalHandler = (state, action) => {
  return {
    ...state,
    ui: {
      resolve: false
    },
    editIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    }
  }
}

const openReopenModalHandler = (state, action) => {
  return {
    ...state,
    ui: {
      reopen: true
    },
    editIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    }
  }
}

const closeReopenModalHandler = (state, action) => {
  return {
    ...state,
    ui: {
      reopen: false
    },
    editIssue: {
      loading: false,
      error: false,
      saved: false,
      data: {}
    }
  }
}

const loadIssuesHandler = (state, action) => {
  return {
    ...state,
    list: {
      data: action.payload || state.list.data || [ ],
      loading: action.loading,
      error: action.error
    }
  }
}

const loadIssueHandler = (state, action) => {
  return {
    ...state,
    issue : {
      data: action.payload,
      loading: action.loading,
      error: action.error
    }
  }
}

const updateIssueHandler = (state, action) => {
  return {
    ...state,
    editIssue: {
      error: action.error,
      loading: action.loading,
      saved : !action.loading && !action.error && action.payload ? true : false,
      data: action.payload
    }
  }
}

const createIssueHandler = (state, action) => {
  return {
    ...state,
    newIssue: {
      error: action.error || false,
      loading: action.loading || false,
      saved : !action.loading && !action.error && action.payload ? true : false,
      data: action.payload
    }
  }
}

const deleteIssueHandler = (state, action) => {
  if (!action.loading) {
    action.sideEffect((store) => {
      if (action.error) {
        const error = get(action.payload, 'response.body.details', 'Failed to delete issue')
        store.dispatch(NotificationToastActions.notificationToastError(error))
      } else {
        store.dispatch(NotificationToastActions.notificationToastSuccess('Issue deleted'))
      }
    })
  }

  return {
    ...state,
    issue: {
      ...state.issue,
      loading: action.loading,
      error: action.error,
    }
  }
}

const modalHandler = (state, _action, uiOverrides) => ({...state, ui: {...state.ui, ...uiOverrides} })

const ACTION_HANDLERS = {
  [OPEN_ISSUE_MODAL] : openIssueModalHandler,
  [CLOSE_ISSUE_MODAL] : closeIssueModalHandler,
  [OPEN_RESOLVE_MODAL] : openResolveModalHandler,
  [CLOSE_RESOLVE_MODAL] : closeResolveModalHandler,
  [OPEN_REOPEN_MODAL] : openReopenModalHandler,
  [CLOSE_REOPEN_MODAL] : closeReopenModalHandler,
  [TOGGLE_MORE_ACTIONS]: (state, action) => ({...state, ui: {...state.ui, moreActions: !state.ui.moreActions}}),
  [OPEN_REFUND_MODAL]: partialRight(modalHandler, { refund: true, moreActions: false }),
  [CLOSE_REFUND_MODAL]: partialRight(modalHandler, { refund: false }),
  [OPEN_POINTS_MODAL]: partialRight(modalHandler, { points: true, moreActions: false }),
  [CLOSE_POINTS_MODAL]: partialRight(modalHandler, { points: false }),
  [OPEN_FUNDS_MODAL]: partialRight(modalHandler, { funds: true, moreActions: false }),
  [CLOSE_FUNDS_MODAL]: partialRight(modalHandler, { funds: false }),
  [OPEN_PROMO_CODE_MODAL]: partialRight(modalHandler, { promoCode: true, moreActions: false }),
  [CLOSE_PROMO_CODE_MODAL]: partialRight(modalHandler, { promoCode: false }),
  [OPEN_AD_HOC_REFIRE_MODAL]: partialRight(modalHandler, { adHocRefire: true, moreActions: false }),
  [CLOSE_AD_HOC_REFIRE_MODAL]: partialRight(modalHandler, { adHocRefire: false }),
  [LOAD_ISSUES]: loadIssuesHandler,
  [LOAD_ISSUE]: loadIssueHandler,
  [CREATE_ISSUE]: createIssueHandler,
  [UPDATE_ISSUE]: updateIssueHandler,
  [RESOLVE_ISSUE]: updateIssueHandler,
  [REOPEN_ISSUE]: updateIssueHandler,
  [CLOSE_ISSUE]: updateIssueHandler,
  [DELETE_ISSUE]: deleteIssueHandler,
}

export default function issuesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
