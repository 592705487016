import { handleActions } from 'redux-actions'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import ActionTypes from '../constants/ActionTypes'
import { toErrorString } from './util'

const initialState = {
  search: {
    loading: false,
    error: false,
    query: null,
    page: 0,
    meta: {},
    data: []
  },
  team: {
    loading: false,
    error: false,
    data: {},
    operation: null,
    members: {
      loading: false,
      error: false,
      page: 0,
      meta: {},
      data: [],
      id: null
    }
  },
}

const updateTeamInList = (state, id, updated) => {
  const stateList = get(state, 'search.data', [])
  const index = findIndex(stateList, i => i.id === id)
  if (index === -1) {
    return stateList
  }
  if (!updated) {
    return [...stateList.slice(0, index), ...stateList.slice(index + 1)]
  }
  return [...stateList.slice(0, index), updated, ...stateList.slice(index + 1)]
}

const reducer = handleActions({
  [ActionTypes.UPDATE_TEAMS_QUERY]: (state, action) => ({
    ...state,
    search: {
      ...state.search,
      query: action.payload,
      page: initialState.search.page,
      data: initialState.search.data,
      meta: initialState.search.meta
    }
  }),

  [ActionTypes.UPDATE_TEAMS_PAGE]: (state, action) => ({
    ...state,
    search: {
      ...state.search,
      page: action.payload,
      data: initialState.search.data,
      meta: initialState.search.meta
    }
  }),

  [ActionTypes.SEARCH_TEAMS_REQUEST]: (state, action) => ({
    ...state,
    search: {
      ...state.search,
      loading: true,
      error: false
    }
  }),
  [ActionTypes.SEARCH_TEAMS_SUCCESS]: (state, action) => ({
    ...state,
    search: {
      ...state.search,
      loading: false,
      error: false,
      data: get(action, 'payload.list', initialState.search.data),
      meta: get(action, 'payload.meta', initialState.search.meta)
    }
  }),
  [ActionTypes.SEARCH_TEAMS_ERROR]: (state, action) => ({
    ...state,
    search: {
      ...state.search,
      loading: false,
      error: toErrorString(state, action),
      data: initialState.search.data,
      meta: initialState.search.meta
    }
  }),

  [ActionTypes.OPEN_EDIT_TEAM]: (state, action) => ({
    ...state,
    team: {
      loading: false,
      error: false,
      operation: action.payload ? 'edit' : initialState.team.operation,
      data: action.payload ? {...action.payload} : initialState.team.data
    }
  }),
  [ActionTypes.OPEN_DELETE_TEAM]: (state, action) => ({
    ...state,
    team: {
      loading: false,
      error: false,
      operation: action.payload ? 'delete' : initialState.team.operation,
      data: action.payload ? {...action.payload} : initialState.team.data
    }
  }),
  [ActionTypes.OPEN_MEMBERS_TEAM]: (state, action) => ({
    ...state,
    team: {
      loading: false,
      error: false,
      operation: action.payload ? 'members' : initialState.team.operation,
      data: action.payload ? {...action.payload} : initialState.team.data,
      members: initialState.team.members
    }
  }),

  [ActionTypes.LOAD_TEAM_REQUEST]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: true,
      error: false
    }
  }),
  [ActionTypes.LOAD_TEAM_SUCCESS]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: false,
      error: false,
      data: action.payload
    }
  }),
  [ActionTypes.LOAD_TEAM_ERROR]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: false,
      error: toErrorString(state, action)
    }
  }),

  [ActionTypes.SAVE_TEAM_REQUEST]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: true,
      error: false
    }
  }),
  [ActionTypes.SAVE_TEAM_SUCCESS]: (state, action) => ({
    ...state,
    team: initialState.team,
    search: {
      ...state.search,
      data: updateTeamInList(state, action.payload.id, action.payload)
    }
  }),
  [ActionTypes.SAVE_TEAM_ERROR]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: false,
      error: toErrorString(state, action)
    }
  }),

  [ActionTypes.DELETE_TEAM_REQUEST]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: true,
      error: false
    }
  }),
  [ActionTypes.DELETE_TEAM_SUCCESS]: (state, action) => ({
    ...state,
    team: initialState.team,
    search: {
      ...state.search,
      data: updateTeamInList(state, state.team.data.id)
    }
  }),
  [ActionTypes.DELETE_TEAM_ERROR]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      loading: false,
      error: toErrorString(state, action)
    }
  }),

  [ActionTypes.UPDATE_TEAM_ADDRESS]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      data: {
        ...state.team.data,
        delivery_address: { address: action.payload }
      }
    }
  }),

  [ActionTypes.UPDATE_MEMBERS_PAGE]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      members: {
        ...state.team.members,
        page: action.payload,
        data: initialState.team.members.data,
        meta: initialState.team.members.meta
      }
    }
  }),

  [ActionTypes.LOAD_TEAM_MEMBERS_REQUEST]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      members: {
        ...state.team.members,
        loading: true,
        error: false
      }
    }
  }),
  [ActionTypes.LOAD_TEAM_MEMBERS_SUCCESS]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      members: {
        ...state.team.members,
        loading: false,
        error: false,
        data: get(action, 'payload.list', initialState.search.data),
        meta: get(action, 'payload.meta', initialState.search.meta)
      }
    }
  }),
  [ActionTypes.LOAD_TEAM_MEMBERS_ERROR]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      members: {
        ...state.team.members,
        loading: false,
        error: toErrorString(state, action)
      }
    }
  }),

  [ActionTypes.RESET_TEAM_MEMBER_REQUEST]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      members: {
        ...state.team.members,
        loading: true,
        error: false,
        id: action.payload.id
      }
    }
  }),
  [ActionTypes.RESET_TEAM_MEMBER_SUCCESS]: (state, action) => {
    // Find and replace the saved one in place in the listing data
    const stateList = get(state, 'team.members.data', [])
    const index = findIndex(stateList, i => i.id === state.team.members.id)
    const list = index !== -1 ? [...stateList.slice(0, index), ...stateList.slice(index + 1)] : stateList

    const updatedData = {
      ...state.team.data,
      member_count: state.team.data.member_count - 1,
    }

    return {
      ...state,
      search: {
        ...state.search,
        data: updateTeamInList(state, state.team.data.id, {
          ...state.team.data,
          ...updatedData
        })
      },
      team: {
        ...state.team,
        data: updatedData,
        members: {
          ...state.team.members,
          loading: false,
          error: false,
          data: list
        }
      }
    }
  },
  [ActionTypes.RESET_TEAM_MEMBER_ERROR]: (state, action) => ({
    ...state,
    team: {
      ...state.team,
      members: {
        ...state.team.members,
        loading: false,
        error: toErrorString(state, action)
      }
    }
  }),
}, initialState)

export default reducer