import { get } from 'lodash'

export default (store) => ({
  layout: { classNames: ['issue-search-layout'] },
  path : '/issues',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    import(
      /* webpackChunkName: 'IssueSearch' */
      './components/IssueSearch'
    ).then(module => {
      cb(null, module.default)
    })
  },
  stateToParams: {
    filter: {
      stateKey: 'issueSearchReducer.filter',
      type: 'object',
      options: {
        shouldPush: true,
        serialize: (currentItemState) => (JSON.stringify(currentItemState)),
        parse: (urlPathReturned) => (JSON.parse(decodeURIComponent(urlPathReturned)))
      }
    },
    page: {
      stateKey: 'issueSearchReducer.page',
      type: 'number',
      options: { 
        shouldPush: true 
      } 
    },
    sort: {
      stateKey: 'issueSearchReducer.sort',
      type: 'object',
      options: {
        shouldPush: true,
        serialize: (currentItemState) => (JSON.stringify(currentItemState)),
        parse: (urlPathReturned) => (JSON.parse(decodeURIComponent(urlPathReturned)))
      }
    },
  },
  paramsToState: (state, location) => ({
    ...state,
    issueSearchReducer: {
      ...state.issueSearchReducer,
      filter: get(location, 'query.issueSearchReducer.filter', {}),
      page: get(location, 'query.issueSearchReducer.page'),
      sort: get(location, 'query.issueSearchReducer.sort', { field: 'created_at', order: 'asc' }),
    }
  })
})