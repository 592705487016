import React, { useEffect, useState } from "react"
import { errorToString } from "../../../reducers/util"
import HttpClient from '../../../api/HttpClient'
import Loading from '../../../components/Loading'
import Modal from '../../../components/Modal/Modal'

const loadTeamPromoCodes = (teamId) => {
  return HttpClient.get(`/api/admin/teams/${teamId}/team_promo_codes`)
}

const removePromoCode = (promoCodeId, teamId) => {
  return HttpClient.del(`/api/admin/teams/${teamId}/team_promo_codes/${promoCodeId}`)
}

const addPromoCodeToTeam = (params, teamId) => {
  return HttpClient.post(`/api/admin/teams/${teamId}/team_promo_codes`, params)
}


const TeamPromoCodesModal = ({ teamId, onClose, hasTeamManagerRole }) => {

  const [promoCodes, setPromoCodes] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [name, setName] = useState("")
  const [promoCode, setPromoCode] = useState("")
  const [addPromoCodeLoading, setAddPromoCodeLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    loadTeamPromoCodes(teamId)
      .then((response) => {
        setLoading(false)
        setError()
        setPromoCodes(response.list)
        setName(response.name)
      })
      .catch((error) => {
        setError(errorToString(error))
        setLoading(false)
      })
  }, [teamId])

  const renderFooter = () => {
    return (
      <div></div>
    )
  }

  const removeCode = (promoCodeId) => {
    setLoading(true)
    removePromoCode(promoCodeId, teamId)
      .then((removedCode) => {
        const codes = promoCodes.filter(pc => pc.id !== removedCode.id)
        setPromoCodes(codes)
        setLoading(false)
        setError()
      })
      .catch((error) => {
        setLoading(false)
        setError(errorToString(error))
      })
  }

  const errorDisplay = (
    <div className="alert alert-danger" role="alert">
      {error}
    </div>
  )

  const addPromoCode = () => {
    setAddPromoCodeLoading(true)
    const params = {
      promo_code: promoCode
    }
    addPromoCodeToTeam(params, teamId)
      .then((addedCode) => {
        setError()
        setAddPromoCodeLoading(false)
        setPromoCode("")
        setPromoCodes(promoCodes.concat(addedCode))
      })
      .catch((error) => {
        setAddPromoCodeLoading(false)
        setError(errorToString(error))
      })
  }

  const renderResults = () => {
    return promoCodes.map(promoCode => {
      const { id, code } = promoCode
      const deleteButton = hasTeamManagerRole ? <button type='button' className='btn btn-danger btn-sm' onClick={() => removeCode(id)}>Remove Code</button> : null

      return (
        <tr key={id}>
          <td>{code}</td>
          <td>{deleteButton}</td>
        </tr>
      )
    })
  }

  return (<Modal
    isOpen={true}
    title={`Promo Codes: ${name}`}
    onClose={() => onClose()}
    footer={renderFooter()}
    modalId='promo-codes'>

    {error ? errorDisplay : null}

    <div className="input-group mb-3">
      <input disabled={addPromoCodeLoading} value={promoCode} onChange={e => setPromoCode(e.target.value)} type="text" className="form-control" placeholder="Promo Code" />
      <div className="input-group-append">
        <button disabled={addPromoCodeLoading} type="button" className="btn btn-primary" onClick={addPromoCode}>Add Promo Code</button>
      </div>
    </div>

    <div className="mt-2">
      <p><strong>{promoCodes.length}</strong> codes found</p>
      {loading ? <Loading /> : (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Code</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {renderResults()}
        </tbody>
      </table>
      )}
    </div>
  </Modal>)
}

export default (TeamPromoCodesModal)