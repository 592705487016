import { isObject, get, toString } from 'lodash'

export const SET_ALERT = 'SET_ALERT'

const initialState = {
  alert: null,
  alertType: 'danger',
  dismissable: true,
}

const displayString = val => {
  if (isObject(val)) {
    return JSON.stringify(val)
  }
  return toString(val)
}

export function displayAlert(alert, alertType = 'danger', dismissable = true) {
  if (alert && isObject(alert)) {
    if (get(alert, 'response.type') === 'application/json') {
      // Try to pull off a sane response if it's an HTTP error response
      let alertString = get(alert, 'response.body.description')
      if (!alertString) {
        const responseBody = get(alert, 'response.body')
        if (responseBody) {
          alertString = displayString(responseBody)
        }
      }
      else {
        const details = get(alert, 'response.body.details')
        if (details) {
          alertString = `${alertString}: ${displayString(details)}`
        }
      }

      alert = alertString || JSON.stringify(alert)
    }
    else {
      alert = 'Unknown Error'
    }
  }

  return {
    type: SET_ALERT,
    alert: alert,
    alertType: alertType,
    dismissable: dismissable
  }
}

export function clearAlert() {
  return {
    type: SET_ALERT,
    alert: null
  }
}

const ACTION_HANDLERS = {
  [SET_ALERT]: (state, action) => ({
    ...state,
    alert: action.alert,
    alertType: action.alertType || 'danger',
    dismissable: action.dismissable
  })
}

export default function alertsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
