import get from 'lodash/get'
import isArray from 'lodash/isArray'
import startCase from 'lodash/startCase'

export const handleRequest = (state, action) => ({
  ...state,
  loading: true
});

export const handleError = (state, action) => ({
  ...state, loading: false, error: action.error
});

export const handleErrorWithDetails = (state, action) => {
  const errors = get(action, 'payload.response.body.details');

  return {
    ...state,
    loading: false,
    error: action.error,
    errors
  };
}

export const toErrorString = (state, action) => {
  const details = get(action, 'payload.response.body.details')
  if (details) {
    return Object.entries(details).map(e => {
      const res = isArray(e[1]) ? e[1] : [e[1]]
      return `${startCase(e[0])}: ${res.join(', ')}`
    }).join(', ')
  }

  return get(action, 'payload.response.body.description', 'Unknown Error')
}

export const errorToString = (error) => {
  const details = get(error, 'response.body.details')
  if (details) {
    return Object.entries(details).map(e => {
      const res = isArray(e[1]) ? e[1] : [e[1]]
      return `${startCase(e[0])}: ${res.join(', ')}`
    }).join(', ')
  }

  return get(error, 'response.body.description', 'Unknown Error')
}
