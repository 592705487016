import React from 'react'
import loadingImg from '../loading.svg'

const Loading = () => (
  <div style={{width: '100%', textAlign: 'center'}}>
    <img src={loadingImg} alt='Loading' className='loading-img'/>
  </div>
)

export default Loading
