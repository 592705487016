import client from './client2'
import clientV1 from './client'
import { isEmpty, get } from 'lodash'
import { toQueryString } from '../util/stringUtils'

export default {
  index: (orderId) => {
    const issuesUrl = `/api/admin/orders/${orderId}/issues`
    return client
      .get(issuesUrl)
  },

  load_issue: (issueId) => {
    const issueUrl = `/api/admin/issues/${issueId}`
    return client
      .get(issueUrl)
  },

  createIssue: (orderId, data) => {
    return client
      .post(`/api/admin/orders/${orderId}/issues`)
      .send(data)
  },

  updateIssue: (issueId, data) => {
    return client
      .put(`/api/admin/issues/${issueId}`)
      .send(data)
  },

  resolveIssue: (issueId, data) => {
    return client
      .patch(`/api/admin/issues/${issueId}/resolve`)
      .send(data)
  },

  reopenIssue: (issueId) => {
    return client
      .patch(`/api/admin/issues/${issueId}/reopen`)
      .send({})
  },

  closeIssue: (issueId) => {
    return client
      .patch(`/api/admin/issues/${issueId}/close`)
      .send({})
  },

  search: (filter, sort, offset = 0, limit = 20) => {
    // Clear off any filters that are empty
    const filterString = toQueryString(filter)
    const filterParam = isEmpty(filterString) ? '' : `&${filterString}`

    const url = `/api/admin/issues?offset=${offset}&limit=${limit}${filterParam}&sort_field=${get(sort, 'field', '')}&sort_order=${get(sort, 'order', '')}`
    return client.get(url)
  },

  getIssueTypes: (withDeleted = true) => {
    return clientV1
      .get(`/api/admin/issue_types?with_deleted=${withDeleted}`)
  },

  destroyIssue: (id) => {
    return client.delete(`/api/admin/issues/${id}`)
  }
}
