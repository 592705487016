import { handleActions } from 'redux-actions';
import { get, remove } from 'lodash';

import ActionTypes from 'constants/ActionTypes';

const initialState = {
  loading: false,
  daysOfWeek: [],
  byStartTime: {},
  byId: {},
  selectedRanges: [],
  error: null
};

const handleRequest = (state, action) => ({ ...state, loading: true });

const handleSuccess = (state, action) => {
  const data = get(action, 'payload.data', []);

  const byStartTime = {};
  data.forEach(dayOfWeek => {
    dayOfWeek.schedule_ranges.forEach(scheduleRange => {
      const rangeStartTime = scheduleRange.range_start_time;

      if (!byStartTime[rangeStartTime]) {
        byStartTime[rangeStartTime] = []
      }

      byStartTime[rangeStartTime].push({
        id: scheduleRange.id,
        dayOfWeek: dayOfWeek.day_of_week,
        dayOfWeekDisplayAlt: dayOfWeek.day_of_week_display_alt,
        rangeDisplay: scheduleRange.range_display,
        availableSlots: scheduleRange.available_slots,
        maxAvailableSlots: scheduleRange.max_available_slots,
        active: scheduleRange.active,
        lineItemCount: scheduleRange.line_item_count
      });
    });
  });

  const byId = {};
  data.forEach(dayOfWeek => {
    dayOfWeek.schedule_ranges.forEach(scheduleRange => {
      byId[scheduleRange.id] = { ...scheduleRange, selected: false };
    });
  });

  const daysOfWeek = data.map(dayOfWeek => {
    return {
      id: dayOfWeek.id,
      dayOfWeek: dayOfWeek.day_of_week,
      display: dayOfWeek.day_of_week_display,
      displayAlt: dayOfWeek.day_of_week_display_alt
    };
  });

  return {
    ...state,
    daysOfWeek,
    byStartTime,
    byId,
    loading: false
  };
};

const handleError = (state, action) => ({
  ...state, error: action.error, loading: false
});

export default handleActions({
  [ActionTypes.SCHEDULE_RANGES_LOAD_REQUEST]: handleRequest,
  [ActionTypes.SCHEDULE_RANGES_LOAD_SUCCESS]: handleSuccess,
  [ActionTypes.SCHEDULE_RANGES_LOAD_ERROR]: handleError,

  [ActionTypes.SCHEDULE_RANGE_TOGGLE_SELECT]: (state, action) => {
    const selected = action.payload.selected;
    const id = action.payload.id;

    let selectedRanges = state.selectedRanges;
    if (state.byId[id].selected !== selected) {
      if (selected) {
        selectedRanges.push(state.byId[id])
      } else {
        remove(selectedRanges, range => range.id === id)
      }
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [id]: { ...state.byId[id], selected: selected }
      },
      selectedRanges
    };
  },

  [ActionTypes.SCHEDULE_RANGES_CLEAR_SELECTED]: (state, action) => {
    const deselectedScheduleRanges = {};

    state.selectedRanges.forEach(selectedRange => {
      deselectedScheduleRanges[selectedRange.id] = state.byId[selectedRange.id]
      deselectedScheduleRanges[selectedRange.id].selected = false;
    });

    return { ...state, selectedRanges: [], byId: { ...state.byId, ...deselectedScheduleRanges } };
  },

  [ActionTypes.SCHEDULE_RANGES_UPDATE_REQUEST]: handleRequest,
  [ActionTypes.SCHEDULE_RANGES_UPDATE_SUCCESS]: (state, action) => {
    const data = get(action, 'payload.data', []);

    const updates = { ...state };

    data.forEach(updatedScheduleRange => {
      const rangeStartTime = updatedScheduleRange.range_start_time;
      const scheduleRangesForStartTime = updates.byStartTime[rangeStartTime];
      const indexOfScheduleRange =
        scheduleRangesForStartTime.findIndex(i => i.id === updatedScheduleRange.id)

      scheduleRangesForStartTime[indexOfScheduleRange] = {
        ...scheduleRangesForStartTime[indexOfScheduleRange],
        id: updatedScheduleRange.id,
        availableSlots: updatedScheduleRange.available_slots,
        maxAvailableSlots: updatedScheduleRange.max_available_slots,
        lineItemCount: updatedScheduleRange.line_item_count
      };

      updates.byStartTime[rangeStartTime] = scheduleRangesForStartTime;
    });

    data.forEach(updatedScheduleRange => {
      updates.byId[updatedScheduleRange.id] = { ...updatedScheduleRange, selected: false };
    });

    return { ...updates, selectedRanges: [], loading: false };
  },
  [ActionTypes.SCHEDULE_RANGES_UPDATE_ERROR]: handleError,
}, initialState)
