import { handleActions } from 'redux-actions';
import { get, isEmpty, omitBy } from 'lodash';

import ActionTypes from '../constants/ActionTypes';
import { handleRequest, handleError, handleErrorWithDetails } from './util.js';

const initialState = {
  loading: false,
  error: false,
  errors: {},
  searchResults: {
    loading: false
  },
  suggestedItemsSearchResults: {
    loading: false
  },
  linkItemSearchResults: {
    loading: false
  },
  currentItem: {
    name: '',
    description: '',
    accounting_category: '',
    tax_rate_id: '',
    cook_time: '',
    price: '',
    addon: false,
    tags: [],
    station_routing: [],
    picture_url: '',
    thumbnail_url: '',
    time_range: { start_at: null, end_at: null }
  },
  currentItemSuggestedItemsDetails: [],
  removeItem: {
    modalIsOpen: false,
    itemToRemove: {
      suggestedItemOnItems: [],
    },
    removedItemsToFilter: [],
  },
};

const handleMoveSuccess = (state, action) => {
  const { modifierGroupId } = action.meta;
  const updatedModifiers = action.payload;

  const modifierGroupIndex =
    state.currentItem.modifier_groups.findIndex(mg => mg.id === modifierGroupId);
  const updateModifierGroups = [ ...state.currentItem.modifier_groups ];
  updateModifierGroups[modifierGroupIndex] = {
    ...state.currentItem.modifier_groups[modifierGroupIndex],
    modifiers: updatedModifiers
  };

  return {
    ...state,
    loading: false,
    currentItem: {
      ...state.currentItem,
      modifier_groups: updateModifierGroups
    }
  };
};

const handleSuccess = (state, action) => {
  const item = action.payload;

  return {
    ...state,
    loading: false,
    currentItem: item
  };
}

const handleItemRequest = (state, action) => {
  const itemId = action.meta.itemId

  if (!isEmpty(state.searchResults.list)) {
    const itemIndex = state.searchResults.list.findIndex((result) => result.id === itemId)
    const item = {
      ...state.searchResults.list[itemIndex],
      loading: true
    }

    let items = [ ...state.searchResults.list ]
    items[itemIndex] = item

    return {
      ...state,
      searchResults: {
        ...state.searchResults,
        list: items
      }
    }
  }

  return state
}

const handleModifierUpdate = (state, action) => {
  const { modifierGroupId } = action.meta;
  const updatedModifier = action.payload;

  const modifierGroupIndex =
    state.currentItem.modifier_groups.findIndex(mg => mg.id === modifierGroupId);
  const modifiers = state.currentItem.modifier_groups[modifierGroupIndex].modifiers;

  const modifierIndex = modifiers.findIndex(result => result.id === updatedModifier.id);
  const updatedModifiers = [ ...modifiers ];
  updatedModifiers[modifierIndex] = updatedModifier;

  const updatedModifierGroups = [ ...state.currentItem.modifier_groups ];
  updatedModifierGroups[modifierGroupIndex] = {
    ...state.currentItem.modifier_groups[modifierGroupIndex],
    modifiers: updatedModifiers
  };

  return {
    ...state,
    loading: false,
    currentItem: {
      ...state.currentItem,
      modifier_groups: updatedModifierGroups
    }
  };
}

const handleItemUpdate = (state, action) => {
  const item = action.payload;

  let updatedItems = [];
  if (!isEmpty(state.searchResults.list)) {
    const itemIndex = state.searchResults.list.findIndex((result) => result.id === item.id);
    updatedItems = [ ...state.searchResults.list ];
    updatedItems[itemIndex] = item;
  }

  return {
    ...state,
    loading: false,
    searchResults: {
      ...state.searchResults,
      list: updatedItems
    },
    currentItem: (isEmpty(state.currentItem) ? {} : item)
  }
}

export default handleActions({
  [ActionTypes.SUGGESTED_ITEMS_SEARCH_REQUEST]: (state, action) => ({
    ...state, suggestedItemsSearchResults: { ...state.suggestedItemsSearchResults, loading: true }
  }),
  [ActionTypes.SUGGESTED_ITEMS_SEARCH_SUCCESS]: (state, action) => {
    const suggestedItemsSearchResults = action.payload;

    return {
      ...state,
      suggestedItemsSearchResults: { ...suggestedItemsSearchResults, loading: false }
    };
  },
  [ActionTypes.SUGGESTED_ITEMS_SEARCH_ERROR]: (state, action) => ({
    ...state,
    suggestedItemsSearchResults: { ...state.suggestedItemsSearchResults, loading: true },
    error: action.error
  }),
  [ActionTypes.ITEMS_SEARCH_REQUEST]: (state, action) => ({
    ...state, searchResults: { ...state.searchResults, loading: true }
  }),
  [ActionTypes.ITEMS_SEARCH_SUCCESS]: (state, action) => {
    const searchResults = action.payload;
    const searchResultsList = get(searchResults, 'list', []);
    const removedItemsToFilter = get(state, 'removeItem.removedItemsToFilter', []);

    const searchResultsWithoutRemovedItems =
      searchResultsList.filter(item => !removedItemsToFilter.includes(item.id));

    return {
      ...state,
      searchResults: { ...searchResults, list: searchResultsWithoutRemovedItems, loading: false }
    };
  },
  [ActionTypes.ITEMS_SEARCH_ERROR]: (state, action) => ({
    ...state,
    searchResults: { ...state.searchResults, loading: false },
    error: action.error
  }),


  // LINK ITEMS SEARCH
  [ActionTypes.LINK_ITEMS_SEARCH_REQUEST]: (state, action) => ({
    ...state, linkItemSearchResults: { ...state.linkItemSearchResults, loading: true }
  }),
  [ActionTypes.LINK_ITEMS_SEARCH_SUCCESS]: (state, action) => {
    const searchResults = action.payload;
    return {
      ...state,
      linkItemSearchResults: { ...searchResults, loading: false }
    };
  },
  [ActionTypes.LINK_ITEMS_SEARCH_ERROR]: (state, action) => ({
    ...state,
    linkItemSearchResults: { ...state.linkItemSearchResults, loading: false },
    error: action.error
  }),



  [ActionTypes.ITEM_LOAD_REQUEST]: handleRequest,
  [ActionTypes.ITEM_LOAD_SUCCESS]: handleSuccess,
  [ActionTypes.ITEM_LOAD_ERROR]: handleError,
  [ActionTypes.ITEM_CREATE_REQUEST]: handleRequest,
  [ActionTypes.ITEM_CREATE_SUCCESS]: handleSuccess,
  [ActionTypes.ITEM_CREATE_ERROR]: handleErrorWithDetails,
  // TODO: Remove item from state when delete comes back
  [ActionTypes.ITEM_DELETE_REQUEST]: (state, action) => ({ ...state, loading: true }),
  [ActionTypes.ITEM_DELETE_SUCCESS]: (state, action) => {
    const removedItemId = get(action, 'payload.id');
    const removedItemsToFilter = get(state, 'removeItem.removedItemsToFilter', []);

    return {
      ...state,
      currentItem: { ...initialState.currentItem },
      removeItem: { ...state.removeItem, removedItemsToFilter: removedItemsToFilter.concat(removedItemId) }
    };
  },
  [ActionTypes.ITEM_DELETE_ERROR]: (state, action) => handleError,
  [ActionTypes.ITEM_UPDATE_REQUEST]: handleItemRequest,
  [ActionTypes.ITEM_UPDATE_SUCCESS]: handleItemUpdate,
  [ActionTypes.ITEM_RESTORE_SUCCESS]: handleItemUpdate,
  [ActionTypes.ITEM_UPDATE_ERROR]: handleErrorWithDetails,
  [ActionTypes.ITEM_LINKED_TO_REQUEST]: handleRequest,
  [ActionTypes.ITEM_LINKED_TO_SUCCESS]: (state, action) => {
    const suggestedItemOnItems = get(action.payload, 'suggested_item_on', []);
    const modifierOnItems = get(action.payload, 'modifier_on', []);

    let removeItem = { ...state.removeItem }
    const itemToRemove = get(state, 'removeItem.itemToRemove');
    if (!isEmpty(itemToRemove)) {
      removeItem = { ...removeItem, itemToRemove: { ...itemToRemove, suggestedItemOnItems, modifierOnItems } }
    }

    return {
      ...state,
      loading: false,
      currentItem: {
        ...state.currentItem,
        suggestedItemOnItems,
        modifierOnItems
      },
      removeItem
    };
  },
  [ActionTypes.ITEM_LINKED_TO_ERROR]: handleError,
  [ActionTypes.ITEM_LOAD_SUGGESTED_ITEMS_REQUEST]: handleRequest,
  [ActionTypes.ITEM_LOAD_SUGGESTED_ITEMS_SUCCESS]: (state, action) => {
    const currentItemSuggestedItemsDetails = action.payload;

    return {
      ...state,
      loading: false,
      currentItemSuggestedItemsDetails
    }
  },
  [ActionTypes.ITEM_LOAD_SUGGESTED_ITEMS_ERROR]: handleError,
  [ActionTypes.ITEM_CLEAR_CURRENT]: (state, action) => ({
    ...state, currentItem: { ...initialState.currentItem }, errors: {}
  }),

  [ActionTypes.ITEM_CURRENT_CHANGE]: (state, action) => {
    const changeKeys = Object.keys(action.payload.change);

    return {
      ...state,
      currentItem: {
        ...state.currentItem,
        ...action.payload.change
      },
      errors: omitBy(state.errors, (v, k) => changeKeys.includes(k))
    }
  },

  [ActionTypes.ITEM_CLEAR_SEARCH_RESULTS]: (state, action) => ({
    ...state, searchResults: { ...initialState.searchResults }
  }),
  [ActionTypes.ITEM_CLEAR_SUGGESTED_ITEMS_SEARCH_RESULTS]: (state, action) => ({
    ...state, suggestedItemsSearchResults: { ...initialState.suggestedItemsSearchResults }
  }),
  [ActionTypes.ITEM_ENTER_REMOVE_STATE]: (state, action) => {
    const itemToRemove = get(action, 'payload.itemToRemove');

    return { ...state, removeItem: { itemToRemove, modalIsOpen: true } };
  },
  [ActionTypes.ITEM_EXIT_REMOVE_STATE]: (state, action) => ({
    ...state,
    removeItem: { ...initialState.removeItem, removedItemsToFilter: state.removeItem.removedItemsToFilter }
  }),
  [ActionTypes.MODIFIER_UPDATE_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_UPDATE_SUCCESS]: (state, action) => {
    const { modifierGroupId } = action.meta;
    const updatedModifier = action.payload;

    const modifierGroupIndex =
      state.currentItem.modifier_groups.findIndex(mg => mg.id === modifierGroupId);
    const modifiers = state.currentItem.modifier_groups[modifierGroupIndex].modifiers;

    const modifierIndex = modifiers.findIndex(result => result.id === updatedModifier.id);
    const updatedModifiers = [ ...modifiers ];
    updatedModifiers[modifierIndex] = updatedModifier;

    const updatedModifierGroups = [ ...state.currentItem.modifier_groups ];
    updatedModifierGroups[modifierGroupIndex] = {
      ...state.currentItem.modifier_groups[modifierGroupIndex],
      modifiers: updatedModifiers
    };

    return {
      ...state,
      loading: false,
      currentItem: {
        ...state.currentItem,
        modifier_groups: updatedModifierGroups
      }
    };
  },
  [ActionTypes.MODIFIER_UPDATE_ERROR]: handleError,
  [ActionTypes.MODIFIER_MOVE_UP_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_MOVE_UP_SUCCESS]: handleMoveSuccess,
  [ActionTypes.MODIFIER_MOVE_UP_ERROR]: handleError,
  [ActionTypes.MODIFIER_MOVE_DOWN_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_MOVE_DOWN_SUCCESS]: handleMoveSuccess,
  [ActionTypes.MODIFIER_MOVE_DOWN_ERROR]: handleError,

  [ActionTypes.MODIFIER_UNLINK_ITEM_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_UNLINK_ITEM_SUCCESS]: handleModifierUpdate,
  [ActionTypes.MODIFIER_UNLINK_ITEM_ERROR]: handleError,

  [ActionTypes.MODIFIER_LINK_ITEM_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_LINK_ITEM_SUCCESS]: handleModifierUpdate,
  [ActionTypes.MODIFIER_LINK_ITEM_ERROR]: handleError,

  [ActionTypes.MODIFIER_NEW]: (state, action) => {
    const modifierGroupId = action.payload.modifierGroupId;
    const newModifier = action.payload.modifier;

    const modifierGroupIndex =
      state.currentItem.modifier_groups.findIndex(mg => mg.id === modifierGroupId);
    const modifiers = state.currentItem.modifier_groups[modifierGroupIndex].modifiers;

    const updatedModifierGroups = [ ...state.currentItem.modifier_groups ];
    updatedModifierGroups[modifierGroupIndex] = {
      ...state.currentItem.modifier_groups[modifierGroupIndex],
      modifiers: modifiers.concat(newModifier)
    };

    return {
      ...state,
      loading: false,
      currentItem: {
        ...state.currentItem,
        modifier_groups: updatedModifierGroups
      }
    }
  },
  [ActionTypes.MODIFIER_CREATE_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_CREATE_SUCCESS]: (state, action) => {
    const { modifierGroupId } = action.meta;
    const newModifier = action.payload;

    const modifierGroupIndex =
      state.currentItem.modifier_groups.findIndex(mg => mg.id === modifierGroupId);
    const modifiers = state.currentItem.modifier_groups[modifierGroupIndex].modifiers;

    const modifierIndex = modifiers.findIndex(result => result.id === 'new');
    const updatedModifiers = [ ...modifiers ];
    updatedModifiers[modifierIndex] = newModifier;

    const updatedModifierGroups = [ ...state.currentItem.modifier_groups ];
    updatedModifierGroups[modifierGroupIndex] = {
      ...state.currentItem.modifier_groups[modifierGroupIndex],
      modifiers: updatedModifiers
    };

    return {
      ...state,
      loading: false,
      currentItem: {
        ...state.currentItem,
        modifier_groups: updatedModifierGroups
      }
    };
  },
  [ActionTypes.MODIFIER_CREATE_ERROR]: handleError,
  [ActionTypes.MODIFIER_GROUP_UPDATE_REQUEST]: handleRequest,
  [ActionTypes.MODIFIER_GROUP_UPDATE_SUCCESS]: (state, action) => {

    const updatedModifierGroup = action.payload
    const modifierGroupIndex = state.currentItem.modifier_groups.findIndex(mg => mg.id === action.meta.modifierGroupId)
    const updatedModifierGroups = [ ...state.currentItem.modifier_groups ]
    updatedModifierGroups[modifierGroupIndex] = updatedModifierGroup

    return {
      ...state,
      loading: false,
      currentItem: {
        ...state.currentItem,
        modifier_groups: updatedModifierGroups
      }

    }
  },
  [ActionTypes.MODIFIER_GROUP_UPDATE_ERROR]: handleError
}, initialState);
