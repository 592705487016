import client from './client'

export default {
  login: (token, cookie_domain = null) => {
    return client
      .post('/api/token')
      .send({
        google_access_token: token,
        cookie_domain
      })
  }
}
