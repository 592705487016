import { handleActions } from 'redux-actions';

import ActionTypes from '../constants/ActionTypes';

const initialState = {
  text: '',
  toastType: 'info'
};

const setTextAndType = (state, action) => {
  const { text, toastType } = action.payload;

  return {
    ...state,
    text,
    toastType
  }
};

export default handleActions({
  [ActionTypes.NOTIFICATION_TOAST_TRUCK_UPDATE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ERROR]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_FIX_ERRORS_ABOVE]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_GENERIC_ERROR]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_TRUCK_ACTIVE_STATE_TOGGLE_MESSAGE]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ITEM_TRUCK_ADD_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ITEM_TRUCK_REMOVE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ITEM_SUGGESTED_ITEM_ADD_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ITEM_SUGGESTED_ITEM_REMOVE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_MODIFIER_SAVE_CHANGES_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ITEM_UPDATE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_ITEM_DELETE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_CUSTOMER_ROLE_GRANT_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_CUSTOMER_ROLE_REVOKE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_COMPONENT_CREATE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_PLACE_SAVE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_SAVE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_ERROR]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_CREATE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_DROPOFF_LOCATION_ARCHIVE_SUCCESS]: setTextAndType,
  [ActionTypes.NOTIFICATION_TOAST_CLEAR_TEXT]: (state, action) => ({
      ...state, text: ''
  })
}, initialState);