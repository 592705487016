import { handleActions } from 'redux-actions';
import { get, partial } from 'lodash';

import ActionTypes from '../constants/ActionTypes';

const initialState = {
  loading: {
    move: false,
    activeStateToggle: false,
    truckList: false,
    currentTruck: false,
    currentTruckItems: false
  },
  error: false,
  errors: {},
  list: [],
  kitchenSlug: null,
  currentTruck: {
    name: '',
    description: '',
    picture_url: '',
    logo_url: ''
  },
  currentTruckItems: []
}

const handleRequest = (loadingOverrides, state, action) => ({
  ...state,
  loading: {
    ...state.loading,
    ...loadingOverrides
  },
  ...action.payload
});

const handleSuccess = (loadingOverrides, state, action) => {
  const trucks = action.payload;

  return {
    ...state,
    loading: {
      ...state.loading,
      ...loadingOverrides
    },
    list: trucks
  };
};

const handleError = (loadingOverrides, state, action) => ({
  ...state,
  loading: {
    ...state.loading,
    ...loadingOverrides
  },
  error: action.error
});

const handleTruckItemsResponse = (state, action) => {
  const currentTruckItems = action.payload.list;

  return {
    ...state,
    loading: {
      ...state.loading,
      currentTruckItems: false,
      move: false
    },
    currentTruckItems
  };
};

export const handleErrorWithDetails = (loadingOverrides, state, action) => {
  const errors = get(action, 'payload.response.body.details');

  return {
    ...state,
    loading: {
      ...state.loading,
      ...loadingOverrides
    },
    error: action.error,
    errors
  };
};

export default handleActions({
  [ActionTypes.TRUCKS_LOAD_REQUEST]: partial(handleRequest, { truckList: true }),
  [ActionTypes.TRUCKS_LOAD_SUCCESS]: partial(handleSuccess, { truckList: false }),
  [ActionTypes.TRUCKS_LOAD_ERROR]: partial(handleError, { truckList: false }),
  [ActionTypes.TRUCK_LOAD_REQUEST]: partial(handleRequest, { currentTruck: true }),
  [ActionTypes.TRUCK_LOAD_SUCCESS]: (state, action) => {
    const currentTruck = action.payload;

    return {
      ...state,
      loading: {
        ...state.loading,
        currentTruck: false
      },
      currentTruck
    };
  },
  [ActionTypes.TRUCK_LOAD_ERROR]: partial(handleError, { currentTruck: false }),
  [ActionTypes.TRUCK_ITEMS_LOAD_REQUEST]: partial(handleRequest, { currentTruckItems: true }),
  [ActionTypes.TRUCK_ITEMS_LOAD_SUCCESS]: handleTruckItemsResponse,
  [ActionTypes.TRUCK_ITEMS_LOAD_ERROR]: partial(handleError, { currentTruckItems: true }),
  [ActionTypes.TRUCK_MOVE_UP_REQUEST]: partial(handleRequest, { move: true, activeStateToggle: true }),
  [ActionTypes.TRUCK_MOVE_UP_SUCCESS]: partial(handleSuccess, { move: false, activeStateToggle: false }),
  [ActionTypes.TRUCK_MOVE_UP_ERROR]: partial(handleError, { move: false, activeStateToggle: false }),
  [ActionTypes.TRUCK_MOVE_DOWN_REQUEST]: partial(handleRequest, { move: true, activeStateToggle: true }),
  [ActionTypes.TRUCK_MOVE_DOWN_SUCCESS]: partial(handleSuccess, { move: false, activeStateToggle: false }),
  [ActionTypes.TRUCK_MOVE_DOWN_ERROR]: partial(handleError, { move: false, activeStateToggle: false }),
  [ActionTypes.TRUCK_ITEM_MOVE_UP_REQUEST]: partial(handleRequest, { move: true }),
  [ActionTypes.TRUCK_ITEM_MOVE_UP_SUCCESS]: handleTruckItemsResponse,
  [ActionTypes.TRUCK_ITEM_MOVE_UP_ERROR]: partial(handleError, { move: false }),
  [ActionTypes.TRUCK_ITEM_MOVE_DOWN_REQUEST]: partial(handleRequest, { move: true }),
  [ActionTypes.TRUCK_ITEM_MOVE_DOWN_SUCCESS]: handleTruckItemsResponse,
  [ActionTypes.TRUCK_ITEM_MOVE_DOWN_ERROR]: partial(handleError, { move: false }),
  [ActionTypes.TRUCK_UPDATE_REQUEST]: partial(handleRequest, { currentTruck: true }),
  [ActionTypes.TRUCK_UPDATE_SUCCESS]: (state, action) => {
    const updatedTruck = action.payload;

    const truckIndex = state.list.findIndex((result) => result.id === updatedTruck.id);
    const updatedTruckList = [ ...state.list ];
    updatedTruckList[truckIndex] = updatedTruck;

    return {
      ...state,
      loading: { ...state.loading, currentTruck: false },
      currentTruck: updatedTruck,
      list: updatedTruckList
    }
  },
  [ActionTypes.TRUCK_UPDATE_ERROR]: partial(handleErrorWithDetails, { currentTruck: false }),
  [ActionTypes.TRUCK_UNLOAD]: (state, action) => {
    return {
      ...state,
      loading: false,
      currentTruck: {},
      currentTruckItems: []
    }
  }
}, initialState);
