import React, { useState, useEffect } from 'react'
import HttpClient from '../../api/HttpClient'
import { get, isUndefined, isEmpty, findIndex } from 'lodash'
import { connect } from 'react-redux'
import SearchOnEnterBox from '../../components/SearchOnEnterBox'
import ReactPaginate from 'react-paginate'
import TeamInformationModal from './components/TeamInformationModal'
import TeamPromoCodesModal from './components/TeamPromoCodesModal'
import Loading from '../../components/Loading'
import TeamMembersModal from './components/TeamMembersModal'
import { errorToString } from '../../reducers/util'

const DEFAULT_PAGE_SIZE = 10

const searchTeams = (query, page = 0) => {
  const params = {
    limit: DEFAULT_PAGE_SIZE,
    offset: page * DEFAULT_PAGE_SIZE
  }
  if (query) {
    params.q = query
  }

  return HttpClient.get('/api/admin/teams', {}, params)
}

const renderFullWidth = (element) => {
  return (<tr><td colSpan='5'>{element}</td></tr>)
}


const Teams = ({ hasTeamManagerRole }) => {

  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(false)
  const [teamId, setTeamId] = useState()
  const [showModal, setShowModal] = useState(false)
  const [query, setQuery] = useState("")
  const [meta, setMeta] = useState()
  const [error, setError] = useState(false)
  const [showMembers, setShowMembers] = useState(false)
  const [teamName, setTeamName] = useState()
  const [showPromoCodes, setShowPromoCodes] = useState(false)

  useEffect(() => {
    search(query)
  }, [query])

  const openCreateTeam = () => {
    setShowModal(!showModal)
  }

  const pagination = isUndefined(meta) ? null : <ReactPaginate
    containerClassName="react-pagination"
    pageCount={Math.ceil(meta.total / meta.limit)}
    pageRangeDisplayed={5}
    marginPagesDisplayed={2}
    onPageChange={(data) => search(query, data.selected)}
    forcePage={meta.offset / meta.limit}
  />

  const search = (query, page = 0) => {
    setQuery(query)
    setLoading(true)
    searchTeams(query, page)
      .then((response) => {
        setLoading(false)
        setError(false)
        setTeams(response.list)
        setMeta(response.meta)
      })
      .catch((error => {
        setError(errorToString(error))
        setLoading(false)
      }))
  }

  const TeamsRow = ({ hasTeamManagerRole, team: { id, name, kitchen, delivery_address, member_count, promo_codes, tip_percentage, tax_exempt } }) => {

    const editTeamClicked = (teamId) => {
      setTeamId(teamId)
      setShowModal(true)
    }

    const showTeamMembers = (teamId, name) => {
      setShowMembers(true)
      setShowModal(false)
      setTeamId(teamId)
      setTeamName(name)
    }

    const showPromoCodes = (teamId) => {
      setTeamId(teamId)
      setShowPromoCodes(true)
    }

    const nameText = hasTeamManagerRole ? (<button className='btn btn-link btn-sm' onClick={() => { editTeamClicked(id) }}>{name}</button>) : name

    return (
      <tr key={id}>
        <td>{nameText}</td>
        <td>{get(kitchen, 'name', '')}</td>
        <td>{get(delivery_address, 'address', '')}</td>
        <td><button className='btn btn-link btn-sm' onClick={() => showTeamMembers(id, name)}>{member_count}</button></td>
        <td><button className='btn btn-link btn-sm' onClick={() => showPromoCodes(id)}>{promo_codes.length}</button></td>
        <td>{tip_percentage * 100}%</td>
        <td>{tax_exempt ? "Yes" : "No"}</td>
      </tr>
    )
  }

  const renderResults = (teams, hasTeamManagerRole, loading, error) => {
    if (error) {
      return renderFullWidth(error)
    }

    if (loading) {
      return renderFullWidth(<Loading />)
    }

    if (isEmpty(teams)) {
      return renderFullWidth('No matches were found')
    }

    return teams.map((team) => {
      return <TeamsRow key={team.id} team={team} hasTeamManagerRole={hasTeamManagerRole} />
    })


  }

  const closeModal = () => {
    setShowModal(false)
    setTeamId()
  }

  const closeMembersModal = () => {
    setShowMembers(false)
    setTeamId()
    setTeamName()
  }

  const closePromoCodesModal = () => {
    setShowPromoCodes(false)
    setTeamId()
    search(query)
  }

  const teamUpdated = (team) => {
    const index = findIndex(teams, i => i.id === team.id)
    if (index === -1) {
      return teams
    }
    const data = [...teams.slice(0, index), team, ...teams.slice(index + 1)]
    setTeams(data)
  }

  return (
    <div>
      {showPromoCodes ? <TeamPromoCodesModal teamId={teamId} onClose={closePromoCodesModal} hasTeamManagerRole={hasTeamManagerRole} /> : null}
      {showModal ? <TeamInformationModal teamId={teamId} onClose={() => closeModal()} teamUpdated={updatedTeam => teamUpdated(updatedTeam)} /> : null}
      {showMembers ? <TeamMembersModal onClose={() => closeMembersModal()} name={teamName} teamId={teamId} hasTeamManagerRole={hasTeamManagerRole} /> : null}
      <div className="row mt-4">
        <div className="col">
          <h2 className="dashhead-title">Team Search</h2>
        </div>
        {hasTeamManagerRole ?
          <button className="btn btn-primary btn-sm float-right" onClick={() => openCreateTeam()} >Create Team</button>
          : null}
      </div>

      <div className="row">
        <SearchOnEnterBox
          default={query}
          placeholder="Search Teams"
          onSearch={query => search(query)} />
      </div>

      <div className="row mt-2">
        {!isUndefined(meta) ? <p><strong>{meta.total}</strong> teams found</p> : ''}
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Kitchen</th>
              <th>Address</th>
              <th>Member Count</th>
              <th>Promo Codes</th>
              <th>Tip Percentage</th>
              <th>Tax Exempt</th>
            </tr>
          </thead>
          <tbody>
            {renderResults(teams, hasTeamManagerRole, loading, error)}
          </tbody>
        </table>
        {pagination}
      </div>
    </div>
  )
}


export default {
  path: '/teams',
  component: connect((state) => {
    const { user } = state
    return {
      hasTeamManagerRole: get(user, 'data.roles', []).indexOf('team_manager') > -1,
    }
  })(Teams)
}

