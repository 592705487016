import client from './client'

export default {
  getFrontContact: (id) => {
    return client.get(`/api/frontapp/contacts/${id}`)
  },

  getCustomer: (id) => {
    return client
      .get(`/api/admin/users/${id}`)
  },

  update: (userId, data) => {
    return client
      .patch(`/api/admin/users/${userId}`)
      .send(data);
  },

  searchCustomers: (search, offset = 0, limit = 20) => {
    const url = `/api/users?q=${search}&offset=${offset}&limit=${limit}`
    return client
      .get(url)
  },

  walletTransactions: (userId, offset = 0, limit = 25) => {
    return client
      .get(`/api/admin/users/${userId}/wallet_transactions?limit=${limit}&offset=${offset}`)
  },

  pointsTransactions: (userId, offset = 0, limit = 30) => {
    return client
      .get(`/api/admin/users/${userId}/points_transactions?limit=${limit}&offset=${offset}`)
  },

  givePoints: (userId, data) => {
    return client
      .post(`/api/admin/users/${userId}/points_transactions`)
      .send(data);
  },

  giveFunds: (userId, data) => {
    return client
      .post(`/api/admin/users/${userId}/wallet_transactions`)
      .send(data);
  },

  logout: (userId) => {
    return client
      .post(`/api/admin/users/${userId}/logout`)
      .send()
  },

  archive: (userId) => {
    return client
      .post(`/api/admin/users/${userId}/archive`)
      .send()
  }
}
