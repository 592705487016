export default (store) => ({
  layout: { fluid: true },
  path : '/kitchens/:id/orders',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    import(
      /* webpackChunkName: 'orders' */
      './components/OrdersView'
    ).then(module => {
      cb(null, module.default)
    })
  }
})
