export default (store) => ({
  path : '/drivers',
  /*  Async getComponent is only invoked when route matches   */
  getComponent (nextState, cb) {
    import(
      /* webpackChunkName: 'DriverSearch' */
      './components/DriverSearch'
    ).then(module => {
      cb(null, module.default)
    })
  }
})
