import defaults from 'superagent-defaults'
import { getAuthHeader, apiVersion } from '../util/api'

const client = defaults()

client
  .set('Accept', apiVersion(1))
  .on('request', (req) => {
    const auth = getAuthHeader()
    req.header.Authorization = auth
  })

export default client
